import {useStore} from "vuex"
import {useRouter} from "vue-router"
import {computed, ref, watch, nextTick} from "vue"
import {IApiResponse, IData, IStateParam} from "@/types"
import GlobalService from "@/services/global_service"
import {useParams} from "@/composables/useParams"
import ApiService from "@/services/api_service"
import {useAppUtils} from "@/composables/useAppUtils"

let urlPropsWatchPaused = false
const SCREEN_STATE = {}
const refreshTimer = {}

let store
export const isAutorefreshActive = ref(false)

export function useFetchState(options) {

  const {
    //props,
    //paramsMap,
    storeNamespace,
    autorefreshable,
    //onBeforeChangeStateParams,
  } = options

  //console.log('onBeforeChangeStateParams', onBeforeChangeStateParams, options)
  const screenName = storeNamespace

  if (SCREEN_STATE[screenName]) {
    return SCREEN_STATE[screenName]
  }

  //const store = useStore()
  store = useStore()
  const router = useRouter()

  const {
    paramsMap,
  } = useParams(screenName)

  const {
    getCurrentScreen,
  } = useAppUtils()

  const fetchStateInit = async(props) => {
    screenFetchState = fetchState
    screenChangeStateParams = changeStateParams
    screenGetServerRequestParams = getServerRequestParams
    console.log('useFetchState getCurrentScreen 1', getCurrentScreen(), screenName)
    updateStoreFromRouteProps(props)
    console.log('useFetchState getCurrentScreen 2', getCurrentScreen(), screenName)
    await updateLocationHref(getRouteObj())
    //return
    console.log('useFetchState getCurrentScreen 3', getCurrentScreen(), screenName)
    await fetchState()
    console.log('useFetchState getCurrentScreen 4', getCurrentScreen(), screenName)
    if (getCurrentScreen() !== screenName) {
      throw 'ScreenChanged'
    }
    initRoutePropsWatcher(props)
    console.log('useFetchState getCurrentScreen 5', getCurrentScreen(), screenName)
    //initAutorefresh()

  }

  const updateStoreFromRouteProps = (props) => {
    console.log('useFetchState useParams searchFields updateStoreFromRouteProps', JSON.stringify(props))
    paramsMap.forEach((param) => {
      if (param.propValue && param.setStoreValue) {
        const value = param.propValue(props)
        console.log('useFetchState updateStoreFromRouteProps forEach', param.name, value)
        value && param.setStoreValue(store, value)
      }
    })
  }

  const resetStoreToDefaultValues = () => {
    console.log('useFetchState useParams searchFields resetStoreToDefaultValues')
    paramsMap.forEach((param) => {
      if (param.setStoreDefaultValue) {
        param.setStoreDefaultValue(store)
      }
    })
  }

  const initRoutePropsWatcher = (props) => {
    watch(props, async () => {

      if (urlPropsWatchPaused) {
        return
      }

      resetStoreToDefaultValues()
      updateStoreFromRouteProps(props)
      await fetchState()
    })
  }


  let fetchStateCancelTokenId = ''

  const fetchState: (extraRequestParams?: IData) => Promise<false | IData> = async function (extraRequestParams = {}): Promise<false | IData> {
    // По умолчанию параметры запроса берутся из store.
    // Если надо дернуть запрос без предварительного изменения состояния страницы (store + view), то передаем параметры в аргументе extraRequestParams

    // "Автоматически" fetchState вызывается только при изменении URL. (Сделано для history back/forward)
    // В остальных случаях нужно вызывать fetchState явно.

    if (!extraRequestParams.refresh) {
      ApiService.cancelAllRequests()
    }

    fetchStateCancelTokenId = extraRequestParams.refresh ? 'fetchStateRefresh' : 'fetchState'
    autorefreshBlockAdd(fetchStateCancelTokenId)

    console.log('1 useFetchState fetchState', performance.now(), screenName, fetchStateCancelTokenId, JSON.stringify(store.getters.refreshBlocks))

    try {
      const requestParams = getServerRequestParams()
      Object.assign(requestParams, extraRequestParams)

      console.log('2 useFetchState fetchState', performance.now(), screenName, fetchStateCancelTokenId, JSON.stringify(requestParams))
      const data = await GlobalService.getScreenData(screenName, requestParams, fetchStateCancelTokenId)
      await processServerResponse(data)
      store.commit('setLastRefreshTimestamp', Date.now())
      autorefreshBlockRemove(fetchStateCancelTokenId)
      return data
    } catch ({e, cancelTokenId}) {
      console.log('3 useFetchState fetchState error', performance.now(), screenName, fetchStateCancelTokenId, cancelTokenId, e)
      if (e !== 'cancel') {
        store.commit('setRefreshPeriod', 60)
        store.commit('setLastRefreshTimestamp', Date.now())
      }
      autorefreshBlockRemove(cancelTokenId)
      console.log('4 useFetchState fetchState error', performance.now(), screenName, fetchStateCancelTokenId, cancelTokenId, JSON.stringify(store.getters.refreshBlocks))
      return e

    }
  }

  const getServerRequestParams: () => IData = function (): IData {
    const requestParams = {};
    paramsMap.forEach((param) => {
      if (param.serverRequestParam && param.getStoreValue) {
        Object.assign(requestParams, param.serverRequestParam(param.getStoreValue(store)))
      }
    })
    return requestParams
  }

  const getServerRequestParamsFromData: (data: IData) => IData = function (data: IData): IData {
    const requestParams = {};
    paramsMap.forEach((param) => {
      if (param.serverRequestParam && data[param.name]) {
        Object.assign(requestParams, param.serverRequestParam(data[param.name]))
      }
    })
    return requestParams
  }

  const saveServerResponseToStore: (xdata: IData) => void = function (xdata: IData): void {
    paramsMap.forEach((param) => {
      saveServerResponseParamToStore(param, xdata)
      // if (param.serverResponseParam && param.setStoreValue) {
      //   const paramValue = param.serverResponseParam(xdata)
      //   paramValue !== undefined && param.setStoreValue(store, paramValue)
      // }
    })
  }

  const saveServerResponseParamToStore: (param: IStateParam, xdata: IData) => void = function (param: IStateParam, xdata: IData): void {
    if (param.serverResponseParam && param.setStoreValue) {
      const paramValue = param.serverResponseParam(xdata)
      paramValue !== undefined && param.setStoreValue(store, paramValue)
    }
  }

  const processServerResponse: (data: IApiResponse) => Promise<void> = async function (data: IApiResponse): Promise<void> {
    if (data && !data.isError && data.xdata) {

      // Сохраняем ответ сервера в параметры vuex стора
      saveServerResponseToStore(data.xdata as IData)

      // Обновляем location
      await updateLocationHref(getRouteObj({}, true))

    }
  }

  const processServerResponseParam: (paramName: string, data: IData) => Promise<void> = async function (paramName: string, data: IData): Promise<void> {
    if (paramName && data && !data.isError && data.xdata) {

      const param = paramsMap.find(item => item.name === paramName)

      if (param) {
        // Сохраняем ответ сервера в параметры vuex стора
        saveServerResponseParamToStore(param, data.xdata as IData)

        // Обновляем location
        //await updateLocationHref(getRouteObj({}, true))
      }

    }
  }

  const getParamByName: (paramName: string) => IStateParam | undefined = function (paramName: string): IStateParam | undefined {
    return paramsMap.find(item => item.name === paramName)
  }

  const changeStateParams = async (paramsObj) => {

    const paramsNamesArray = Object.keys(paramsObj)
    const paramsArray = paramsMap.filter((item) => paramsNamesArray.includes(item.name))
    paramsArray.forEach((param) => {
      if (param.setStoreValue) {
        param.setStoreValue(store, paramsObj[param.name])
      }
    })

    await updateLocationHref(getRouteObj(paramsObj, true), true)
    return await fetchState()
  }

  const getRouteObj = (forcedParams: IData = {}, onlyIfAlreadyExistsInUrl = false) => {
    const routeParams = {}
    paramsMap.forEach((param) => {
      if (param.routeUrlParam) {
        const isForced = Object.prototype.hasOwnProperty.call(forcedParams, param.name)
        const routeParamValue = isForced ? forcedParams[param.name] : param.getStoreValue && param.getStoreValue(store)
        const routeUrlParamResult = param.routeUrlParam(routeParams, routeParamValue, store)
        if (routeUrlParamResult && routeUrlParamResult.merged && onlyIfAlreadyExistsInUrl && !isForced && !routeParamExistsInUrl(routeUrlParamResult)) {
          delete routeParams[routeUrlParamResult.type][routeUrlParamResult.name]
        }
      }
    })
    return routeParams
  }

  const routeParamExistsInUrl = (routeUrlParamResult) => {
    const paramType = routeUrlParamResult.type
    const paramName = routeUrlParamResult.name
    if (!(paramName && paramType)) {
      return false
    }
    console.log('useParams searchIn routeUrlParam routeParamExistsInUrl', paramType, paramName, Object.prototype.hasOwnProperty.call(router.currentRoute.value[paramType], paramName))
    return Object.prototype.hasOwnProperty.call(router.currentRoute.value[paramType], paramName)
  }

  const updateLocationHref = async (routeParams, addToHistory = false) => {
    urlPropsWatchPaused = true
    await router[addToHistory ? 'push' : 'replace'](routeParams)
    console.log('updateLocationHref', addToHistory, window.history.state)
    urlPropsWatchPaused = false
  }

  const doAutorefresh = async () => {
    //store.commit('setLastRefreshTimestamp', Date.now())
    // await fetchState({refresh: 1})
    await autorefreshScreen()
  }

  const checkAutorefresh = async () => {
    console.log('checkAutorefresh', mayAutorefresh(), JSON.stringify(refreshTimer))
    if (mayAutorefresh()) {
      await doAutorefresh()
    }
  }

  const mayAutorefresh = () => {
    const isAutorefreshPaused = store.getters['refreshPaused']
    const isRefreshPeriodPassed = store.getters['isRefreshPeriodPassed']
    const refreshBlocks = store.getters['refreshBlocks']
    const isRefreshBlocked = refreshBlocks?.length
    console.log('mayAutorefresh', !isAutorefreshPaused, isRefreshPeriodPassed, !isRefreshBlocked)
    return !isAutorefreshPaused && isRefreshPeriodPassed && !isRefreshBlocked
  }

  const checkRefreshPeriod = options.checkRefreshPeriod || 5

  const activateAutorefresh = () => {
    if (!refreshTimer[screenName]) {
      refreshTimer[screenName] = setInterval(checkAutorefresh, checkRefreshPeriod * 1000);
    }
    isAutorefreshActive.value = true
  }

  const cancelAutorefresh = () => {
    console.log('cancelAutorefresh', JSON.stringify(refreshTimer))
    Object.keys(refreshTimer).forEach(screen => {
      console.log('cancelAutorefresh forEach', screen, refreshTimer[screen])
      clearInterval(refreshTimer[screen])
      refreshTimer[screen] = null
    })
    autorefreshBlockRemove()
    isAutorefreshActive.value = false
  }

  const initAutorefresh = () => {
    cancelAutorefresh()
    // const isAutorefreshable = store.getters[storeNamespace + '/autorefreshable']
    // console.log('initAutorefresh', isAutorefreshable, JSON.stringify(options))
    // if (isAutorefreshable) {
    store.commit('setLastRefreshTimestamp', Date.now())
    activateAutorefresh()
    // }
  }


  SCREEN_STATE[screenName] = {
    initAutorefresh,
    doAutorefresh,
    cancelAutorefresh,
    fetchState,
    getServerRequestParams,
    getServerRequestParamsFromData,
    processServerResponse,
    processServerResponseParam,
    fetchStateInit,
    changeStateParams,
    getRouteObj,
    getParamByName,
    updateLocationHref,
  }

  return SCREEN_STATE[screenName]

}

export let screenFetchState: null | ((extraRequestParams?: IData) => Promise<false | IData>) = null
export let screenChangeStateParams: null | ((extraRequestParams?: IData) => Promise<false | IData>) = null
export let screenGetServerRequestParams: null | (() => IData) = null

export const refreshScreen = async (extraRequestParams?: IData) => {
  if (typeof screenFetchState === 'function') {
    await screenFetchState(extraRequestParams)
  }
}

export const forceRefreshScreen = async () => {
  await refreshScreen({nocache: 1, refresh: 0, cache_md5: ''})
}

export const autorefreshScreen = async () => {
  await refreshScreen({refresh: 1})
}

export const autorefreshBlockAdd = (blockType: string, blockAttrs: IData = {}) => {
  console.log('autorefreshBlockExists autorefreshBlockAdd', performance.now())
  if (!store) {
    return
  }
  store.commit('refreshBlockAdd', Object.assign(blockAttrs, {type: blockType}))
  ApiService.cancelRequest('fetchStateRefresh')
}

export const autorefreshBlockRemove = (blockType = '', blockAttrs: IData = {}) => {
  console.log('autorefreshBlockExists autorefreshBlockRemove', performance.now())
  // without parameters removes all blocks
  if (!store) {
    return
  }
  if (blockType) {
    Object.assign(blockAttrs, {type: blockType})
  }
  store.commit('refreshBlockRemove', blockAttrs)
}

export const autorefreshBlockExists = (blockType = '', blockAttrs: IData = {}) => {
  if (!store) {
    return false
  }
  if (blockType) {
    Object.assign(blockAttrs, {type: blockType})
  }
  const blocks = store.getters.refreshBlocksByAttrs(blockAttrs)
  console.log('autorefreshBlockExists', blockAttrs, JSON.stringify(blocks))
  return !!blocks.length
}
