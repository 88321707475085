import {ActionTree, GetterTree, MutationTree} from "vuex"
import {TokenService} from "@/services/storage_service"
import {RootState} from "@/store"
import AuthService from "@/services/auth_service"
import {AuthenticationError} from '@/services/auth_service'
import qs from "qs"
import {IUser} from "@/types"


export interface AuthState {
  authenticating: boolean
  accessToken: string
  authenticationErrorCode: number
  authenticationError: string

  authUser?: IUser,
  refreshTokenPromise: any

  domain: string
}


const state: AuthState = {
  authenticating: false,
  accessToken: TokenService.getToken() || '',
  authenticationErrorCode: 0,
  authenticationError: '',

  authUser: undefined,
  refreshTokenPromise: null,

  domain: window.location.hostname.split('.')[0] || ''
}

const mutations: MutationTree<AuthState> = {
  loginRequest(state) {
    state.authenticating = true
    state.authenticationError = ''
    state.authenticationErrorCode = 0
  },

  loginSuccess(state, accessToken) {
    state.accessToken = accessToken
    state.authenticating = false
  },

  loginError(state, {errorCode, errorMessage}) {
    state.authenticating = false
    state.authenticationErrorCode = errorCode
    state.authenticationError = errorMessage
  },

  logoutSuccess(state) {
    state.accessToken = ''
  },

  refreshTokenPromise(state, promise) {
    state.refreshTokenPromise = promise
  },

  changeAuthUser(state, {authUser}) {
    console.log('set state auth user', authUser)
    state.authUser = authUser
  }
}

const actions: ActionTree<AuthState, RootState> = {

  login({commit}, {login, password, domain, remember}) {

    return new Promise((resolve, reject) => {
      console.log('login ', login, password, domain)
      commit('loginRequest')
      AuthService.login(login, password, domain, remember).then((result: any) => {
        commit('loginSuccess', result.Authorization)
        const params = qs.parse(window.location.search, {ignoreQueryPrefix: true})
        let p: string = <string>(params["redirect"]) || ""
        if (p === "") {
          p = result.version == "1" && process.env.VUE_APP_V1_URI ? process.env.VUE_APP_V1_URI : (process.env.NODE_ENV === 'production' ? "/" : "/") + "board"
        }
        //window.location.href = p
        window.location.replace(p)
      }).catch(error => {
        if (error instanceof AuthenticationError) {
          commit('loginError', {errorCode: error.errorCode, errorMessage: error.messages})
        }
        reject(error)
      })
    })
  },

  logout: async ({commit, state, rootState}, {redirect}) => {
    if (rootState?.config?.v1_login_enabled) {
      window.location.replace('/logout.php')
    } else {
      document.getElementById("sq_loader")!.style.display = 'flex'
      commit('logoutSuccess')

      await AuthService.logout()
      //const url = rootState?.config?.v1_login_enabled ? (redirect || '/') : '/login' + (redirect ? '?redirect=' + escape(redirect) : "")

      setTimeout(() => {
        const url = '/login' + (redirect ? '?redirect=' + escape(redirect) : "")
        window.location.replace(url)
      }, 10)

      //window.location.replace('/login' + (redirect ? '?redirect=' + escape(redirect) : ""))
    }
  },

  refreshToken({commit}) {
    if (!state.refreshTokenPromise) {
      console.log("store refresh token")
      const p = AuthService.refreshToken()
      commit('refreshTokenPromise', p)
      p.then(
        response => {
          commit('refreshTokenPromise', null)
          commit('loginSuccess', response)
        })
      p.catch(
        error => {
          commit('refreshTokenPromise', null)
        })
    }
    return state.refreshTokenPromise
  },

}

const getters: GetterTree<AuthState, RootState> = {
  isLoggedIn: state => !!state.accessToken,

  authenticationErrorCode: (state) => {
    return state.authenticationErrorCode
  },

  authenticationError: (state) => {
    return state.authenticationError
  },

  authenticating: (state) => {
    return state.authenticating
  },

  authUser: (state) => {
    return state.authUser
  },

  domain: state => state.domain
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
