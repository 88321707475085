import {ActionTree, GetterTree, MutationTree} from "vuex"
import {RootState} from "@/store"
import {IFlowStep, IRole, IUser} from "@/types"

export interface ProjectTeamState {
  projectId: string
  roles: IRole[]
  users: IUser[]
  steps: IFlowStep[]
  isChanged: boolean
  readOnly: boolean
  isDefaultUsersExternalProject: boolean
}

const state: ProjectTeamState = {
  projectId: '',
  roles: [],
  users: [],
  steps: [],
  isChanged: false,
  readOnly: false,
  isDefaultUsersExternalProject: false,
}

const getters: GetterTree<ProjectTeamState, RootState> = {
  projectId: state => state.projectId,
  roles: state => state.roles,
  users: state => state.users,
  steps: state => state.steps,
  isChanged: state => state.isChanged,
  readOnly: state => state.readOnly,
  isDefaultUsersExternalProject: state => state.isDefaultUsersExternalProject,
}

const mutations: MutationTree<ProjectTeamState> = {
  setProjectId(state, id: string) {
    state.projectId = id
  },
  setRoles(state, value: IRole[]) {
    state.roles = [...value]
  },
  setUsers(state, value: IUser[]) {
    state.users = [...value]
  },
  setSteps(state, value: IFlowStep[]) {
    state.steps = [...value]
  },
  setIsChanged(state, value: boolean) {
    state.isChanged = value
  },
  setReadOnly(state, value: boolean) {
    state.readOnly = value
  },
  setIsDefaultUsersExternalProject(state, value: boolean) {
    state.isDefaultUsersExternalProject = value
  },
}

const actions: ActionTree<ProjectTeamState, RootState> = {

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules : {
  }
}
