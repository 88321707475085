import {ActionTree, GetterTree, MutationTree} from "vuex"
import {RootState} from "@/store"

export interface PanelsState {
  resizablePanel: "left" | "right"
  resizablePanelWidth: number
  leftPanelMinWidth: number
  rightPanelMinWidth: number
}
const state = (): PanelsState => ({
  resizablePanel: 'left',
  // resizablePanelWidth: 600,
  // leftPanelMinWidth: 500,
  // rightPanelMinWidth: 500,

  resizablePanelWidth: 600,
  leftPanelMinWidth: 400,
  rightPanelMinWidth: 400,
})

const getters: GetterTree<PanelsState, RootState> = {
  resizablePanel: state => state.resizablePanel,
  resizablePanelWidth: state => state.resizablePanelWidth,
  leftPanelMinWidth: state => state.leftPanelMinWidth,
  rightPanelMinWidth: state => state.rightPanelMinWidth,
}

const mutations: MutationTree<PanelsState> = {
  setResizablePanel(state, value: "left" | "right") {
    if (value) {
      state.resizablePanel = value
    }
  },
  setResizablePanelWidth(state, value: number | string) {
    if (value) {
      state.resizablePanelWidth = parseFloat(value.toString())
    }
  },
  setLeftPanelMinWidth(state, value: number | string) {
    if (value) {
      state.leftPanelMinWidth = parseFloat(value.toString())
    }
  },
  setRightPanelMinWidth(state, value: number | string) {
    if (value) {
      state.rightPanelMinWidth = parseFloat(value.toString())
    }
  },

}

const actions: ActionTree<PanelsState, RootState> = {
}

export default {
  state,
  actions,
  mutations,
  getters,
}
