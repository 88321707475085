import {ActionTree, GetterTree, MutationTree} from "vuex"
import {RootState} from "@/store"
import {IData, IFlowStep} from "@/types"

export interface TicketDesignerState {
  projectId: string
  schemaByRows: { main: IData[], advanced: IData[] } | null
  schema: { main: IData[], advanced: IData[] } | null
  defaultDateOptions: {key: string, label: string}[]
  isChanged: boolean
  flowSteps: IFlowStep[]
}

const state: TicketDesignerState = {
  projectId: '',
  schemaByRows: null,
  schema: null,
  defaultDateOptions: [],
  isChanged: false,
  flowSteps: [],
}

const getters: GetterTree<TicketDesignerState, RootState> = {
  projectId: state => state.projectId,
  schemaByRows: state => state.schemaByRows,
  schema: state => state.schema,
  defaultDateOptions: state => state.defaultDateOptions,
  isChanged: state => state.isChanged,
  flowSteps: state => state.flowSteps,
}

const mutations: MutationTree<TicketDesignerState> = {
  setProjectId(state, id: string) {
    state.projectId = id
  },
  setSchemaByRows(state, obj: { main: IData[], advanced: IData[] }) {
    state.schemaByRows = {...obj}
  },
  setSchema(state, obj: { main: IData[], advanced: IData[] }) {
    state.schema = {...obj}
  },
  setDefaultDateOptions(state, value) {
    state.defaultDateOptions = value
  },
  setIsChanged(state, value: boolean) {
    state.isChanged = value
  },
  setFlowSteps(state, value: IFlowStep[]) {
    state.flowSteps = value
  },
}

const actions: ActionTree<TicketDesignerState, RootState> = {

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules : {
  }
}
