import {ActionTree, GetterTree, MutationTree} from "vuex"
import {RootState} from "@/store"
import GlobalService from "@/services/global_service";
import {IData} from "@/types"
// import {SettingsState} from "@/store/modules/settings";


export interface DevState {
  t_dummy: boolean,
  t_in_col: number,
  ckEditorInstances: IData[],
  isShowDevTools: boolean,
  // isDragAnimation: boolean
  serverParam: string
}

const state: DevState = {
  t_dummy: false,
  t_in_col: 0,
  ckEditorInstances: [],
  isShowDevTools: true,
  // isDragAnimation: true,
  serverParam: '',
}

const mutations: MutationTree<DevState> = {
  setTDummy(state, {t_dummy}) {
    state.t_dummy = t_dummy;
  },

  setT_in_col(state, {t_in_col}) {
    state.t_in_col = t_in_col;
  },
  addCKEditorInstance(state, editor) {
    state.ckEditorInstances.push({
      id: editor.id,
      editor
    })
  },
  removeCKEditorInstance(state, editorId) {
    state.ckEditorInstances = state.ckEditorInstances.filter(item => item.id !== editorId)
  },
  toggleIsShowDevTools(state, value?: boolean) {
    state.isShowDevTools = value === undefined ? !state.isShowDevTools : value
  },
  // toggleIsDragAnimation(state, value?: boolean) {
  //   state.isDragAnimation = value === undefined ? !state.isDragAnimation : value
  // },
  setServerParam(state, value) {
    state.serverParam = value
  },
}

const actions: ActionTree<DevState, RootState> = {
  load({commit}) {
    return new Promise(()=>{
      console.log("1");
    });
  }
}

const getters: GetterTree<DevState, RootState> = {
  t_dummy: state=>state.t_dummy,
  t_in_col: state=>state.t_in_col,
  serverParam: state => state.serverParam,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
