import {computed, ref} from "vue"
import GlobalService from "@/services/global_service"
import NotificationCenterPopup from '@/components/Popups/NotificationCenterPopup.vue'
import {IData} from "@/types"
import {useEventListener} from "@vueuse/core"
import _throttle from 'lodash/throttle'

const allAutomator = ref(false)
const allAutoreminder = ref(false)
const notificationsCount = ref('')
const refreshPeriod = ref(0)
const isActiveRefresh = ref(true)

const $automator_enabled_btn = ref(false)
const $autoreminder_enabled_btn = ref(false)
const $automator_logs = ref([])
const $autoreminder_logs = ref([])
const $showProjects = ref(false)
const $projects_arr = ref({})
const $more_automator_count = ref(0)
const $more_autoreminder_count = ref(0)
const $autoreminder_count = ref(0)
const $automator_count = ref(0)
const notViewedNotifications = ref(false)
const ringBell = computed(() => notificationsCount.value && notViewedNotifications.value && !refNotificationCenter.value?.isOpened)

let refNotificationCenter = ref<InstanceType<typeof NotificationCenterPopup> | null>(null)
let autorefreshInitialized = false

const refreshNotificationCenterPosition = _throttle(() => {
  if (refNotificationCenter.value?.isOpened) {
    refNotificationCenter.value.refreshPosition()
  }
}, 50)

useEventListener(window, 'resize', refreshNotificationCenterPosition)


export function useNotificationCenter() {

  let autoRefreshDelay = 60 * 1000
  let lastLoadTime = - autoRefreshDelay // AVB: чтобы при первом вызове autoRefresh() сразу отработал запрос

  const checkAutorefreshNeededPeriod = 20 * 1000

  const setNotificationCenterPopup = (componentRef) => {
    refNotificationCenter = componentRef
  }

  const fetchData = async (params = {}) => {
    const data: IData = {}
    if (allAutomator.value) {
      data.all_automator = 1
    }
    if (allAutoreminder.value) {
      data.all_autoreminder = 1
    }
    Object.assign(data, params);

    try {
      const response = await GlobalService.fetchNotificationCenter(data)
      parseResponse(response)
      if (response.refresh_period && parseInt(response.refresh_period, 10) > 0) {
        autoRefreshDelay = parseInt(response.refresh_period, 10) * 1000;
      }
      lastLoadTime = window.performance.now()
      return true
    } catch (e) {
      return false
    }
  }

  const parseResponse = (response) => {
    $automator_enabled_btn.value = !!response.automator_enabled_btn
    $autoreminder_enabled_btn.value = !!response.autoreminder_enabled_btn
    $automator_logs.value = response.automator_logs || []
    $autoreminder_logs.value = response.autoreminder_logs || []
    $showProjects.value = !!response.showProjects
    $projects_arr.value = response.projects_arr || {}
    $more_automator_count.value = response.more_automator_count || 0
    $more_autoreminder_count.value = response.more_autoreminder_count || 0
    $autoreminder_count.value = response.autoreminder_count || 0
    $automator_count.value = response.automator_count || 0

    if (response.count && response.count.toString() !== notificationsCount.value && !refNotificationCenter.value?.isOpened) {
      notViewedNotifications.value = true
      setTimeout(() => {notViewedNotifications.value = false}, 28 * 1000)
    }
    notificationsCount.value = response.count ? response.count.toString() : ''

  }

  const autoRefresh = async () => {
    const _now = window.performance.now()
    console.log('useNotificationCenter autoRefresh refNotificationCenter', refNotificationCenter.value?.isOpened, refNotificationCenter)
    if (_now - lastLoadTime >= autoRefreshDelay) {
      const params = {'auto': 1}
      if (!refNotificationCenter.value?.isOpened) {
        Object.assign(params, {'get_count': 1})
      }
      await fetchData(params)
    }
    setTimeout(autoRefresh, checkAutorefreshNeededPeriod)
  }

  const moreAutomationLogs = () => {
    allAutomator.value = true
    return fetchData()
  }

  const moreAutoreminderLogs = () => {
    allAutoreminder.value = true
    return fetchData()
  }

  const clearAll = () => {
    return fetchData({
      reset: 1
    })
  }

  if (!autorefreshInitialized) {
    autoRefresh()
    autorefreshInitialized = true
  }

  return {
    allAutomator,
    allAutoreminder,
    notificationsCount,

    $automator_enabled_btn,
    $autoreminder_enabled_btn,
    $automator_logs,
    $autoreminder_logs,
    $showProjects,
    $projects_arr,
    $more_automator_count,
    $more_autoreminder_count,
    $autoreminder_count,
    $automator_count,
    notViewedNotifications,
    ringBell,

    moreAutomationLogs,
    moreAutoreminderLogs,
    clearAll,
    fetchData,
    setNotificationCenterPopup,
  }

}