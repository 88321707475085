import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.iconPath)
    ? (_openBlock(), _createElementBlock("svg", {
        key: 0,
        class: _normalizeClass(_ctx.className),
        style: _normalizeStyle(_ctx.style),
        xmlns: "http://www.w3.org/2000/svg"
      }, [
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("title", _hoisted_1, _toDisplayString(_ctx.title), 1))
          : _createCommentVNode("", true),
        _createElementVNode("use", {
          "xlink:href": _ctx.iconPath,
          "xmlns:xlink": "http://www.w3.org/1999/xlink"
        }, null, 8, _hoisted_2)
      ], 6))
    : _createCommentVNode("", true)
}