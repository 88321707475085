import {ActionTree, GetterTree, MutationTree} from "vuex"
import {RootState} from "@/store"
import {IRefreshBlock} from "@/types"

export interface AutorefreshState {
  refreshPaused: boolean
  lastRefreshTimestamp: number
  refreshPeriod: number
  cache_md5: string
  refreshBlocks: IRefreshBlock[]
}

const state = (): AutorefreshState => ({
  refreshPaused: false,
  lastRefreshTimestamp: 0,
  refreshPeriod: 20,
  cache_md5: '',
  refreshBlocks: [
    // {type: 'test', uid: 'uid', attr: 'attr'},
    // {type: 'test', attr: 'attr'},
    // {type: 'test', uid: 'uid'},
    // {type: 'test'},
    // {type: 'test2', uid: 'uid'},
    // {type: 'test2', attr: 'attr'},
    // {type: 'test2'},
    // {type: 'test3', attr: 'attr3'},
  ]
})

const getters: GetterTree<AutorefreshState, RootState> = {
  refreshPaused: state => state.refreshPaused,
  lastRefreshTimestamp: state => state.lastRefreshTimestamp,
  refreshPeriod: state => state.refreshPeriod,
  cache_md5: state => state.cache_md5,
  isRefreshPeriodPassed: state => {
    const refreshTimeDelta = Date.now() - state.lastRefreshTimestamp
    return state.refreshPeriod && (refreshTimeDelta < 0 || Math.round(refreshTimeDelta / 1000) >= state.refreshPeriod);
  },
  refreshBlocks: state => state.refreshBlocks,
  refreshBlocksByAttrs: state => (attrs) => {
    console.log('autorefreshBlockExists all refreshBlocks', JSON.stringify(state.refreshBlocks))
    return state.refreshBlocks.filter((block) => {
      return !Object.keys(attrs).find((attrName) => block[attrName] !== attrs[attrName])
    })
  }
}

const mutations: MutationTree<AutorefreshState> = {
  setRefreshPeriod(state, value) {
    const intValue = parseInt(value, 10)
    if (!isNaN(intValue)) {
      state.refreshPeriod = intValue
    }
  },
  setCacheMd5(state, value) {
    state.cache_md5 = value
  },
  refreshBlockAdd(state, block: IRefreshBlock) {
    // block - объект, содержащий набор свойств, описывающих блокировку
    state.refreshBlocks.push(block)
  },
  refreshBlockRemove(state, attrs) {
    // attrs - объект, содержащий набор свойств, описывающих блокировку
    state.refreshBlocks = state.refreshBlocks.filter((block) => {
      console.log('refreshBlockRemove', block, Object.keys(attrs).find((attrName) => block[attrName] !== attrs[attrName]))
      return Object.keys(attrs).find((attrName) => block[attrName] !== attrs[attrName])

      // return _.isUndefined(_.find(attrs, function (attrValue, attrName) {
      //   return block[attrName] !== attrValue;
      // }))

    })
    console.log('refreshBlockRemove refreshBlocks', state.refreshBlocks, JSON.stringify(state.refreshBlocks))
  },
  pauseAutorefresh(state) {
    state.refreshPaused = true
  },
  unpauseAutorefresh(state) {
    state.refreshPaused = false
  },
  setLastRefreshTimestamp(state, value) {
    state.lastRefreshTimestamp = value
  },
}

const actions: ActionTree<AutorefreshState, RootState> = {
}

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
}
