import {ActionTree, GetterTree, MutationTree} from "vuex"
import {RootState} from "@/store"
import {IData, IFieldErrors, IFlowStep, ITicket, ITicketTag} from "@/types"
import notes from "@/store/modules/notes"
import ticketTasks from "@/store/modules/tickettasks"
import panels from "@/store/modules/panels"

export interface TicketState {
  ticketId:  string
  ticket: ITicket | Record<string, never>
  projectId: string
  isNewTicket: boolean
  isReadonlyTicket: boolean
  //schema: IData[]
  schemaByRows: { main: IData[], advanced: IData[] } | null
  customFieldsValues: IData | null
  flowSteps: IFlowStep[]
  clone?: string
  nextPrev?: string
  project?: string
  ticketTags: ITicketTag[]
  eventLog: string[]
  isTicketFormChanged: boolean
  quickAddError: {ticket?: string, errors?: IFieldErrors}
}

const state: TicketState = {
  ticketId: '',
  ticket: {},
  projectId: '',
  isNewTicket: false,
  isReadonlyTicket: false,
  //schema: [],
  schemaByRows: null,
  customFieldsValues: null,
  flowSteps: [],
  ticketTags: [],
  eventLog: [],
  isTicketFormChanged: false,
  quickAddError: {}
}

const getters: GetterTree<TicketState, RootState> = {
  ticketId: state => state.ticketId,
  ticket: state => state.ticket,
  projectId: state => state.projectId,
  isNewTicket: state => state.isNewTicket,
  isReadonlyTicket: state => state.isReadonlyTicket,
  //schema: state => state.schema,
  schemaByRows: state => state.schemaByRows,
  customFieldsValues: state => state.customFieldsValues,
  flowSteps: state => state.flowSteps,
  clone: state => state.clone,
  nextPrev: state => state.nextPrev,
  ticketTags: state => state.ticketTags,
  eventLog: state => state.eventLog,
  isChanged: (state, getters) => state.isTicketFormChanged || getters.isNoteFormsChanged || getters.isTicketTasksChanged,
  quickAddError: state => state.quickAddError,
  // {
  //   console.log('Ticket store isChanged', state, getters, getters.isNoteFormsChanged)
  //   return true
  // },
/*
  schemaByRows: state => {
    const formRows: IData[][] = []
    let nextItemAlreadyAddedToRow = false
    state.schema.forEach((item, index, array) => {
      if (nextItemAlreadyAddedToRow) {
        nextItemAlreadyAddedToRow = false
        return
      }
      const row: IData[] = [item]
      if (item.pos_type === 'single') {
        const nextItem = array[index + 1]
        const isNextItemSingleToo = nextItem && nextItem.pos_type === 'single'
        if (isNextItemSingleToo) {
          row.push(nextItem)
          nextItemAlreadyAddedToRow = true
        }
      }
      formRows.push(row)
    })
    return formRows
  }
*/
}

const mutations: MutationTree<TicketState> = {
  set_ticketId(state, id: string) {
    state.ticketId = id

  },
  set_ticket(state, obj: ITicket) {
    state.ticket = obj ? {...obj} : {}
  },
  set_projectId(state, id: string) {
    state.projectId = id
  },
  setIsNewTicket(state, value: boolean) {
    state.isNewTicket = value
  },
  setIsReadonlyTicket(state, value: boolean) {
    state.isReadonlyTicket = value
  },
  // setSchema(state, value: IData[]) {
  //   state.schema = [...value]
  // },
  setSchemaByRows(state, obj: { main: IData[], advanced: IData[] }) {
    state.schemaByRows = {...obj}
  },
  setCustomFieldsValues(state, obj: IData) {
    state.customFieldsValues = {...obj}
  },
  setFlowSteps(state, value: IFlowStep[]) {
    state.flowSteps = value
  },
  setClone(state, id: string) {
    state.clone = id
  },
  setNextPrev(state, value: string) {
    state.nextPrev = value
  },
  setTicketTags(state, value: ITicketTag[]) {
    state.ticketTags = value
  },
  setEventLog(state, value: string[]) {
    state.eventLog = Array.isArray(value) ? value : []
  },
  setTicketFormChanged(state, value: boolean) {
    state.isTicketFormChanged = value
  },
  setQuickAddError(state, obj) {
    state.quickAddError = obj ? {...obj} : {}
  },
}

const actions: ActionTree<TicketState, RootState> = {

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules : {
    notes,
    ticketTasks,
    panels,
  }
}
