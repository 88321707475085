
import {computed, defineComponent, inject, onMounted, ref, watch, nextTick} from "vue";
import store from "@/store";
import SvgIcon from "@/components/SvgIcon";
import ThemeSwitcher from "@/components/ThemeSwitcher.vue";
import AutorefreshPanel from "@/components/AutorefreshPanel.vue";
import {useStore} from "vuex"
import {useRouter} from "vue-router"
import {useAppUtils} from "@/composables/useAppUtils"
import TestIsChanged from "@/components/Test/TestIsChanged.vue"
import {useI18n} from "vue-i18n"
import {useBrowserStorage} from "@/composables/useBrowserStorage"
import {useEventListener} from "@vueuse/core"
import _throttle from 'lodash/throttle'
import MenuTools from "@/components/Menus/MenuTools.vue"
import {useFloatingExists} from "@/composables/useFloatingExists"

  export default defineComponent({
    name: "TheNavbar",
    components: {SvgIcon, ThemeSwitcher, AutorefreshPanel, TestIsChanged, MenuTools},
    emits: ['triggerSettings'],
    // methods: {
    //   logout():void {
    //     store.dispatch('auth/logout', {}).then(() => {
    //       //this.$router.replace('/login');
    //     });
    //   }
    // },
    computed: {
      isLoggedIn():void {
        return store.getters['auth/isLoggedIn'];
      }
    },
    setup(props, {emit}) { //props, context

      const store = useStore()
      const router = useRouter()
      const { t } = useI18n()
      const isProjectRoute = computed(() => router.currentRoute.value.meta.isProjectSubRoute)
      const isSettingsRoute = computed(() => router.currentRoute.value.meta.isSettingsSubRoute)
      const projectId = computed(() => store.getters['project'].id)
      const navbarOpened = computed(() => store.getters.navbarOpened)
      const toggleSidebarButtonHintContent = computed(() => navbarOpened.value ? t('Collapse sidebar') : t('Expand sidebar'))

      const refToolsButton = ref(null)
      const refToolsMenu = ref<InstanceType<typeof MenuTools> | null>(null)
      const {isExist: isExistToolsMenu, triggerHandler: triggerHandlerToolsMenu} = useFloatingExists(refToolsMenu)

      const navbarExtraClasses = ref<string[]>([])

      const tippyOptions = computed(() => ({
        allowHTML: true,
        placement: 'right',
        onShow: () => !navbarOpened.value,
      }))

      const isDebugMode = !!inject('isDebugMode')

      // for testing
      const buildTime = store.state.config.build_time
      const isShowDevTools = computed(() => isDebugMode && store.state.dev.isShowDevTools)
      if (isDebugMode) {
        document.documentElement.classList.add('app-debug-mode')
        watch(isShowDevTools, value => {
          document.documentElement.classList.toggle('hide-dev-tools', !value)
        }, {immediate: true})
      }

      const adjustFontSize = (change) => {
        const html = document.documentElement;
        const fontSize = parseFloat(getComputedStyle(html).fontSize);
        const newFontSize = fontSize + change;

        // Ограничения на размер шрифта
        const minFontSize = 10;
        const maxFontSize = 22;

        if (newFontSize >= minFontSize && newFontSize <= maxFontSize) {
          html.style.fontSize = `${newFontSize}px`;
        }
      };


      const decreaseFontSize = () => {
        adjustFontSize(-1);
      };

      const increaseFontSize = () => {
        adjustFontSize(1);
      };

      const resetFontSize = () => {
        document.documentElement.style.fontSize = ''; // Устанавливаем размер шрифта по умолчанию
      };

      const {
        isMayContinue,
        showPanelsLoaders,
        hidePanelsLoaders,
      } = useAppUtils()

      const gotoDashboard = async () => {
        if (!await isMayContinue()) { return false }
        await router.push({
          name: 'Dashboard',
          params: store.getters['dashboard/routeParams'],
          query: store.getters['dashboard/routeQuery']
        })
      }

      const gotoProject = async () => {
        if (!await isMayContinue()) { return false }
        const projectTab = store.getters['projectTab']
        const settingsTab = store.getters['settingsTab']

        switch (projectTab) {
          case 'listview':
            await router.push({
              name: 'List',
              params: store.getters['listview/routeParams'],
              query: store.getters['listview/routeQuery']
            })
            break
          case 'reports':
            await router.push({
              name: 'ReportGeneral',
              params: {
                projectId: projectId.value
              }
            })
            break
          case 'settings':
            await router.push({
              name: settingsTab,
              params: {
                projectId: projectId.value
              }
            })
            break
          case 'automation':
            await router.push({
              name: 'Automation',
              params: {
                projectId: projectId.value
              }
            })
            break
          // case 'dummy':
          //   await router.push({
          //     name: 'Dummy',
          //     params: {
          //       projectId: projectId.value
          //     }
          //   })
          //   break
          default:
            await router.push({
              name: 'Board',
              params: {
                projectId: projectId.value
              }
            })
        }
      }

      const gotoPeople = async () => {
        if (!await isMayContinue()) { return false }
        await router.push({
          name: 'People',
          params: store.getters['people/routeParams'],
          query: store.getters['people/routeQuery']
        })
      }

      const {
        getBrowserStorageItem,
        setBrowserStorageItem,
      } = useBrowserStorage()

      const navbarStorageType = 'localStorage'
      const getNavbarStorageKey = (): string => 'navbar_collapsed'

      const setNavbarState = () => {
        const navbarCollapsedStorageValue = getBrowserStorageItem(getNavbarStorageKey(), navbarStorageType)
        if (navbarCollapsedStorageValue !== null) {
          store.commit('toggleNavbar', navbarCollapsedStorageValue !== '1')
        } else {
          store.commit('toggleNavbar', window.innerWidth > 1200)
        }

      }

      const setNavbarStateOnResize = _throttle(setNavbarState, 100)
      useEventListener(window, 'resize', setNavbarStateOnResize)

      setNavbarState()

      const toggleSidebar = () => {
        store.commit('toggleNavbar')
        setBrowserStorageItem(getNavbarStorageKey(), store.getters.navbarOpened ? '0' : '1', navbarStorageType)
      }

      const settingsBtnClickHandler = async () => {
        emit('triggerSettings')
      }

      const logoImage = ref()
      const logoWrapper = ref()

      const isDefaultLogo = computed(() => !!store.state.settings.logo_is_default)
      const logoImg = computed(() => store.state.settings.logo_img)
      const logoImg2x = computed(() => store.state.settings.logo_img_2x)
      const logoImgSmall = computed(() => store.state.settings.logo_img_small)
      const logoImgSmall2x = computed(() => store.state.settings.logo_img_small_2x)
      const logoImgClass = computed(() => store.state.settings.logo_img_class)
      const logoCanRotate = computed(() => store.state.settings.logo_can_rotate == 1)

      const updateSize = () => {
        const image = logoImage.value
        const wrapper = logoWrapper.value
        if (image && wrapper) {
          // Проверка на ненулевой offsetWidth
          if (image.offsetWidth > 0) {
            // const width = Math.min(image.offsetWidth, 50);
            const width = Math.min(image.offsetWidth, 150);
            const imgWidth = Math.min(image.offsetWidth, 45);
            // const imgHeight = Math.min(image.offsetHeight, 135);
            const height = Math.min(image.offsetHeight, 45);
            wrapper.style.height = width + 'px';
            wrapper.style.width = height + 'px';
            image.style.MaxHeight = imgWidth + 'px';
            // image.style.width = imgHeight + 'px';
          }
        }
      }

      onMounted(async() => {
        await nextTick()

        const image = logoImage.value
        const wrapper = logoWrapper.value
        if (image && wrapper) {
          if (image.complete && image.naturalHeight !== 0) {
            updateSize()
          } else {
            image.addEventListener('load', updateSize, {once: true})
          }
        }

        setTimeout(() => {
          navbarExtraClasses.value.push('animated')
        }, 200)


        // const sidebarElement = document.querySelector('.navbar')
        // if (sidebarElement) {
        //   sidebarElement.classList.add('animated')
        // }

      })

      watch(logoImg, async () => {
        await nextTick()
        const image = logoImage.value
        if (image) {
          image.addEventListener('load', updateSize, {once: true})
        }
      })

      return {
        t,
        isDebugMode,
        isProjectRoute,
        isSettingsRoute,
        // enterHandler,
        // leaveHandler,
        // clickHandler,
        gotoDashboard,
        gotoProject,
        gotoPeople,
        buildTime,
        isShowDevTools,
        toggleSidebar,
        navbarExtraClasses,
        navbarOpened,
        tippyOptions,
        toggleSidebarButtonHintContent,
        settingsBtnClickHandler,
        toolsTippyContent: '<span style="text-transform: capitalize;">' + t('tools') + '</span>',
        companyUrl: computed(() => store.state.settings.company_url),
        isDefaultLogo,
        logoImg,
        logoImg2x,
        logoImgSmall,
        logoImgSmall2x,
        logoImgClass,
        logoCanRotate,
        refToolsMenu,
        refToolsButton,
        isExistToolsMenu,
        triggerHandlerToolsMenu,
        logoImage,
        logoWrapper,
        decreaseFontSize,
        increaseFontSize,
        resetFontSize,
      }
    }
    // ,
    // mounted() {
    //   this.$nextTick(() => {
    //     const image = this.$refs.logoImage as HTMLImageElement;
    //     const wrapper = this.$refs.logoWrapper as HTMLDivElement;
    //
    //     image.addEventListener('load', () => {
    //       const width = Math.min(image.offsetWidth, 90);
    //
    //       wrapper.style.height = width + 'px';
    //       image.style.width = width + 'px';
    //     });
    //   });
    // }

  });
