import {ActionTree, GetterTree, MutationTree} from "vuex"
import {RootState} from "@/store"
import {IData, ITicket} from "@/types"

export interface TicketsState {
  tickets: ITicket[]
  step: string
}

const state = (): TicketsState => ({
  tickets: [],
  step: '0',
})

const getters: GetterTree<TicketsState, RootState> = {
  tickets: state => state.tickets,
  getItemById: (state) => (id: string) => {
    return state.tickets.find(item => item.id.toString() === id)
  },
  step: state => state.step,
}

const mutations: MutationTree<TicketsState> = {
  set_tickets(state, tickets: ITicket[]) {
    state.tickets = tickets

  },
  set_step(state, step: string) {
    state.step = step
  },
  removeTicket(state, ticketId: string) {
    state.tickets = state.tickets.filter(item => item.id !== ticketId)
  },
  // setTicketArchived(state, {ticketId, archived}) {
  //   const ticket = state.tickets.find(item => item.id === ticketId)
  //   if (ticket) {
  //     ticket.archived = !!archived
  //   }
  // },
  // setTicketStar(state, {ticketId, star}) {
  //   const ticket = state.tickets.find(item => item.id === ticketId)
  //   if (ticket) {
  //     ticket.star = star
  //   }
  // },
  // setTicketTasksCount(state, data: IData) {
  //   const {ticketId, closedCount, totalCount} = data
  //   const ticket = state.tickets.find(item => item.id === ticketId)
  //   if (ticket) {
  //     if (parseFloat(totalCount as string) > 0) {
  //       ticket.todos = closedCount + '/' + totalCount
  //     } else {
  //       delete ticket.todos
  //     }
  //   }
  // },
  // setTicketTeam(state, {ticketId, team}) {
  //   const ticket = state.tickets.find(item => item.id === ticketId)
  //   if (ticket) {
  //     ticket.team = team
  //   }
  // },
  updateTicket(state, {id, attrs}) {
    const ticket = state.tickets.find(item => item.id === id)
    if (ticket) {
      Object.assign(ticket, attrs)
    }
  }
}

const actions: ActionTree<TicketsState, RootState> = {
}

export default {
  state,
  actions,
  mutations,
  getters,
}
