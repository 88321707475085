

import {computed, defineComponent, inject, toRef, toRefs} from "vue";

const anticacheValue = Date.now()

export default defineComponent({
  name: 'svg-icon',
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: null
    },
    width: {
      type: String||Number,
    },
    height: {
      type: String||Number,
    }
  },
  setup(props) {
    const className = 'icon icon-' + props.name;

    let style:{width?:string, height?:string} = {}
    if (props.width) {
      style.width = props.width+'px';
    }
    if (props.height) {
      style.height = props.height+'px';
    }

    const {name} = toRefs(props);

    const isDebugMode = !!inject('isDebugMode')

    const iconPath = computed(()=>{
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      let icon
      try {
        icon = require(`@/assets/icons/${name.value}.svg`);
      } catch (e) {
        //console.log(`SvgIcon require ${name.value}.svg error`, e)
        return ''
      }

      if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
        icon = icon.default;
      }
      console.log(`SvgIcon ${name.value}.svg url`, !isDebugMode ? icon.url : icon.url.replace('#', '?t=' + anticacheValue + '#'), isDebugMode, '|', anticacheValue, '|');

      return isDebugMode ? icon.url.replace('#', '?t=' + anticacheValue + '#') : icon.url
      // return `#${name.value}`;
    })

    return {
      className,
      iconPath,
      style
    }
  }
});

