import {getKeyboardFocusableElements} from "@/utils/appUtils"

export const circleTabulationDataAttribute = 'data-circle-tabulation'

const createDummyLink = (type: 'start' | 'end') => {
  const dummyLink = document.createElement('a')
  dummyLink.setAttribute('href', 'javascript:void(0)')
  dummyLink.setAttribute(circleTabulationDataAttribute, type)
  dummyLink.setAttribute('tabindex', '0')
  return dummyLink
}

const getFocusableElements = (event) => {
  const el = event.target.parentElement
  return getKeyboardFocusableElements(el, {omitServiceElements: true})
    //.filter(el => !el.hasAttribute(circleTabulationDataAttribute))
}

const placeFocusAtStart = (event) => {
  console.log('CircleTabulation placeFocusAtStart', event.target, event)
  const focusableElements = getFocusableElements(event)
  if (focusableElements.length) {
    (focusableElements[0] as HTMLElement).focus()
  }
}

const placeFocusAtEnd = (event) => {
  console.log('CircleTabulation placeFocusAtEnd', event.target, event)
  const focusableElements = getFocusableElements(event)
  if (focusableElements.length) {
    (focusableElements[focusableElements.length - 1] as HTMLElement).focus()
  }

}

const CircleTabulation = {
  mounted(el, binding) {
    console.log('CircleTabulation mounted', el, binding, CircleTabulation)

    // Remove existing dummyLinks
    el.querySelectorAll('a['+ circleTabulationDataAttribute +']').forEach(e => e.remove())


    const topDummyLink = createDummyLink('start')
    if (topDummyLink) {
      topDummyLink.addEventListener('focus', placeFocusAtEnd)
      el.insertBefore(topDummyLink, el.firstChild)
    }
    const bottomDummyLink = createDummyLink('end')
    if (bottomDummyLink) {
      bottomDummyLink.addEventListener('focus', placeFocusAtStart)
      el.appendChild(bottomDummyLink)
    }
  },
  unmounted(el, binding) {
    console.log('CircleTabulation unmounted', el, binding)
    el.querySelectorAll('a['+ circleTabulationDataAttribute +']').forEach(element => {
      if (element.getAttribute(circleTabulationDataAttribute) === 'start') {
        element.removeEventListener('focus', placeFocusAtEnd)
      } else {
        element.removeEventListener('focus', placeFocusAtStart)
      }
    })
  }
}

export default CircleTabulation
