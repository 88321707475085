import {getKeyboardFocusableElements} from "@/utils/appUtils"

const keyEventHandler = (el, event) => {
  console.log('TabOnArrows', el, event.key, event.target, event)
  const forwardArrowsKeys = ['ArrowUp', 'ArrowRight']
  const backwardArrowsKeys = ['ArrowDown', 'ArrowLeft']
  const isForwardArrow = forwardArrowsKeys.includes(event.key)
  const isBackwardArrow = backwardArrowsKeys.includes(event.key)
  if (isForwardArrow || isBackwardArrow) {
    const tabbableElements = getKeyboardFocusableElements(el)
    const targetIndex = tabbableElements.indexOf(event.target)
    let nextIndex
    if (isBackwardArrow) {
      nextIndex = targetIndex === 0 ? tabbableElements.length - 1 : targetIndex - 1
    } else {
      nextIndex = targetIndex < tabbableElements.length - 1 ? targetIndex + 1 : 0
    }
    console.log('TabOnArrows keyEventHandler', targetIndex, nextIndex, isBackwardArrow, event)
    tabbableElements[nextIndex].focus()
  }
}

const TabOnArrows = {
  mounted(el, binding) {
    console.log('TabOnArrows mounted', el, binding, TabOnArrows)
    el.addEventListener('keydown', keyEventHandler.bind(null, el))
  },
  unmounted(el, binding) {
    console.log('TabOnArrows unmounted', el, binding, TabOnArrows)
    el.removeEventListener('keydown', keyEventHandler.bind(null, el))
  },
}

export default TabOnArrows
