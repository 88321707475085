import {ActionTree, GetterTree, MutationTree} from "vuex"
import {RootState} from "@/store"
import panels from "@/store/modules/panels"
import {
  IAutomationAction,
  IAutomationCondition,
  IAutomationConditionIf,
  IAutomationRule, IAutomationRuleTemplate, IData,
  IFlowStep,
  IUser,
  IProjectSeekerItem,
} from "@/types"

export interface AutomationState {
  projectId: string
  rules: IAutomationRule[]
  actions: IAutomationAction[]
  conditions: IAutomationCondition[]
  conditionsIfs: IAutomationConditionIf[]
  projects: IProjectSeekerItem[]
  users: IUser[]
  steps: IFlowStep[]
  templates: IAutomationRuleTemplate[]
  logs: IData[]
  logsOffset: number
  isMoreLogs: boolean
  welcomeKey: string
  welcomeShow: boolean
  readOnly: boolean
  timeOptions: IData[]
  days: number[]
  periodsForTimeSpentInStage: IData[]
  newRuleData: Partial<IAutomationRule> | null
}

const state: AutomationState = {
  projectId: '',
  rules: [],
  actions: [],
  conditions: [],
  conditionsIfs: [],
  projects: [],
  users: [],
  steps: [],
  templates: [],
  logs: [],
  logsOffset: 0,
  isMoreLogs: false,
  welcomeKey: 'automator_popup',
  welcomeShow: true,
  readOnly: false,
  timeOptions: [],
  days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
  periodsForTimeSpentInStage: [],
  newRuleData: null,
}

const getters: GetterTree<AutomationState, RootState> = {
  projectId: state => state.projectId,
  rules: state => state.rules,
  actions: state => state.actions,
  conditions: state => state.conditions,
  conditionsIfs: state => state.conditionsIfs,
  getConditionsIfsByConditionId: (state) => (conditionId: string) => state.conditionsIfs.filter(item => item.condition_id === conditionId) || [],
  getConditionsIfsByConditionAlias: (state, getters) => (conditionAlias: string) => {
    const condition = state.conditions.find(item => item.alias === conditionAlias)
    return condition?.id ? getters.getConditionsIfsByConditionId(condition.id) : []
  },
  projects: state => state.projects,
  users: state => state.users,
  steps: state => state.steps,
  templates: state => state.templates,
  logs: state => state.logs,
  logsOffset: state => state.logsOffset,
  isMoreLogs: state => state.isMoreLogs,
  welcomeKey: state => state.welcomeKey,
  welcomeShow: state => state.welcomeShow,
  readOnly: state => state.readOnly,
  timeOptions: state => state.timeOptions,
  days: state => state.days,
  periodsForTimeSpentInStage: state => state.periodsForTimeSpentInStage,
  rulesChanged: state => !!state.rules.find(item => item.hasChanges),
  isAddingNewRule: state => !!state.newRuleData,
  isChanged: (state, getters) => getters.rulesChanged || getters.isAddingNewRule,
}

const mutations: MutationTree<AutomationState> = {
  setProjectId(state, id: string) {
    state.projectId = id
  },
  setRules(state, value: IAutomationRule[]) {
    state.rules = [...value]
  },
  updateRule(state, {id, attrs}) {
    const rule = state.rules.find(item => item.id === id)
    if (rule) {
      Object.assign(rule, attrs)
    }
  },
  removeRule(state, id: string) {
    state.rules = state.rules.filter(item => item.id !== id)
  },
  setActions(state, value: IAutomationAction[]) {
    state.actions = [...value]
  },
  setConditions(state, value: IAutomationCondition[]) {
    state.conditions = [...value]
  },
  setConditionsIfs(state, value: IAutomationConditionIf[]) {
    state.conditionsIfs = [...value]
  },
  setProjects(state, value: IProjectSeekerItem[]) {
    state.projects = [...value]
  },
  setUsers(state, value: IUser[]) {
    state.users = [...value]
  },
  setSteps(state, value: IFlowStep[]) {
    state.steps = [...value]
  },
  setTemplates(state, value: IAutomationRuleTemplate[]) {
    state.templates = [...value]
  },
  setLogs(state, value: IData[] | null) {
    if (Array.isArray(value)) {
      state.logs = value
    }
  },
  setLogsOffset(state, value: number) {
    state.logsOffset = value
  },
  setIsMoreLogs(state, value: boolean) {
    state.isMoreLogs = value
  },
  appendLogs(state, value: IData[]) {
    state.logs = [
      ...state.logs,
      ...value,
    ]
  },
  setWelcomeKey(state, value: string) {
    if (value) {
      state.welcomeKey = value
    }
  },
  setWelcomeShow(state, value: boolean) {
    state.welcomeShow = value
  },
  setReadOnly(state, value: boolean) {
    state.readOnly = value
  },
  setTimeOptions(state, value: IData[] | null) {
    if (Array.isArray(value)) {
      state.timeOptions = value
    }
  },
  setDays(state, value: number[] | null) {
    if (Array.isArray(value)) {
      state.days = value
    }
  },
  setPeriodsForTimeSpentInStage(state, value: IData[] | null) {
    if (Array.isArray(value)) {
      state.periodsForTimeSpentInStage = value
    }
  },
  setNewRuleData(state, value: Partial<IAutomationRule> | null) {
    state.newRuleData = value
  }
}

const actions: ActionTree<AutomationState, RootState> = {

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules : {
    panels,
  }
}
