import _merge from 'lodash/merge';
import {IStateParam} from "@/types"
import {inject} from 'vue'
import _isEqual from 'lodash/isEqual'

interface IUseParamsResponse {
  paramsMap: Array<IStateParam>
}

export function useParams (moduleName: string): IUseParamsResponse {

  let paramsMap: Array<IStateParam> = []

  switch (moduleName) {
    case 'ticket':
      paramsMap = [
        ...getParamsMapNotes(moduleName),
        ...getParamsMapUsersSelector(moduleName),
        ...getParamsMapTicketTasks(moduleName),
        ...getParamsMapPanelsParams(moduleName),
        ...getParamsMapAutorefresh(),
        ...getParamsMapCurrentProject(),
        ...getParamsMapDebug(),
        {
          name: 'ticketId',
          propValue: (props) => props['ticketId'],
          serverRequestParam: value => value ? {id: value} : null,
          serverResponseParam: response => response.ticket_id,
          routeUrlParam: (params, value) => {
            const type = 'params'
            const name = 'id'
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }
            if (value) {
              _merge(params, {[type]: {[name]: value}})
              result.merged = true
            } else {
              //deleteRouteUrlParam(params, type, name)
              _merge(params, {[type]: {[name]: null}})
              result.deleted = true
            }
            return result
          },
          getStoreValue: (store) => store.getters[moduleName + '/ticketId'],
          setStoreValue: (store, value) => store.commit(moduleName + '/set_ticketId', value),
          setStoreDefaultValue: store => store.commit(moduleName + '/set_ticketId', ''),
        },
        {
          name: 'ticket',
          serverRequestParam: null,
          serverResponseParam: response => response?.ticket,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/ticket'],
          setStoreValue: (store, value) => store.commit(moduleName + '/set_ticket', value)
        },
        {
          name: 'isNewTicket',
          serverRequestParam: null,
          serverResponseParam: response => response.isNew,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters['isNewTicket'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setIsNewTicket', value)
        },
        {
          name: 'isReadonlyTicket',
          serverRequestParam: null,
          serverResponseParam: response => response.viewMode,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters['isReadonlyTicket'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setIsReadonlyTicket', value)
        },
        // {
        //   name: 'schema',
        //   serverRequestParam: null,
        //   serverResponseParam: response => response.schema,
        //   routeUrlParam: null,
        //   getStoreValue: (store) => store.getters[moduleName + '/schema'],
        //   setStoreValue: (store, value) => store.commit(moduleName + '/setSchema', value)
        // },
        {
          name: 'schemaByRows',
          serverRequestParam: null,
          serverResponseParam: response => response.schemaByRows,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/schemaByRows'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setSchemaByRows', value)
        },
        {
          name: 'customFieldsValues',
          serverRequestParam: null,
          serverResponseParam: response => response.CustomFields,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/customFieldsValues'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setCustomFieldsValues', value)
        },
        {
          name: 'flowSteps',
          serverRequestParam: null,
          serverResponseParam: response => response.flowSteps,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/flowSteps'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setFlowSteps', value)
        },
        {
          name: 'clone',
          propValue: (props) => props['clone'],
          serverRequestParam: value => value ? {clone: value} : null,
          serverResponseParam: null,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/clone'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setClone', value)
        },
        {
          name: 'nextPrev',
          propValue: (props) => props['nextPrev'],
          serverRequestParam: value => value ? {np: value} : null,
          serverResponseParam: null,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/nextPrev'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setNextPrev', value)
        },
        {
          name: 'projectId',
          propValue: (props) => props['project'],
          serverRequestParam: value => value ? {project: value} : null,
          serverResponseParam: null,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/projectId'],
          setStoreValue: (store, value) => store.commit(moduleName + '/set_projectId', value)
        },
        {
          name: 'tags',
          serverRequestParam: null,
          serverResponseParam: response => response.ticketTags,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/ticketTags'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setTicketTags', value)
        },
        {
          name: 'eventLog',
          serverRequestParam: null,
          serverResponseParam: response => response.toolbar?.event_log,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/eventLog'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setEventLog', value)
        },
      ]
      break
    case 'dashboard':
      paramsMap = [
        ...getParamsMapTicketsList(moduleName),
        ...getParamsMapTicketsFilters('ticketsfilters'),
        ...getParamsMapNotes(moduleName),
        ...getParamsMapTicketTasks(moduleName),
        ...getParamsMapPanelsParams(moduleName),
        ...getParamsMapAutorefresh(),
        ...getParamsMapDebug(),
        {
          name: 'selectedItemId',
          propValue: (props) => props['selectedItemId'],
          serverRequestParam: value => value ? {selectedId: value} : null,
          serverResponseParam: response => {
            return response.cached ? 'cached' : response?.tickets_view?.selectedItemId || 'all'
          },
          routeUrlParam: (params, value) => {
            const type = 'query'
            const name = 'ticket'
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }
            if (value) {
              _merge(params, {[type]: {[name]: value}})
              result.merged = true
            } else {
              //_merge(params, {[type]: {[name]: null}})
              deleteRouteUrlParam(params, type, name)
              result.deleted = true
            }
            return result
          },
          getStoreValue: (store) => store.getters[moduleName + '/selectedItemId'],
          setStoreValue: (store, value) => {
            if (value !== 'cached') {
              store.commit(moduleName + '/set_selectedItemId', value && value.toString && value.toString() !== 'all' ? value.toString() : '')
            }
          },
          setStoreDefaultValue: store => store.commit(moduleName + '/set_selectedItemId', ''),
        },
      ]
      break
    case 'listview':
      paramsMap = [
        ...getParamsMapTicketsList(moduleName),
        ...getParamsMapTicketsFilters('ticketsfilters'),
        ...getParamsMapNotes(moduleName),
        ...getParamsMapTicketTasks(moduleName),
        ...getParamsMapPanelsParams(moduleName),
        ...getParamsMapAutorefresh(),
        ...getParamsMapCurrentProject(),
        ...getParamsMapDebug(),
        {
          name: 'projectId',
          propValue: (props) => props['projectId'],
          serverRequestParam: value => value ? {project: value} : null,
          serverResponseParam: response => response.project_id,
          routeUrlParam: (params, value) => {
            const type = 'params'
            const name = 'projectId'
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }
            if (value) {
              _merge(params, {[type]: {[name]: value}})
              result.merged = true
            } else {
              //deleteRouteUrlParam(params, type, name)
              _merge(params, {[type]: {[name]: null}})
              result.deleted = true
            }
            return result
          },
          getStoreValue: (store) => store.getters[moduleName + '/projectId'],
          setStoreValue: (store, value) => store.commit(moduleName + '/set_projectId', value),
          setStoreDefaultValue: store => store.commit(moduleName + '/set_projectId', ''),
        },
        {
          name: 'selectedItemId',
          propValue: (props) => props['selectedItemId'],
          serverRequestParam: value => value ? {selectedId: value} : null,
          serverResponseParam: response => {
            return response.cached ? 'cached' : response?.tickets_view?.selectedItemId || 'all'
          },
          routeUrlParam: (params, value) => {
            const type = 'query'
            const name = 'ticket'
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }
            if (value) {
              _merge(params, {[type]: {[name]: value}})
              result.merged = true
            } else {
              deleteRouteUrlParam(params, type, name)
              result.deleted = true
            }
            return result
          },
          getStoreValue: (store) => store.getters[moduleName + '/selectedItemId'],
          setStoreValue: (store, value) => {
            if (value !== 'cached') {
              store.commit(moduleName + '/set_selectedItemId', value && value.toString && value.toString() !== 'all' ? value.toString() : '')
            }
          },
          setStoreDefaultValue: store => store.commit(moduleName + '/set_selectedItemId', ''),
        },
        {
          name: 'role',
          serverRequestParam: null,
          serverResponseParam: response => response.role || {},
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/getRole'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setRole', value)
        },
      ]
      break
    case 'people':
      paramsMap = [
        ...getParamsMapDatatable(moduleName),
        ...getParamsMapPanelsParams(moduleName),
        ...getParamsMapAutorefresh(),
        ...getParamsMapDebug(),
        {
          name: 'users',
          serverRequestParam: null,
          serverResponseParam: response => response?.users_view?.items,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/users'],
          setStoreValue: (store, value) => store.commit(moduleName + '/set_users', value)
        },
        {
          name: 'selectedItemId',
          propValue: (props) => props['selectedItemId'],
          serverRequestParam: value => value ? {id: value} : null,
          serverResponseParam: response => response?.users_view?.itemSelected,
          routeUrlParam: (params, value) => {
            const type = 'params'
            const name = 'userId'
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }
            if (value) {
              _merge(params, {[type]: {[name]: value === '0' ? 'new' : value}})
              result.merged = true
            } else {
              _merge(params, {[type]: {[name]: null}})
              //deleteRouteUrlParam(params, type, name)
              result.deleted = true
            }
            return result
          },
          getStoreValue: (store) => store.getters[moduleName + '/selectedItemId'],
          setStoreValue: (store, value) => store.commit(moduleName + '/set_selectedItemId', value === null ? '' : value.toString()),
          setStoreDefaultValue: store => store.commit(moduleName + '/set_selectedItemId', ''),
        },
        {
          name: 'selectedUser',
          serverRequestParam: null,
          serverResponseParam: response => response.user || null,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/selectedUser'],
          setStoreValue: (store, value) => store.commit(moduleName + '/set_selectedUser', value)
        },
        {
          name: 'userCanEdit',
          serverRequestParam: null,
          serverResponseParam: response => response.user_can_edit || 0,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/userCanEdit'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setUserCanEdit', value === 1)
        },
        {
          name: 'canRemoveUser',
          serverRequestParam: null,
          serverResponseParam: response => !!response.canRemoveUser,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/canRemoveUser'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setCanRemoveUser', value)
        },
        {
          name: 'canChangeAdm',
          serverRequestParam: null,
          serverResponseParam: response => !!response.canChangeAdm,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/canChangeAdm'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setCanChangeAdm', value)
        },
        {
          name: 'canChangeAccessRights',
          serverRequestParam: null,
          serverResponseParam: response => !!response.canChangeAccessRights,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/canChangeAccessRights'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setCanChangeAccessRights', value)
        },
        {
          name: 'usersLimitReached',
          serverRequestParam: null,
          serverResponseParam: response => !!response?.usersLimitReached,
          routeUrlParam: null,
          getStoreValue: null,
          setStoreValue: (store, value) => store.commit('setUsersLimitReached', value)
        },
        {
          name: 'search',
          propValue: (props) => props['search'],
          serverRequestParam: value => value ? {search: value} : null,
          serverResponseParam: response => response?.users_view?.search,
          routeUrlParam: (params, value) => {
            const type = 'query'
            const name = 'search'
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }
            if (value) {
              _merge(params, {[type]: {[name]: value}})
              result.merged = true
            } else {
              deleteRouteUrlParam(params, type, name)
              result.deleted = true
            }
            return result
          },
          getStoreValue: (store) => store.getters[moduleName + '/search'],
          //setStoreValue: (store, value) => store.commit(moduleName + '/set_search', value),
          //setStoreDefaultValue: store => store.commit(moduleName + '/set_search', ''),
        },

      ]
      break
    case 'board':
      paramsMap = [
        ...getParamsMapTicketsFilters('ticketsfilters'),
        ...getParamsMapUsersSelector(moduleName),
        ...getParamsMapAutorefresh(),
        ...getParamsMapCurrentProject(),
        ...getParamsMapDebug(),
        {
          name: 'projectId',
          propValue: (props) => props['projectId'],
          serverRequestParam: value => value ? {project: value} : null,
          serverResponseParam: response => response.project_id,
          routeUrlParam: (params, value) => {
            const type = 'params';
            const name = 'projectId';
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }
            if (value) {
              _merge(params, {[type]: {[name]: value}})
              result.merged = true
            } else {
              //deleteRouteUrlParam(params, type, name)
              _merge(params, {[type]: {[name]: null}})
              result.deleted = true
            }
            return result
          },
          getStoreValue: (store) => store.getters[moduleName + '/project'],
          setStoreValue: (store, value) => store.commit(moduleName + '/set_projectId', value),
          setStoreDefaultValue: store => store.commit(moduleName + '/set_projectId', ''),
        },
        {
          name: 'board',
          serverRequestParam: null,
          serverResponseParam: response => response,
          getStoreValue: null,
          setStoreValue: (store, value) => {
            //if (value.cached != 1) {
            store.dispatch(moduleName + '/updateBoard', value)
            //}
          },
          setStoreDefaultValue: null//store => store.dispatch(moduleName + '/updateBoard', {}),
        },

       ]
      break
    case 'report_general':
      paramsMap = [
        ...getParamsMapPanelsParams(moduleName),
        ...getParamsMapCurrentProject(),
        ...getParamsMapDebug(),
        {
          name: 'projectId',
          propValue: (props) => props['projectId'],
          serverRequestParam: value => value ? {project: value} : null,
          serverResponseParam: response => response.project_id,
          routeUrlParam: (params, value) => {
            const type = 'params';
            const name = 'projectId';
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }
            if (value) {
              _merge(params, {[type]: {[name]: value}})
              result.merged = true
            } else {
              //deleteRouteUrlParam(params, type, name)
              _merge(params, {[type]: {[name]: null}})
              result.deleted = true
            }
            return result
          },
          getStoreValue: (store) => store.getters[moduleName + '/projectId'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setProjectId', value),
          setStoreDefaultValue: store => store.commit(moduleName + '/setProjectId', ''),
        },
        {
          name: 'isCreated',
          propValue: (props) => props['isCreated'],
          serverRequestParam: value => value ? {filter_submit: '1'} : null,
          serverResponseParam: null,
          routeUrlParam: null,
          // routeUrlParam: (params, value, store) => {
          //   const type = 'query'
          //   const name = 'x'
          //   const result = {
          //     merged: false,
          //     deleted: false,
          //     type,
          //     name,
          //     value,
          //     params,
          //   }
          //
          //   const paramUrlValue = value ? '1' : ''
          //   //if (params?.query?.search && paramUrlValue && paramUrlValue !== 'title,description' && paramUrlValue !== 'description,title') {
          //   if (paramUrlValue) {
          //     _merge(params, {[type]: {[name]: paramUrlValue}})
          //     result.merged = true
          //   } else {
          //     deleteRouteUrlParam(params, type, name)
          //     result.deleted = true
          //   }
          //   console.log('useParams ReportGeneral isCreated routeUrlParam', params, value, paramUrlValue, result)
          //   return result
          // },
          getStoreValue: (store) => store.getters[moduleName + '/isCreated'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setIsCreated', value),
          setStoreDefaultValue: store => store.commit(moduleName + '/setIsCreated', null),
        },
        {
          name: 'color',
          propValue: (props) => props['color'],
          serverRequestParam: value => value ? {color: value} : null,
          serverResponseParam: response => response?.postData?.color,
          routeUrlParam: (params, value) => {
            const type = 'query'
            const name = 'c'
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }
            if (value) {
              _merge(params, {[type]: {[name]: value}})
              result.merged = true
            } else {
              deleteRouteUrlParam(params, type, name)
              result.deleted = true
            }
            return result
          },
          getStoreValue: (store) => store.getters[moduleName + '/color'],
          setStoreValue: (store, value) =>  store.commit(moduleName + '/setColor', value),
          setStoreDefaultValue: store => store.commit(moduleName + '/setFilterDefaultValue', 'color'),
        },
        {
          name: 'createdBy',
          propValue: (props) => {
            console.log('useParams ReportGeneral createdBy propValue', props['createdBy'], JSON.stringify(props))
            return Array.isArray(props['createdBy']) && props['createdBy'].length ? props['createdBy'] : null
          },
          serverRequestParam: (value: string[]) => {
            console.log('useParams ReportGeneral createdBy serverRequestParam', value)
            return value ? {created_by_id: value} : null
          },
          serverResponseParam: response => response?.postData?.created_by_id,
          routeUrlParam: (params, value, store) => {
            const type = 'query'
            const name = 'a'
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }

            const paramUrlValue = Array.isArray(value) ? value.join(',') : ''
            //if (params?.query?.search && paramUrlValue && paramUrlValue !== 'title,description' && paramUrlValue !== 'description,title') {
            if (paramUrlValue) {
              _merge(params, {[type]: {[name]: paramUrlValue}})
              result.merged = true
            } else {
              deleteRouteUrlParam(params, type, name)
              result.deleted = true
            }
            console.log('useParams ReportGeneral createdBy routeUrlParam', params, value, paramUrlValue, result)
            return result
          },
          getStoreValue: store => {
            console.log('useParams ReportGeneral createdBy getStoreValue', moduleName, store.getters[moduleName + '/createdBy'])
            return store.getters[moduleName + '/createdBy']
          },
          setStoreValue: (store, value) => {
            store.commit(moduleName + '/setCreatedBy', value)
            console.log('useParams ReportGeneral createdBy setStoreValue', store, value, store.getters[moduleName + '/createdBy'], moduleName)
          },
          setStoreDefaultValue: store => store.commit(moduleName + '/setFilterDefaultValue', 'createdBy'),
        },
        {
          name: 'assignedTo',
          propValue: (props) => Array.isArray(props['assignedTo']) && props['assignedTo'].length ? props['assignedTo'] : null, //props['assignedTo'],
          serverRequestParam: (value: string[]) => value ? {assigned_to_id: value} : null,
          serverResponseParam: response => response?.postData?.assigned_to_id,
          routeUrlParam: (params, value, store) => {
            const type = 'query'
            const name = 'u'
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }

            const paramUrlValue = Array.isArray(value) ? value.join(',') : ''
            //if (params?.query?.search && paramUrlValue && paramUrlValue !== 'title,description' && paramUrlValue !== 'description,title') {
            if (paramUrlValue) {
              _merge(params, {[type]: {[name]: paramUrlValue}})
              result.merged = true
            } else {
              deleteRouteUrlParam(params, type, name)
              result.deleted = true
            }
            return result
          },
          getStoreValue: store => store.getters[moduleName + '/assignedTo'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setAssignedTo', value),
          setStoreDefaultValue: store => store.commit(moduleName + '/setFilterDefaultValue', 'assignedTo'),
        },
        {
          name: 'stagesFilter',
          propValue: (props) => Array.isArray(props['stagesFilter']) && props['stagesFilter'].length ? props['stagesFilter'] : null, //props['stagesFilter'],
          serverRequestParam: (value: string[]) => value ? {stage: value} : null,
          serverResponseParam: response => response?.postData?.stage,
          routeUrlParam: (params, value, store) => {
            const type = 'query'
            const name = 's'
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }

            const paramUrlValue = Array.isArray(value) ? value.join(',') : ''
            //if (params?.query?.search && paramUrlValue && paramUrlValue !== 'title,description' && paramUrlValue !== 'description,title') {
            if (paramUrlValue) {
              _merge(params, {[type]: {[name]: paramUrlValue}})
              result.merged = true
            } else {
              deleteRouteUrlParam(params, type, name)
              result.deleted = true
            }
            return result
          },
          getStoreValue: store => store.getters[moduleName + '/stagesFilter'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setStagesFilter', value),
          setStoreDefaultValue: store => store.commit(moduleName + '/setFilterDefaultValue', 'stagesFilter'),
        },
        {
          name: 'tagsFilter',
          propValue: (props) => Array.isArray(props['tagsFilter']) && props['tagsFilter'].length ? props['tagsFilter'] : null, //props['tagsFilter'],
          serverRequestParam: (value: string[]) => value ? {tag_names: value} : null,
          serverResponseParam: response => response?.postData?.tag_names,
          routeUrlParam: (params, value, store) => {
            const type = 'query'
            const name = 't'
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }

            const paramUrlValue = Array.isArray(value) ? value.join(',') : ''
            //if (params?.query?.search && paramUrlValue && paramUrlValue !== 'title,description' && paramUrlValue !== 'description,title') {
            if (paramUrlValue) {
              _merge(params, {[type]: {[name]: paramUrlValue}})
              result.merged = true
            } else {
              deleteRouteUrlParam(params, type, name)
              result.deleted = true
            }
            return result
          },
          getStoreValue: store => store.getters[moduleName + '/tagsFilter'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setTagsFilter', value),
          setStoreDefaultValue: store => store.commit(moduleName + '/setFilterDefaultValue', 'tagsFilter'),
        },
        {
          name: 'users',
          propValue: null,
          serverRequestParam: null,
          serverResponseParam: response => response.users,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/users'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setUsers', value)
        },
        {
          name: 'stages',
          serverRequestParam: null,
          serverResponseParam: response => response.stages,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/stages'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setStages', value)
        },
        {
          name: 'fields',
          serverRequestParam: null,
          serverResponseParam: response => response.fields,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/fields'],
          setStoreValue: (store, value) => store.commit(moduleName + '/set_fields', value)
        },
        {
          name: 'periods',
          serverRequestParam: null,
          serverResponseParam: response => response.period_list,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/periods'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setPeriods', value)
        },
        {
          name: 'periodFilter',
          propValue: (props) => props['periodFilter'],
          serverRequestParam: value => value ? {period: value} : null,
          serverResponseParam: response => response?.postData?.period,
          routeUrlParam: (params, value) => {
            const type = 'query'
            const name = 'p'
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }
            if (value && value !== 'last_14_days') {
              _merge(params, {[type]: {[name]: value}})
              result.merged = true
            } else {
              deleteRouteUrlParam(params, type, name)
              result.deleted = true
            }
            return result
          },
          getStoreValue: (store) => store.getters[moduleName + '/periodFilter'],
          setStoreValue: (store, value) =>  store.commit(moduleName + '/setPeriodFilter', value),
          setStoreDefaultValue: store => store.commit(moduleName + '/setFilterDefaultValue', 'periodFilter'),
        },
        {
          name: 'fromDate',
          propValue: (props) => props['fromDate'],
          serverRequestParam: value => value ? {from_date: value} : null,
          serverResponseParam: response => response?.postData?.from_date,
          routeUrlParam: (params, value) => {
            const type = 'query'
            const name = 'b'
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }
            //console.log('useParams report fromDate', value, params?.query?.p, params)
            if (value && params?.query?.p === 'custom') {
              _merge(params, {[type]: {[name]: value}})
              result.merged = true
            } else {
              deleteRouteUrlParam(params, type, name)
              result.deleted = true
            }
            return result
          },
          getStoreValue: (store) => store.getters[moduleName + '/fromDate'],
          setStoreValue: (store, value) =>  store.commit(moduleName + '/setFromDate', value),
          setStoreDefaultValue: store => store.commit(moduleName + '/setFilterDefaultValue', 'fromDate'),
        },
        {
          name: 'toDate',
          propValue: (props) => props['toDate'],
          serverRequestParam: value => value ? {to_date: value} : null,
          serverResponseParam: response => response?.postData?.to_date,
          routeUrlParam: (params, value) => {
            const type = 'query'
            const name = 'e'
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }
            //console.log('useParams report toDate', value, params?.query?.p, params)
            if (value && params?.query?.p === 'custom') {
              _merge(params, {[type]: {[name]: value}})
              result.merged = true
            } else {
              deleteRouteUrlParam(params, type, name)
              result.deleted = true
            }
            return result
          },
          getStoreValue: (store) => store.getters[moduleName + '/toDate'],
          setStoreValue: (store, value) =>  store.commit(moduleName + '/setToDate', value),
          setStoreDefaultValue: store => store.commit(moduleName + '/setFilterDefaultValue', 'toDate'),
        },

        {
          name: 'archived',
          propValue: (props) => props['archived'],
          serverRequestParam: value => ({archived: value ? 'show' : 'hide'}),
          serverResponseParam: response => response?.postData?.archived !== 'hide',
          routeUrlParam: (params, value) => {
            const type = 'query'
            const name = 'r'
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }
            if (value) {
              _merge(params, {[type]: {[name]: '1'}})
              result.merged = true
            } else {
              deleteRouteUrlParam(params, type, name)
              result.deleted = true
            }
            return result
          },
          getStoreValue: (store) => store.getters[moduleName + '/archived'],
          setStoreValue: (store, value) =>  store.commit(moduleName + '/setArchived', value),
          setStoreDefaultValue: store => store.commit(moduleName + '/setFilterDefaultValue', 'archived'),
        },
/*
        {
          name: 'header',
          propValue: (props) => {
            console.log('report_general useParams header propValue', props['header'])
            return props['header'] ? 'yes' : 'no'
          },
          serverRequestParam: value => {
            console.log('report_general useParams header serverRequestParam', value)
            return value ? {header: 'header'} : null
          },
          serverResponseParam: response => {
            console.log('report_general useParams header serverResponseParam', !!response?.showHeader)
            return !!response?.showHeader
          },
          routeUrlParam: (params, value) => {
            console.log('report_general useParams header routeUrlParam', value)
            const type = 'query'
            const name = 'h'
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }
            if (value) {
              deleteRouteUrlParam(params, type, name)
              result.deleted = true
            } else {
              _merge(params, {[type]: {[name]: '0'}})
              result.merged = true
            }
            return result
          },
          getStoreValue: (store) => store.getters[moduleName + '/header'],
          setStoreValue: (store, value) => {
            console.log('report_general useParams header setStoreValue', value)
            store.commit(moduleName + '/setHeader', value === true || value === 'yes')
          },
          setStoreDefaultValue: store => store.commit(moduleName + '/setFilterDefaultValue', 'header'),
        },
*/
        {
          name: 'headerType',
          propValue: (props) => {
            console.log('report_general useParams headerType propValue', props['headerType'])
            return props['headerType']
          },
          serverRequestParam: value => {
            console.log('report_general useParams headerType serverRequestParam', value, value === 'yes' ? {header: 'header'} : null)

            return value === 'none' ? null : (value === 'stats' ? {header: 'header'} : {chart: 'chart'})
          },
          serverResponseParam: null,
          // response => {
          //   console.log('report_general useParams headerType serverResponseParam', response?.showHeader)
          //   return response?.showHeader ? 'yes' : 'no'
          // },
          routeUrlParam: (params, value) => {
            console.log('report_general useParams headerType routeUrlParam', value)
            const type = 'query'
            const name = 'h'
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }
            if (value === 'stats') {
              deleteRouteUrlParam(params, type, name)
              result.deleted = true
            } else {
              _merge(params, {[type]: {[name]: value[0]}})
              result.merged = true
            }
            return result
          },
          getStoreValue: (store) => store.getters[moduleName + '/headerType'],
          setStoreValue: (store, value) => {
            console.log('report_general useParams headerType setStoreValue', value)
            store.commit(moduleName + '/setHeaderType', value)
          },
          setStoreDefaultValue: store => store.commit(moduleName + '/setFilterDefaultValue', 'headerType'),
        },
/*
        {
          name: 'headerFilter',
          propValue: (props) => {
            console.log('report_general useParams headerFilter propValue', props['headerFilter'])
            return props['headerFilter']
          },
          serverRequestParam: value => {
            console.log('report_general useParams headerFilter serverRequestParam', value, value === 'yes' ? {header: 'header'} : null)
            return value === 'yes' ? {header: 'header'} : null
          },
          serverResponseParam: response => {
            console.log('report_general useParams headerFilter serverResponseParam', response?.showHeader)
            return response?.showHeader ? 'yes' : 'no'
          },
          routeUrlParam: (params, value) => {
            console.log('report_general useParams headerFilter routeUrlParam', value)
            const type = 'query'
            const name = 'h'
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }
            if (value === 'yes') {
              deleteRouteUrlParam(params, type, name)
              result.deleted = true
            } else {
              _merge(params, {[type]: {[name]: value}})
              result.merged = true
            }
            return result
          },
          getStoreValue: (store) => store.getters[moduleName + '/headerFilter'],
          setStoreValue: (store, value) => {
            console.log('report_general useParams headerFilter setStoreValue', value)
            store.commit(moduleName + '/setHeaderFilter', value)
          },
          setStoreDefaultValue: store => store.commit(moduleName + '/setFilterDefaultValue', 'headerFilter'),
        },
        {
          name: 'chartFilter',
          propValue: (props) => {
            console.log('report_general useParams chartFilter propValue', props['chartFilter'])
            return props['chartFilter']
          },
          serverRequestParam: value => {
            console.log('report_general useParams chartFilter serverRequestParam', value, value === 'yes' ? {chart: 'chart'} : null)
            return value === 'yes' ? {chart: 'chart'} : null
          },
          serverResponseParam: response => {
            console.log('report_general useParams chartFilter serverResponseParam', response?.chart)
            return response?.chart ? 'yes' : 'no'
          },
          routeUrlParam: (params, value) => {
            console.log('report_general useParams chartFilter routeUrlParam', value)
            const type = 'query'
            const name = 'g'
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }
            if (value !== 'yes') {
              deleteRouteUrlParam(params, type, name)
              result.deleted = true
            } else {
              _merge(params, {[type]: {[name]: value}})
              result.merged = true
            }
            return result
          },
          getStoreValue: (store) => store.getters[moduleName + '/chartFilter'],
          setStoreValue: (store, value) => {
            console.log('report_general useParams chartFilter setStoreValue', value)
            store.commit(moduleName + '/setChartFilter', value)
          },
          setStoreDefaultValue: store => store.commit(moduleName + '/setFilterDefaultValue', 'chartFilter'),
        },
*/
        {
          name: 'notes',
          propValue: (props) => props['notes'],
          serverRequestParam: value => value ? {notes: 'notes'} : null,
          serverResponseParam: response => response?.postData?.notes,
          routeUrlParam: (params, value) => {
            const type = 'query'
            const name = 'n'
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }
            if (value) {
              _merge(params, {[type]: {[name]: '1'}})
              result.merged = true
            } else {
              deleteRouteUrlParam(params, type, name)
              result.deleted = true
            }
            return result
          },
          getStoreValue: (store) => store.getters[moduleName + '/notes'],
          setStoreValue: (store, value) =>  store.commit(moduleName + '/setNotes', value),
          setStoreDefaultValue: store => store.commit(moduleName + '/setFilterDefaultValue', 'notes'),
        },
        {
          name: 'headerStatistics',
          serverRequestParam: null,
          serverResponseParam: response => response.headerStatistic,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/headerStatistics'],
          setStoreValue: (store, value) => {
            store.commit(moduleName + '/setHeaderStatistics', value)
            if (value) {
              store.commit(moduleName + '/setIsIrrelevantHeaderStatistics', false)
            }
          },
        },
        {
          name: 'chartData',
          serverRequestParam: null,
          serverResponseParam: response => response.chart,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/chartData'],
          setStoreValue: (store, value) => {
            store.commit(moduleName + '/setChartData', value)
            if (value) {
              store.commit(moduleName + '/setIsIrrelevantChartData', false)
            }
          },
        },
        // {
        //   name: 'showHeader',
        //   serverRequestParam: null,
        //   serverResponseParam: response => !!response.showHeader,
        //   routeUrlParam: null,
        //   getStoreValue: (store) => store.getters[moduleName + '/showHeader'],
        //   setStoreValue: (store, value) => store.commit(moduleName + '/setShowHeader', value),
        //   setStoreDefaultValue: store => store.commit(moduleName + '/setFilterDefaultValue', 'showHeader'),
        // },

        {
          name: 'tickets',
          serverRequestParam: null,
          serverResponseParam: response => response?.report?.tickets || [],
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/tickets'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setTickets', value)
        },
        {
          name: 'order',
          propValue: (props) => props['order'],
          serverRequestParam: value => value ? {order: value} : null,
          serverResponseParam: response => response.orderCol,
          routeUrlParam: (params, value) => {
            const type = 'query'
            const name = 'o'
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }
            if (value && value !== 'created_on') {
              _merge(params, {[type]: {[name]: value}})
              result.merged = true
            } else {
              deleteRouteUrlParam(params, type, name)
              result.deleted = true
            }
            return result
          },
          getStoreValue: (store) => store.getters[moduleName + '/order'],
          setStoreValue: (store, value) => store.commit(moduleName + '/set_order', value),
          setStoreDefaultValue: store => store.commit(moduleName + '/set_order', null),
        },
        {
          name: 'reverse',
          propValue: props => props['reverse'],
          serverRequestParam: value => value ? {reverse: 1} : null,
          serverResponseParam: response => !!response.reverse,
          routeUrlParam: (params, value) => {
            const type = 'query'
            const name = 'd'
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }
            if (value && params?.query?.o) {
              _merge(params, {[type]: {[name]: value ? 1 : 0}})
              result.merged = true
            } else {
              deleteRouteUrlParam(params, type, name)
              result.deleted = true
            }
            return result
          },
          getStoreValue: store => store.getters[moduleName + '/reverse'],
          setStoreValue: (store, value) => store.commit(moduleName + '/set_reverse', value),
          setStoreDefaultValue: store => store.commit(moduleName + '/set_reverse', null),
        },
        {
          name: 'pageCurrent',
          propValue: (props) => props['pageCurrent'],
          serverRequestParam: value => value ? {page: value} : null,
          serverResponseParam: response => response.tickets_view?.pageCurrent,

          routeUrlParam: (params, value) => {
            const type = 'query'
            const name = 'page'
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }
            if (value > 1) {
              _merge(params, {[type]: {[name]: value}})
              result.merged = true
            } else {
              deleteRouteUrlParam(params, type, name)
              result.deleted = true
            }
            return result
          },

          getStoreValue: (store) => store.getters[moduleName + '/pageCurrent'],
          setStoreValue: (store, value) => store.commit(moduleName + '/set_pageCurrent', value),
          //setStoreDefaultValue: store => store.commit(moduleName + '/set_pageCurrent', 1),
          setStoreDefaultValue: store => store.commit(moduleName + '/setFilterDefaultValue', 'pageCurrent'),
        },
        {
          name: 'pageLast',
          serverRequestParam: null,
          serverResponseParam: response => response.tickets_view?.pageLast,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/pageLast'],
          setStoreValue: (store, value) => store.commit(moduleName + '/set_pageLast', value)
        },
        {
          name: 'itemsCount',
          serverRequestParam: null,
          serverResponseParam: response => response.tickets_view?.itemsCount,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/itemsCount'],
          setStoreValue: (store, value) => store.commit(moduleName + '/set_itemsCount', value)
        },
        {
          name: 'onlyTable',
          serverRequestParam: value => ({only_table: value ? 1 : 0}),
          serverResponseParam: null,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/onlyTable'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setOnlyTable', value),
        },
      ]
      break
    case 'project_info':
      paramsMap = [
        ...getParamsMapCurrentProject(),
        ...getParamsMapDebug(),
        ...getParamsMapAutorefresh(),
        {
          name: 'tab',
          propValue: () => 'project_info',
          serverRequestParam: () => ({tab: 'project_info'}),
          serverResponseParam: null,
          routeUrlParam: null,
          getStoreValue: () => 'project_info',
          setStoreValue: null,
        },

        {
          name: 'projectId',
          propValue: (props) => props['projectId'],
          serverRequestParam: value => value ? {project: value} : null,
          serverResponseParam: response => response.project_id,
          routeUrlParam: (params, value) => {
            const type = 'params';
            const name = 'projectId';
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }
            if (value) {
              _merge(params, {[type]: {[name]: value}})
              result.merged = true
            } else {
              //deleteRouteUrlParam(params, type, name)
              _merge(params, {[type]: {[name]: null}})
              result.deleted = true
            }
            return result
          },
          getStoreValue: (store) => store.getters[moduleName + '/projectId'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setProjectId', value),
          setStoreDefaultValue: store => store.commit(moduleName + '/setProjectId', ''),
        },
        {
          name: 'createdBy',
          serverRequestParam: null,
          serverResponseParam: response => response.createdBy,
          routeUrlParam: null,
          getStoreValue: null,
          setStoreValue: (store, value) => store.commit(moduleName + '/setCreatedBy', value)
        },
        {
          name: 'createdOn',
          serverRequestParam: null,
          serverResponseParam: response => response.createdOn,
          routeUrlParam: null,
          getStoreValue: null,
          setStoreValue: (store, value) => store.commit(moduleName + '/setCreatedOn', value)
        },
        {
          name: 'ticketsPostingEmail',
          serverRequestParam: null,
          serverResponseParam: response => response.ticketsPostingEmail,
          routeUrlParam: null,
          getStoreValue: null,
          setStoreValue: (store, value) => store.commit(moduleName + '/setTicketsPostingEmail', value)
        },
        {
          name: 'ticketsPostingHelpLink',
          serverRequestParam: null,
          serverResponseParam: response => response.ticketsPostingHelpLink,
          routeUrlParam: null,
          getStoreValue: null,
          setStoreValue: (store, value) => store.commit(moduleName + '/setTicketsPostingHelpLink', value)
        },
        {
          name: 'readOnly',
          serverRequestParam: null,
          serverResponseParam: response => !!response?.readOnly,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/readOnly'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setReadOnly', value)
        },
        {
          name: 'showBigcommerceInvite',
          serverRequestParam: null,
          serverResponseParam: response => !!response?.showBigcommerceInvite,
          routeUrlParam: null,
          getStoreValue: null,
          setStoreValue: (store, value) => store.commit(moduleName + '/setShowBigcommerceInvite', value)
        },
        {
          name: 'bigcommerceConnectLink',
          serverRequestParam: null,
          serverResponseParam: response => response.bigcommerceConnectLink,
          routeUrlParam: null,
          getStoreValue: null,
          setStoreValue: (store, value) => store.commit(moduleName + '/setBigcommerceConnectLink', value)
        },
        {
          name: 'bigcommerceIsConnected',
          serverRequestParam: null,
          serverResponseParam: response => !!response?.bigcommerceIsConnected,
          routeUrlParam: null,
          getStoreValue: null,
          setStoreValue: (store, value) => store.commit(moduleName + '/setBigcommerceIsConnected', value)
        },
        {
          name: 'bigcommerceSettingsLink',
          serverRequestParam: null,
          serverResponseParam: response => response.bigcommerceSettingsLink,
          routeUrlParam: null,
          getStoreValue: null,
          setStoreValue: (store, value) => store.commit(moduleName + '/setBigcommerceSettingsLink', value)
        },
      ]
      break
    case 'project_team':
      paramsMap = [
        ...getParamsMapCurrentProject(),
        ...getParamsMapDebug(),
        ...getParamsMapAutorefresh(),
        {
          name: 'tab',
          propValue: () => 'team',
          serverRequestParam: () => ({tab: 'team'}),
          serverResponseParam: null,
          routeUrlParam: null,
          getStoreValue: () => 'team',
          setStoreValue: null,
        },

        {
          name: 'projectId',
          propValue: (props) => props['projectId'],
          serverRequestParam: value => value ? {project: value} : null,
          serverResponseParam: response => response.project_id,
          routeUrlParam: (params, value) => {
            const type = 'params';
            const name = 'projectId';
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }
            if (value) {
              _merge(params, {[type]: {[name]: value}})
              result.merged = true
            } else {
              //deleteRouteUrlParam(params, type, name)
              _merge(params, {[type]: {[name]: null}})
              result.deleted = true
            }
            return result
          },
          getStoreValue: (store) => store.getters[moduleName + '/projectId'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setProjectId', value),
          setStoreDefaultValue: store => store.commit(moduleName + '/setProjectId', ''),
        },
        {
          name: 'roles',
          propValue: null,
          serverRequestParam: null,
          serverResponseParam: response => response.roles,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/roles'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setRoles', value)
        },
        {
          name: 'users',
          propValue: null,
          serverRequestParam: null,
          serverResponseParam: response => response.users,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/users'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setUsers', value)
        },
        {
          name: 'steps',
          propValue: null,
          serverRequestParam: null,
          serverResponseParam: response => response.steps,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/steps'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setSteps', value)
        },
        {
          name: 'readOnly',
          serverRequestParam: null,
          serverResponseParam: response => !!response?.readOnly,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/readOnly'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setReadOnly', value)
        },
        {
          name: 'isDefaultUsersExternalProject',
          serverRequestParam: null,
          serverResponseParam: response => !!response.isDefaultUsersExternalProject,
          routeUrlParam: null,
          getStoreValue: null,
          setStoreValue: (store, value) => store.commit(moduleName + '/setIsDefaultUsersExternalProject', value)
        },

      ]
      break
    case 'workflow_designer':
      paramsMap = [
        ...getParamsMapCurrentProject(),
        ...getParamsMapDebug(),
        ...getParamsMapAutorefresh(),
        {
          name: 'tab',
          propValue: () => 'workflow',
          serverRequestParam: () => ({tab: 'workflow'}),
          serverResponseParam: null,
          routeUrlParam: null,
          getStoreValue: () => 'workflow',
          setStoreValue: null,
        },

        {
          name: 'projectId',
          propValue: (props) => props['projectId'],
          serverRequestParam: value => value ? {project: value} : null,
          serverResponseParam: response => response.project_id,
          routeUrlParam: (params, value) => {
            const type = 'params';
            const name = 'projectId';
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }
            if (value) {
              _merge(params, {[type]: {[name]: value}})
              result.merged = true
            } else {
              //deleteRouteUrlParam(params, type, name)
              _merge(params, {[type]: {[name]: null}})
              result.deleted = true
            }
            return result
          },
          getStoreValue: (store) => store.getters[moduleName + '/projectId'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setProjectId', value),
          setStoreDefaultValue: store => store.commit(moduleName + '/setProjectId', ''),
        },
        {
          name: 'readOnly',
          serverRequestParam: null,
          serverResponseParam: response => !!response?.readOnly,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/readOnly'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setReadOnly', value)
        },
        {
          name: 'steps',
          propValue: null,
          serverRequestParam: null,
          serverResponseParam: response => response.steps,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/steps'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setSteps', value)
        },

      ]
      break
    case 'ticket_designer':
      paramsMap = [
        ...getParamsMapCurrentProject(),
        ...getParamsMapDebug(),
        ...getParamsMapAutorefresh(),
        {
          name: 'tab',
          propValue: () => 'ticket_form',
          serverRequestParam: () => ({tab: 'ticket_form'}),
          serverResponseParam: null,
          routeUrlParam: null,
          getStoreValue: () => 'ticket_form',
          setStoreValue: null,
        },

        {
          name: 'projectId',
          propValue: (props) => props['projectId'],
          serverRequestParam: value => value ? {project: value} : null,
          serverResponseParam: response => response.project_id,
          routeUrlParam: (params, value) => {
            const type = 'params';
            const name = 'projectId';
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }
            if (value) {
              _merge(params, {[type]: {[name]: value}})
              result.merged = true
            } else {
              //deleteRouteUrlParam(params, type, name)
              _merge(params, {[type]: {[name]: null}})
              result.deleted = true
            }
            return result
          },
          getStoreValue: (store) => store.getters[moduleName + '/projectId'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setProjectId', value),
          setStoreDefaultValue: store => store.commit(moduleName + '/setProjectId', ''),
        },
        {
          name: 'schemaByRows',
          serverRequestParam: null,
          serverResponseParam: response => response.schemaByRows,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/schemaByRows'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setSchemaByRows', value)
        },
        {
          name: 'schema',
          serverRequestParam: null,
          serverResponseParam: response => response.schema,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/schema'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setSchema', value)
        },
        {
          name: 'defaultDateOptions',
          serverRequestParam: null,
          serverResponseParam: response => response.defaultDateOptions,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/defaultDateOptions'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setDefaultDateOptions', value)
        },
        {
          name: 'flowSteps',
          serverRequestParam: null,
          serverResponseParam: response => response.flowSteps,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/flowSteps'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setFlowSteps', value)
        },
      ]
      break
    case 'automation':
      paramsMap = [
        ...getParamsMapPanelsParams(moduleName),
        ...getParamsMapAutorefresh(),
        ...getParamsMapCurrentProject(),
        ...getParamsMapDebug(),
        {
          name: 'tab',
          propValue: () => 'automation',
          serverRequestParam: () => ({tab: 'automator'}),
          serverResponseParam: null,
          routeUrlParam: null,
          getStoreValue: () => 'automation',
          setStoreValue: null,
        },

        {
          name: 'projectId',
          propValue: (props) => props['projectId'],
          serverRequestParam: value => value ? {project: value} : null,
          serverResponseParam: response => response.project_id,
          routeUrlParam: (params, value) => {
            const type = 'params';
            const name = 'projectId';
            const result = {
              merged: false,
              deleted: false,
              type,
              name,
              value,
              params,
            }
            if (value) {
              _merge(params, {[type]: {[name]: value}})
              result.merged = true
            } else {
              //deleteRouteUrlParam(params, type, name)
              _merge(params, {[type]: {[name]: null}})
              result.deleted = true
            }
            return result
          },
          getStoreValue: (store) => store.getters[moduleName + '/projectId'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setProjectId', value),
          setStoreDefaultValue: store => store.commit(moduleName + '/setProjectId', ''),
        },
        {
          name: 'rules',
          propValue: null,
          serverRequestParam: null,
          serverResponseParam: response => response.rules,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/rules'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setRules', value)
        },
        {
          name: 'actions',
          propValue: null,
          serverRequestParam: null,
          serverResponseParam: response => response.actions_list,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/actions'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setActions', value)
        },
        {
          name: 'conditions',
          propValue: null,
          serverRequestParam: null,
          serverResponseParam: response => response.conditions_list,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/conditions'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setConditions', value)
        },
        {
          name: 'conditionsIfs',
          propValue: null,
          serverRequestParam: null,
          serverResponseParam: response => response.conditions_ifs_list,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/conditionsIfs'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setConditionsIfs', value)
        },
        {
          name: 'projects',
          propValue: null,
          serverRequestParam: null,
          serverResponseParam: response => response.projects,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/projects'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setProjects', value)
        },
        {
          name: 'users',
          propValue: null,
          serverRequestParam: null,
          serverResponseParam: response => response.users,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/users'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setUsers', value)
        },
        {
          name: 'steps',
          propValue: null,
          serverRequestParam: null,
          serverResponseParam: response => response.steps,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/steps'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setSteps', value)
        },
        {
          name: 'templates',
          propValue: null,
          serverRequestParam: null,
          serverResponseParam: response => response.templates,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/templates'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setTemplates', value)
        },
        {
          name: 'logs',
          serverRequestParam: null,
          serverResponseParam: response => response?.logs || null,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/logs'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setLogs', value)
        },
        {
          name: 'logsForOffset',
          serverRequestParam: null,
          serverResponseParam: response => response?.logs_for_offset || [],
          routeUrlParam: null,
          getStoreValue: null,
          setStoreValue: (store, value) => store.commit(moduleName + '/appendLogs', value)
        },
        {
          name: 'logsOffset',
          serverRequestParam: value => value ? {offset: value} : null,
          serverResponseParam: response => response?.logs_offset,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/logsOffset'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setLogsOffset', value)
        },
        {
          name: 'isMoreLogs',
          serverRequestParam: null,
          serverResponseParam: response => !response?.logs_is_all,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/isMoreLogs'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setIsMoreLogs', value)
        },
        {
          name: 'welcomeKey',
          serverRequestParam: null,
          serverResponseParam: response => response?.welcome_key,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/welcomeKey'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setWelcomeKey', value)
        },
        {
          name: 'welcomeShow',
          serverRequestParam: null,
          serverResponseParam: response => !!response?.welcome_show,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/welcomeShow'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setWelcomeShow', value)
        },
        {
          name: 'readOnly',
          serverRequestParam: null,
          serverResponseParam: response => !!response?.readOnly,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/readOnly'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setReadOnly', value)
        },
        {
          name: 'timeOptions',
          serverRequestParam: null,
          serverResponseParam: response => response?.time_options || null,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/timeOptions'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setTimeOptions', value)
        },
        {
          name: 'days',
          propValue: null,
          serverRequestParam: null,
          serverResponseParam: response => response.days,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/days'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setDays', value)
        },
        {
          name: 'periodsForTimeSpentInStage',
          propValue: null,
          serverRequestParam: null,
          serverResponseParam: response => response.periods_time_spent_in_stage,
          routeUrlParam: null,
          getStoreValue: (store) => store.getters[moduleName + '/periodsForTimeSpentInStage'],
          setStoreValue: (store, value) => store.commit(moduleName + '/setPeriodsForTimeSpentInStage', value)
        },
      ]
    break
  }

  return {
    paramsMap
  }
}

/*
{
  name: '',
  serverRequestParam: value => ({}),
  serverResponseParam: response => response?.,
  routeUrlParam: value => value ? {query: {}} : null,
  getStoreValue: (store) => store.getters[module + '/'],
  setStoreValue: (store, value) => store.commit(module + '/', value)
},
*/

const deleteRouteUrlParam = (params, type, name) => {
  if (typeof params[type] === 'object' && params[type]) {
    delete params[type][name]
  }
}

const getParamsMapDatatable = (module): Array<IStateParam> => {
  const serverResponseNamespace = module === 'people' ? 'users_view' : 'tickets_view'
  return [
  {
    name: 'order',
    propValue: (props) => props['order'],
    serverRequestParam: value => value ? {order: value} : null,
    serverResponseParam: response => response[serverResponseNamespace]?.order,
    routeUrlParam: (params, value) => {
      const type = 'query'
      const name = 'order'
      const result = {
        merged: false,
        deleted: false,
        type,
        name,
        value,
        params,
      }
      if (value && value !== 'created_on') {
        _merge(params, {[type]: {[name]: value}})
        result.merged = true
      } else {
        deleteRouteUrlParam(params, type, name)
        result.deleted = true
      }
      return result
    },
    getStoreValue: (store) => store.getters[module + '/order'],
    setStoreValue: (store, value) => store.commit(module + '/set_order', value),
    setStoreDefaultValue: store => store.commit(module + '/set_order', null),
  },
  {
    name: 'reverse',
    propValue: props => props['reverse'],
    serverRequestParam: value => value ? {reverse: 1} : null,
    serverResponseParam: response => response[serverResponseNamespace] && (response[serverResponseNamespace].reverse ? 1 : 0),
    routeUrlParam: (params, value) => {
      const type = 'query'
      const name = 'reverse'
      const result = {
        merged: false,
        deleted: false,
        type,
        name,
        value,
        params,
      }
      if (value && params?.query?.order) {
        _merge(params, {[type]: {[name]: value ? 1 : 0}})
        result.merged = true
      } else {
        deleteRouteUrlParam(params, type, name)
        result.deleted = true
      }
      return result
    },
    getStoreValue: store => store.getters[module + '/reverse'],
    setStoreValue: (store, value) => store.commit(module + '/set_reverse', value),
    setStoreDefaultValue: store => store.commit(module + '/set_reverse', null),
  },
  {
    name: 'pageCurrent',
    propValue: (props) => props['pageCurrent'],
    serverRequestParam: value => value ? {page: value} : null,
    serverResponseParam: response => response[serverResponseNamespace]?.pageCurrent,

    routeUrlParam: (params, value) => {
      const type = 'query'
      const name = 'page'
      const result = {
        merged: false,
        deleted: false,
        type,
        name,
        value,
        params,
      }
      if (value > 1) {
        _merge(params, {[type]: {[name]: value}})
        result.merged = true
      } else {
        deleteRouteUrlParam(params, type, name)
        result.deleted = true
      }
      return result
    },

    getStoreValue: (store) => store.getters[module + '/pageCurrent'],
    setStoreValue: (store, value) => store.commit(module + '/set_pageCurrent', value),
    setStoreDefaultValue: store => store.commit(module + '/set_pageCurrent', 1),
  },
  {
    name: 'fields',
    serverRequestParam: null,
    serverResponseParam: response => response[serverResponseNamespace]?.fields,
    routeUrlParam: null,
    getStoreValue: (store) => store.getters[module + '/fields'],
    setStoreValue: (store, value) => store.commit(module + '/set_fields', value)
  },
  {
    name: 'pageLast',
    serverRequestParam: null,
    serverResponseParam: response => response[serverResponseNamespace]?.pageLast,
    routeUrlParam: null,
    getStoreValue: (store) => store.getters[module + '/pageLast'],
    setStoreValue: (store, value) => store.commit(module + '/set_pageLast', value)
  },
  {
    name: 'itemsCount',
    serverRequestParam: null,
    serverResponseParam: response => response[serverResponseNamespace]?.itemsCount,
    routeUrlParam: null,
    getStoreValue: (store) => store.getters[module + '/itemsCount'],
    setStoreValue: (store, value) => store.commit(module + '/set_itemsCount', value)
  },
]
}

const getParamsMapNotes = (module) => [
  {
    name: 'notes',
    serverRequestParam: null,
    serverResponseParam: response => response?.notes_view?.items || null,
    routeUrlParam: null,
    getStoreValue: (store) => store.getters[module + '/notes'],
    setStoreValue: (store, value) => store.commit(module + '/set_notes', value)
  },
  {
    name: 'notesForOffset',
    serverRequestParam: null,
    serverResponseParam: response => response?.notes_view?.itemsForOffset || [],
    routeUrlParam: null,
    getStoreValue: null,
    setStoreValue: (store, value) => store.commit(module + '/appendNotes', value)
  },
  {
    name: 'notesOffset',
    serverRequestParam: value => value ? {offset: value} : null,
    serverResponseParam: response => response?.notes_view?.data_offset,
    routeUrlParam: null,
    getStoreValue: (store) => store.getters[module + '/notesOffset'],
    setStoreValue: (store, value) => store.commit(module + '/set_notesOffset', value)
  },
  {
    name: 'notesTimeOffset',
    serverRequestParam: value => value ? {time_offset: value} : null,
    serverResponseParam: response => response?.notes_view?.time_offset,
    routeUrlParam: null,
    getStoreValue: (store) => store.getters[module + '/notesTimeOffset'],
    setStoreValue: (store, value) => store.commit(module + '/set_notesTimeOffset', value)
  },
  {
    name: 'addNoteDisabled',
    serverRequestParam: null,
    serverResponseParam: response => response?.add_note_disabled,
    routeUrlParam: null,
    getStoreValue: (store) => store.getters[module + '/addNoteDisabled'],
    setStoreValue: (store, value) => store.commit(module + '/set_addNoteDisabled', value)
  },
  {
    name: 'isMoreNotes',
    serverRequestParam: null,
    serverResponseParam: response => !!response?.notes_view?.show_next,
    routeUrlParam: null,
    getStoreValue: (store) => store.getters[module + '/isMoreNotes'],
    setStoreValue: (store, value) => store.commit(module + '/set_isMoreNotes', value)
  },
  {
    name: 'isShowLogs',
    serverRequestParam: value => value ? {show_logs: value} : null,
    serverResponseParam: response => response?.notes_view?.show_logs,
    routeUrlParam: null,
    getStoreValue: (store) => store.getters[module + '/isShowLogs'],
    setStoreValue: (store, value) => store.commit(module + '/set_isShowLogs', value)
  },
  {
    name: 'noteSearch',
    serverRequestParam: value => value ? {noteSearch: value} : null,
    serverResponseParam: null, //response => response?.notes_view && Object.prototype.hasOwnProperty.call(response.notes_view, 'noteSearch') ? (response?.notes_view?.noteSearch || '') : null,
    routeUrlParam: null,
    getStoreValue: (store) => store.getters[module + '/noteSearch'],
    setStoreValue: null,
    //   (store, value) => {
    //   if (typeof value === 'string') {
    //     store.commit(module + '/set_noteSearch', value)
    //   }
    // }
  },
  {
    name: 'newNoteObj',
    serverRequestParam: null,
    serverResponseParam: response => response?.notes_view?.newNote || null,
    routeUrlParam: null,
    getStoreValue: (store) => store.getters[module + '/newNoteObj'],
    setStoreValue: (store, value) => store.commit(module + '/setNewNoteObj', value),
  },
  // {
  //   name: 'externalUser',
  //   serverRequestParam: null,
  //   serverResponseParam: response => response?.add_to_users || null,
  //   routeUrlParam: null,
  //   getStoreValue: (store) => store.getters[module + '/externalUser'],
  //   setStoreValue: (store, value) => { console.log('setExternalUser', value); store.commit(module + '/setExternalUser', value)},
  // },
  {
    name: 'storageLimitReached',
    serverRequestParam: null,
    serverResponseParam: response => !!response?.storageLimitReached,
    routeUrlParam: null,
    getStoreValue: null,
    setStoreValue: (store, value) => store.commit('setStorageLimitReached', value)
  },
]

const getParamsMapTicketsList = (module): Array<IStateParam> => [
  ...getParamsMapDatatable(module),
  ...getParamsMapUsersSelector(module),
  {
    name: 'tickets',
    serverRequestParam: null,
    serverResponseParam: response => response?.tickets_view?.items,
    routeUrlParam: null,
    getStoreValue: (store) => store.getters[module + '/tickets'],
    setStoreValue: (store, value) => store.commit(module + '/set_tickets', value)
  },
  {
    name: 'step',
    propValue: (props) => props['step'] === '0' ? null : props['step'],
    serverRequestParam: value => value && value !== '0' ? {step: value} : null,
    serverResponseParam: response => response?.tickets_view?.step,
    routeUrlParam: (params, value) => {
      const type = 'query'
      const name = 'step'
      const result = {
        merged: false,
        deleted: false,
        type,
        name,
        value,
        params,
      }
      if (value && value !== '0') {
        _merge(params, {[type]: {[name]: value}})
        result.merged = true
      } else {
        deleteRouteUrlParam(params, type, name)
        result.deleted = true
      }
      return result
    },
    getStoreValue: (store) => store.getters[module + '/step'],
    setStoreValue: (store, value) => store.commit(module + '/set_step', value),
    setStoreDefaultValue: store => store.commit(module + '/set_step', '0'),
  },
]
const getParamsMapTicketsFilters = (module): Array<IStateParam> => {
  //const serverResponseNamespace = module === 'people' ? 'users_view' : 'tickets_view'
  return [
    {
      name: 'search',
      propValue: (props) => props['search'],
      serverRequestParam: value => value ? {search: value} : null,
      serverResponseParam: null, //response => response?.tickets_view?.search,
      routeUrlParam: (params, value) => {
        const type = 'query'
        const name = 'search'
        const result = {
          merged: false,
          deleted: false,
          type,
          name,
          value,
          params,
        }
        if (value) {
          _merge(params, {[type]: {[name]: value}})
          result.merged = true
        } else {
          deleteRouteUrlParam(params, type, name)
          result.deleted = true
        }
        return result
      },
      getStoreValue: (store) => store.getters[module + '/search'],
      setStoreValue: (store, value) => store.commit(module + '/set_search', value),
      setStoreDefaultValue: store => store.commit(module + '/set_search', ''),
    },
    {
      name: 'searchIn',
      propValue: (props) => {
        console.log('useParams searchIn propValue', props['searchIn'], JSON.stringify(props))
        return props['searchIn']
      },
      serverRequestParam: (value: string[]) => value ? {search_fields: value} : null,
      serverResponseParam: null,
      routeUrlParam: (params, value, store) => {
        const type = 'query'
        const name = 'searchin'
        const result = {
          merged: false,
          deleted: false,
          type,
          name,
          value,
          params,
        }

        const searchInDefault = store.getters['ticketsfilters/searchInDefault']

        const paramUrlValue = value.join(',')
        //if (params?.query?.search && paramUrlValue && paramUrlValue !== 'title,description' && paramUrlValue !== 'description,title') {
        if (params?.query?.search && Array.isArray(value) && !_isEqual([...value].sort(), [...searchInDefault].sort())) {
          _merge(params, {[type]: {[name]: paramUrlValue}})
          result.merged = true
        } else {
          deleteRouteUrlParam(params, type, name)
          result.deleted = true
        }
        console.log('useParams searchIn routeUrlParam', params, value, paramUrlValue, result)
        return result
      },
      getStoreValue: store => {
        console.log('useParams searchIn getStoreValue', module, store.getters[module + '/searchIn'])
        return store.getters[module + '/searchIn']
      },
      setStoreValue: null,
      // setStoreDefaultValue: store => {
      //   console.log('useParams searchIn setStoreDefaultValue', module)
      //   store.commit(module + '/setSearchInDefault')
      // },
    },
    {
      name: 'searchFields',
      propValue: null,
      serverRequestParam: null,
      serverResponseParam: response => {
        console.log('useParams searchFields serverResponseParam', response?.tickets_view?.search_fields, response?.search_fields)
        return response?.search_fields || response?.tickets_view?.search_fields
      },
      routeUrlParam: null,
      getStoreValue: store => {
        console.log('useParams searchFields getStoreValue', module, store.getters[module + '/searchFields'])
        return store.getters[module + '/searchFields']
      },
      setStoreValue: (store, value) => {
        console.log('+++ useParams searchFields setStoreValue', module, value)
        store.commit(module + '/set_searchFields', value)
      },
      setStoreDefaultValue: store => {
        console.log('useParams searchFields setStoreDefaultValue', module)
        store.commit(module + '/set_searchFields', [])
      },
    },
    {
      name: 'requestedById',
      propValue: (props) => props['requestedById'],
      serverRequestParam: value => value ? {requested_by_id: value} : null,
      serverResponseParam: response => response?.requested_by_id || response?.tickets_view?.requested_by_id,
      routeUrlParam: (params, value) => {
        const type = 'query'
        const name = 'requested'
        const result = {
          merged: false,
          deleted: false,
          type,
          name,
          value,
          params,
        }
        if (value && value !== '0') {
          _merge(params, {[type]: {[name]: value}})
          result.merged = true
        } else {
          deleteRouteUrlParam(params, type, name)
          result.deleted = true
        }
        return result
      },

      getStoreValue: (store) => store.getters[module + '/requestedById'],
      setStoreValue: (store, value) => store.commit(module + '/set_requestedById', value),
      setStoreDefaultValue: store => store.commit(module + '/set_requestedById', ''),
    },
    {
      name: 'assignedToId',
      propValue: (props) => props['assignedToId'],
      serverRequestParam: value => value ? {assigned_to_id: value === 'nobody' ? '-1' : value} : null,
      serverResponseParam: response => response?.assigned_to_id || response?.tickets_view?.assigned_to_id,
      routeUrlParam: (params, value) => {
        const type = 'query'
        const name = 'assigned'
        const result = {
          merged: false,
          deleted: false,
          type,
          name,
          value,
          params,
        }
        if (value && value !== '0') {
          if (value === '-1') {
            value = 'nobody'
          }
          _merge(params, {[type]: {[name]: value}})
          result.merged = true
        } else {
          deleteRouteUrlParam(params, type, name)
          result.deleted = true
        }
        return result
      },

      getStoreValue: (store) => store.getters[module + '/assignedToId'],
      setStoreValue: (store, value) => store.commit(module + '/set_assignedToId', value === 'nobody' ? '-1' : value),
      setStoreDefaultValue: store => store.commit(module + '/set_assignedToId', ''),
    },
    {
      name: 'tagsFilter',
      propValue: null,
      serverRequestParam: value => Array.isArray(value) ? {tags: value.map(item => item.id)} : null,
      serverResponseParam: null,
      routeUrlParam: null,
      getStoreValue: (store) => store.getters[module + '/tagsFilterValues'],
      setStoreValue: (store, value) => store.commit(module + '/set_tagsFilterValues', value)
    },
    {
      name: 'tagsLogic',
      propValue: null,
      serverRequestParam: value => value ? {logic_tags: value} : null,
      serverResponseParam: null,
      routeUrlParam: null,
      getStoreValue: (store) => store.getters[module + '/tagsLogic'],
      setStoreValue: (store, value) => store.commit(module + '/setTagsLogic', value)
    },
  ]
}

const getParamsMapUsersSelector = (module): Array<IStateParam> => {
  return [
    {
      name: 'users',
      serverRequestParam: null,
      serverResponseParam: response => response?.users,
      routeUrlParam: null,
      getStoreValue: (store) => store.getters['usersForFilter'],
      setStoreValue: (store, value) => store.commit('set_usersForFilter', value)
    },

  ]
}

const getParamsMapTicketTasks = (moduleName): Array<IStateParam> => {
  return [
    {
      name: 'ticketTasks',
      serverRequestParam: null,
      serverResponseParam: response => response?.todos_data?.todos,
      routeUrlParam: null,
      getStoreValue: (store) => store.getters[moduleName + '/ticketTasks'],
      setStoreValue: (store, value) => store.commit(moduleName + '/setTicketTasks', value)
    },
    {
      name: 'canEditTasks',
      serverRequestParam: null,
      serverResponseParam: response => response?.todos_data?.can_edit,
      routeUrlParam: null,
      getStoreValue: (store) => store.getters[moduleName + '/canEditTasks'],
      setStoreValue: (store, value) => store.commit(moduleName + '/setCanEditTasks', !!value)
    },
    {
      name: 'hideClosedTasks',
      serverRequestParam: null,
      serverResponseParam: response => response?.todos_data?.hide_closed,
      routeUrlParam: null,
      getStoreValue: (store) => store.getters[moduleName + '/hideClosedTasks'],
      setStoreValue: (store, value) => store.commit(moduleName + '/setHideClosedTasks', !!value)
    },
    {
      name: 'foldedTasksArea',
      serverRequestParam: null,
      serverResponseParam: response => response?.todos_data?.collapsed,
      routeUrlParam: null,
      getStoreValue: (store) => store.getters[moduleName + '/foldedTasksArea'],
      setStoreValue: (store, value) => store.commit(moduleName + '/setFoldedTasksArea', !!value)
    },
  ]
}

const getParamsMapPanelsParams = (moduleName): Array<IStateParam> => {
  return [
    {
      name: 'resizablePanel',
      serverRequestParam: null,
      serverResponseParam: response => response?.panels?.resizable_panel,
      routeUrlParam: null,
      getStoreValue: (store) => store.getters[moduleName + '/resizablePanel'],
      setStoreValue: (store, value) => store.commit(moduleName + '/setResizablePanel', value)
    },
    {
      name: 'resizablePanelWidth',
      serverRequestParam: null,
      serverResponseParam: response => response?.panels?.width,
      routeUrlParam: null,
      getStoreValue: (store) => store.getters[moduleName + '/resizablePanelWidth'],
      setStoreValue: (store, value) => store.commit(moduleName + '/setResizablePanelWidth', value)
    },
    {
      name: 'leftPanelMinWidth',
      serverRequestParam: null,
      serverResponseParam: response => response?.panels?.min_width,
      routeUrlParam: null,
      getStoreValue: (store) => store.getters[moduleName + '/leftPanelMinWidth'],
      setStoreValue: (store, value) => store.commit(moduleName + '/setLeftPanelMinWidth', value)
    },
    {
      name: 'rightPanelMinWidth',
      serverRequestParam: null,
      serverResponseParam: response => response?.panels?.min_right_width,
      routeUrlParam: null,
      getStoreValue: (store) => store.getters[moduleName + '/rightPanelMinWidth'],
      setStoreValue: (store, value) => store.commit(moduleName + '/setRightPanelMinWidth', value)
    },
  ]
}

const getParamsMapAutorefresh = (): Array<IStateParam> => {
  return [
    {
      name: 'refreshPeriod',
      serverRequestParam: null,
      serverResponseParam: response => response?.refresh_period,
      routeUrlParam: null,
      getStoreValue: (store) => store.getters['refreshPeriod'],
      setStoreValue: (store, value) => store.commit('setRefreshPeriod', value)
    },
    {
      name: 'cache_md5',
      serverRequestParam: value => value ? {cache_md5: value} : null,
      serverResponseParam: response => response?.cache_md5,
      routeUrlParam: null,
      getStoreValue: (store) => store.getters['cache_md5'],
      setStoreValue: (store, value) => store.commit('setCacheMd5', value)
    },
  ]
}

const getParamsMapCurrentProject = (): Array<IStateParam> => {
  return [
    {
      name: 'project',
      serverRequestParam: value => value ? {project: value} : null,
      serverResponseParam: response => response?.project,
      routeUrlParam: null,
      getStoreValue: (store) => store.getters['project']?.id,
      setStoreValue: (store, value) => store.commit('updateProject', {project: value})
    },
  ]
}

const getParamsMapDebug = (): Array<IStateParam> => {
  const isDebugMode = !!inject('isDebugMode')
  console.log('useParams getParamsMapDebug isDebugMode', isDebugMode)
  if (!isDebugMode) {
    return []
  }

  return [
    {
      name: 'serverParam',
      serverRequestParam: value => value ? {serverParam: value} : null,
      serverResponseParam: response => response?.serverParam,
      propValue: (props) => props['serverParam'],
      routeUrlParam: (params, value) => {
        const type = 'query'
        const name = 'serverParam'
        const result = {
          merged: false,
          deleted: false,
          type,
          name,
          value,
          params,
        }
        if (value) {
          _merge(params, {[type]: {[name]: value}})
          result.merged = true
        } else {
          deleteRouteUrlParam(params, type, name)
          result.deleted = true
        }
        return result
      },
      getStoreValue: (store) => store.getters['dev/serverParam'],
      setStoreValue: (store, value) => store.commit('dev/setServerParam', value)
    },
  ]
}
