import {ActionTree, GetterTree, MutationTree} from "vuex"
import {RootState} from "@/store"

export interface ProjectInfoState {
  projectId: string
  createdBy: string
  createdOn: string
  ticketsPostingEmail: string
  ticketsPostingHelpLink: string
  showBigcommerceInvite: boolean
  bigcommerceConnectLink: string
  bigcommerceIsConnected: boolean
  bigcommerceSettingsLink: string
  isChanged: boolean
  readOnly: boolean
}

const state: ProjectInfoState = {
  projectId: '',
  createdBy: '',
  createdOn: '',
  ticketsPostingEmail: '',
  ticketsPostingHelpLink: '',
  showBigcommerceInvite: false,
  bigcommerceConnectLink: '',
  bigcommerceIsConnected: false,
  bigcommerceSettingsLink: '',
  isChanged: false,
  readOnly: false,
}

const getters: GetterTree<ProjectInfoState, RootState> = {
  projectId: state => state.projectId,
  createdBy: state => state.createdBy,
  createdOn: state => state.createdOn,
  ticketsPostingEmail: state => state.ticketsPostingEmail,
  ticketsPostingHelpLink: state => state.ticketsPostingHelpLink,
  showBigcommerceInvite: state => state.showBigcommerceInvite,
  bigcommerceConnectLink: state => state.bigcommerceConnectLink,
  bigcommerceIsConnected: state => state.bigcommerceIsConnected,
  bigcommerceSettingsLink: state => state.bigcommerceSettingsLink,
  isChanged: state => state.isChanged,
  readOnly: state => state.readOnly,
}

const mutations: MutationTree<ProjectInfoState> = {
  setProjectId(state, id: string) {
    state.projectId = id
  },
  setCreatedBy(state, value: string) {
    state.createdBy = value
  },
  setCreatedOn(state, value: string) {
    state.createdOn = value
  },
  setTicketsPostingEmail(state, value: string) {
    state.ticketsPostingEmail = value
  },
  setTicketsPostingHelpLink(state, value: string) {
    state.ticketsPostingHelpLink = value
  },
  setShowBigcommerceInvite(state, value) {
    state.showBigcommerceInvite = !!value
  },
  setBigcommerceConnectLink(state, value: string) {
    state.bigcommerceConnectLink = value || ''
  },
  setBigcommerceIsConnected(state, value) {
    state.bigcommerceIsConnected = !!value
  },
  setBigcommerceSettingsLink(state, value: string) {
    state.bigcommerceSettingsLink = value || ''
  },
  setIsChanged(state, value: boolean) {
    state.isChanged = value
  },
  setReadOnly(state, value: boolean) {
    state.readOnly = value
  },
}

const actions: ActionTree<ProjectInfoState, RootState> = {

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules : {
  }
}
