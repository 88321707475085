const TOKEN_KEY = 'access_token'
const REFRESH_TOKEN_KEY = 'refresh_token'

const TokenService = {

    getToken():string|null {
        return localStorage.getItem(TOKEN_KEY)
    },

    saveToken(accessToken:string): void {
        localStorage.setItem(TOKEN_KEY, accessToken)
    },

    removeToken():void {
        localStorage.removeItem(TOKEN_KEY)
    },

    getRefreshToken():string|null {
        return localStorage.getItem(REFRESH_TOKEN_KEY)
    },

    saveRefreshToken(refreshToken:string):void {
        localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
    },

    removeRefreshToken():void {
        localStorage.removeItem(REFRESH_TOKEN_KEY)
    }

}

export { TokenService }