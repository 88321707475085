import {getCurrentInstance} from "vue"
import {useEventListener} from "@vueuse/core"

const listenersOrder = new Map()

const getLastUid = () => [...listenersOrder.keys()].pop()

const keyHandler  = async (event) => {
  if (event.key === 'Escape') {
    //await closeLastPopupFromOrder('closeByEsc')
    const params = listenersOrder.get(getLastUid())
    console.log('useEscapeButtonOrder keyHandler', event.key, listenersOrder, params)
    params?.callback()
  }

}

useEventListener(document, 'keydown', keyHandler)

export function useEscapeButtonOrder () {

  const currentInstance = getCurrentInstance()

  const addEscapeButtonListener = (callback) => {
    if (currentInstance?.uid) {
      listenersOrder.set(currentInstance.uid, {
        instance: currentInstance,
        callback,
      })
    }
  }

  const removeEscapeButtonListener = () => {
    if (currentInstance?.uid) {
      listenersOrder.delete(currentInstance.uid)
    }
  }

  return {
    addEscapeButtonListener,
    removeEscapeButtonListener,
  }

}
