import {useStore} from "vuex"
import {useBrowserStorage} from "@/composables/useBrowserStorage"

export interface IThemesAttrs {
  mode: string
  light: string
  dark: string
}

export interface ITheme {
  id: string
  name?: string
  def?: 1
}

export function useColorScheme() {

  const store = useStore()

  const {
    setBrowserStorageItem,
    removeBrowserStorageItem,
  } = useBrowserStorage()

  const storageType = 'localStorage'

  const getLoginThemeStorageKey = (): string => window['disarea'].getLoginThemeStorageKey()

  const initLoginTheme = () => window['disarea'].initLoginTheme()

  const getDefaultThemeAttrs = () => window['disarea'].getDefaultThemeAttrs()

  const getCurrentThemeAttrs = () => window['disarea'].getCurrentThemeAttrs()

  const setThemeAttrs = (attrs: Partial<IThemesAttrs>) => window['disarea'].setThemeAttrs(attrs)

  const setLoginThemeStorageValue = (attrs: IThemesAttrs) => {
    const defaultAttrs = getDefaultThemeAttrs()
    const isDefaultValues = defaultAttrs.mode === attrs.mode && defaultAttrs.light === attrs.light && defaultAttrs.dark === attrs.dark
    if (isDefaultValues) {
      removeBrowserStorageItem(getLoginThemeStorageKey(), storageType, false, false)
    } else {
      setBrowserStorageItem(getLoginThemeStorageKey(), JSON.stringify(attrs), storageType, false, false)
    }
  }

  const initAppTheme = () => {
    const settings = store.getters['settings/getAll']
    const themeAttrs = {
      mode: settings.theme_mode,
      light: settings.theme_light,
      dark: settings.theme_dark,
    }
    setThemeAttrs(themeAttrs)
    setLoginThemeStorageValue(themeAttrs)
  }

  return {
    initAppTheme,
    initLoginTheme,
    getCurrentThemeAttrs,
    setThemeAttrs,
    setLoginThemeStorageValue,
  }


}
