import {getKeyboardFocusableElements} from "@/utils/appUtils"

const keyEventHandler = (event) => {
  if (event.key === 'Enter') {
    const tabbableElements = getKeyboardFocusableElements()
    const targetIndex = tabbableElements.indexOf(event.target)
    let nextIndex
    if (event.shiftKey) {
      nextIndex = targetIndex === 0 ? tabbableElements.length - 1 : targetIndex - 1
    } else {
      nextIndex = targetIndex < tabbableElements.length - 1 ? targetIndex + 1 : 0
    }
    console.log('TabOnEnter keyEventHandler', targetIndex, nextIndex, event.shiftKey, event)
    tabbableElements[nextIndex].focus()
  }
}

const TabOnEnter = {
  mounted(el, binding) {
    console.log('TabOnEnter mounted', el, binding, TabOnEnter)
    el.addEventListener('keypress', keyEventHandler)
  },
  unmounted(el, binding) {
    console.log('TabOnEnter unmounted', el, binding, TabOnEnter)
    el.removeEventListener('keypress', keyEventHandler)
  },
}

export default TabOnEnter
