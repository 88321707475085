import {getDomElement} from "@/utils/appUtils"
import {onBeforeUnmount, onMounted} from "vue"
import {useTippy} from "vue-tippy"
import {useResizeObserver} from "@/composables/useResizeObserver"

const CSS_CLASS_NAME = 'invalid'
const DEFAULT_TIPPY_PROPS = {
  theme: 'field-error',
  hideOnClick: false,
  trigger: 'manual',
  // interactive: true,
  // flip: false,
  // flipOnUpdate: false,
  // inlinePositioning: true,
  onClickOutside(instance, event) {
    console.log('useFormErrors onClickOutside', instance, event)
  },
  placement: 'top-start',
  //appendTo: 'parent',
  popperOptions: {
    modifiers: [
      {
        name: 'flip',
        enabled: false,
      },
      {
        name: 'arrow',
        options: {
          padding: {
            //top: 20,
            left: 14,
            right: 14000,
            //bottom: 0,
          }
        },
      },
    ]
  }
}

interface IFieldErrorOptions {
  fieldName: string
  withoutHint?: boolean
  isErrorGetter?: () => boolean
  elementRefGetter: () => any
  errorMessageGetter: () => string
  tippyProps?: Record<string, any>
  onShowFieldError?: () => void
  onHideFieldError?: () => void
}

const forms = {}
const tippyManagers = {}


export function useFormErrors(formName: string, options?) {

  if (!forms[formName]) {
    forms[formName] = {}
  }

  const unregisterFormFields = () => {
    destroyFormErrors()
    forms[formName] = {}
  }

  const registerField = (fieldOptions: IFieldErrorOptions) => {
    forms[formName][fieldOptions.fieldName] = fieldOptions
  }

  const getFieldOptions = (fieldName) => {
    return forms[formName][fieldName]
  }

  const checkFormErrors = () => {
    Object.keys(forms[formName]).forEach((fieldName) => {
      hideFieldError(fieldName)
      checkFieldError(fieldName)
    })
  }

  const hideFormErrors = () => {
    Object.keys(forms[formName]).forEach((fieldName) => {
      hideFieldError(fieldName)
    })
  }

  const hideFormErrorsHints = () => {
    Object.keys(forms[formName]).forEach((fieldName) => {
      hideErrorHint(fieldName)
    })
    errorHintMaybeVisible = false
  }
  const destroyFormErrors = () => {
    hideFormErrors()
    destroyFormTippyManagers()
  }


  const checkFieldError = (fieldName: string) => {
    console.log('useFormErrors checkFieldError', formName, fieldName, getFieldOptions(fieldName), forms)
    const fieldOptions = getFieldOptions(fieldName)
    const errorMessage = fieldOptions.errorMessageGetter()
    const isError = typeof fieldOptions.isErrorGetter === 'function' ? fieldOptions.isErrorGetter() : !!errorMessage
    isError ? showFieldError(fieldName, errorMessage) : hideFieldError(fieldName)
  }

  const showFieldError = (fieldName: string, errorMessage: string) => {
    showErrorBorder(fieldName)
    if (!getFieldOptions(fieldName).withoutHint) {
      showErrorHint(fieldName, errorMessage)
    }
    addEventListeners(fieldName)
    callFieldHook(fieldName, 'onShowFieldError')
  }

  const hideFieldError = (fieldName: string) => {
    hideErrorBorder(fieldName)
    hideErrorHint(fieldName)
    removeEventListeners(fieldName)
    callFieldHook(fieldName, 'onHideFieldError')
  }

  const callFieldHook = (fieldName: string, hookName: string) => {
    const fieldOptions = getFieldOptions(fieldName)
    if (typeof fieldOptions[hookName] === 'function') {
      fieldOptions[hookName]()
    }
  }

  const addEventListeners = (fieldName: string) => {
    const elRef = getFieldElementRef(fieldName)
    const elDom = getDomElement(elRef)
    if (elDom) {
      elDom.setAttribute('data-error-fieldname', fieldName)
      elDom.addEventListener('click', hideFieldErrorListener, {once: true})
      elDom.addEventListener('focus', hideFieldErrorListener, {once: true})
      elDom.addEventListener('input', hideFieldErrorListener, {once: true})
      elDom.addEventListener('keydown', hideFieldErrorListener, {once: true})
      elDom.querySelectorAll('input').forEach(element => {
        element.addEventListener('focus', hideFieldErrorListener, {once: true})
      })
    }
  }

  const removeEventListeners = (fieldName: string) => {
    const elRef = getFieldElementRef(fieldName)
    const elDom = getDomElement(elRef)
    if (elDom) {
      elDom.removeEventListener('click', hideFieldErrorListener, {once: true})
      elDom.removeEventListener('focus', hideFieldErrorListener, {once: true})
      elDom.removeEventListener('input', hideFieldErrorListener, {once: true})
      elDom.removeEventListener('keydown', hideFieldErrorListener, {once: true})
      elDom.querySelectorAll('input').forEach(element => {
        element.removeEventListener('focus', hideFieldErrorListener, {once: true})
      })
      elDom.removeAttribute('data-error-fieldname')
    }
  }

  const hideFieldErrorListener = (event) => {
    const elDom = event.target.closest('[data-error-fieldname]')
    const fieldName = elDom?.getAttribute('data-error-fieldname')
    if (fieldName) {
      hideFieldError(fieldName)
      if (options && typeof options.onRemoveError === 'function') {
        options.onRemoveError(fieldName)
      }
      removeEventListeners(fieldName)
    }
  }

  const showErrorBorder = (fieldName: string) => {
    toggleFieldInvalidCssClass(fieldName, true)
  }

  const hideErrorBorder = (fieldName: string) => {
    toggleFieldInvalidCssClass(fieldName, false)
  }

  const getFieldElementRef = (fieldName: string) => getFieldOptions(fieldName)?.elementRefGetter()

  const toggleFieldInvalidCssClass = (fieldName: string, value: boolean) => {
    const elRef = getFieldElementRef(fieldName)
    const classListObj = getDomElement(elRef)?.classList
    if (classListObj) {
      classListObj[value ? 'add' : 'remove'](CSS_CLASS_NAME)
    }
  }

  const getFieldTippyManager = (fieldName: string) => {
    if (!tippyManagers[formName]) {
      tippyManagers[formName] = {}
    }

    if (!tippyManagers[formName][fieldName]) {
      const tippyProps = Object.assign({}, DEFAULT_TIPPY_PROPS, getFieldOptions(fieldName)?.tippyProps)
      tippyManagers[formName][fieldName] = useTippy(getFieldElementRef(fieldName), tippyProps)
    }
    return tippyManagers[formName][fieldName]
  }

  let errorHintMaybeVisible = false
  const showErrorHint = (fieldName: string, errorMessage: string) => {
    errorHintMaybeVisible = true
    const tippyManager = getFieldTippyManager(fieldName)
    tippyManager.setContent(errorMessage)
    tippyManager.show()
  }

  const hideErrorHint = (fieldName: string) => {
    const tippyManager = getFieldTippyManager(fieldName)
    tippyManager?.hide()
  }

  const destroyFormTippyManagers = () => {
    if (tippyManagers[formName]) {
      Object.keys(tippyManagers[formName]).forEach((fieldName) => {
        destroyFieldTippyManager(fieldName)
      })
    }
  }

  const destroyFieldTippyManager = (fieldName: string) => {
    let tippyManager = getFieldTippyManager(fieldName)
    tippyManager.destroy()
    tippyManager = null
    delete tippyManagers[formName][fieldName]
  }

  if (Object.prototype.hasOwnProperty.call(options, 'refResizeObserverTarget')) {
    let resizeObserverItemUid
    const {
      addResizeObserverItem,
      removeResizeObserverItem,
    } = useResizeObserver()

    const resizeObserverHandler = () => {
      if (errorHintMaybeVisible) {
        hideFormErrorsHints()
      }
    }

    onMounted(() => {
      resizeObserverItemUid = addResizeObserverItem(options.refResizeObserverTarget?.value, resizeObserverHandler)
    })

    onBeforeUnmount(() => {
      removeResizeObserverItem(resizeObserverItemUid)
      destroyFormErrors()
    })
  }


  // let resizeObserver: any = null
  //
  // if ('ResizeObserver' in self) {
  //   resizeObserver = new window['ResizeObserver']((entries) => {
  //     for (const entry of entries) {
  //       console.log('useFormErrors ResizeObserver entry', entry, entry.target === options.refResizeObserverTarget?.value)
  //       if (entry.target === options.refResizeObserverTarget?.value) {
  //         hideFormErrorsHints()
  //       }
  //     }
  //
  //   });
  //
  //   onMounted(() => {
  //     if (resizeObserver && options.refResizeObserverTarget?.value) {
  //       resizeObserver.observe(options.refResizeObserverTarget.value)
  //     }
  //   })
  //
  //   onBeforeUnmount(() => {
  //     if (resizeObserver && options.refResizeObserverTarget?.value) {
  //       resizeObserver.unobserve(options.refResizeObserverTarget.value)
  //     }
  //     destroyFormErrors()
  //   })
  //
  // }

  return {
    unregisterFormFields,
    registerField,
    checkFormErrors,
    destroyFormErrors,
    showFieldError,
    hideFieldError,
    toggleFieldInvalidCssClass,
    showErrorHint,
    hideErrorHint,
    hideFormErrorsHints,
  }
}
