import {ActionTree, GetterTree, MutationTree} from "vuex"
import {RootState} from "@/store"
import {IData, ITicketTask} from "@/types"

export interface AccountState {
  accountInfo: IData | null
  billingInfo: IData | null
  invoiceInfo: IData | null
}

const state: AccountState = {
  accountInfo: null,
  billingInfo: null,
  invoiceInfo: null,
}

const getters: GetterTree<AccountState, RootState> = {
  accountInfo: state => state.accountInfo,
  billingInfo: state => state.billingInfo,
  invoiceInfo: state => state.invoiceInfo,
}

const mutations: MutationTree<AccountState> = {
  setAccountInfo(state, value: IData) {
    state.accountInfo = value  || null
  },
  setBillingInfo(state, value: IData) {
    state.billingInfo = value || null
  },
  setInvoiceInfo(state, value: IData) {
    state.invoiceInfo = value || null
  },
}

const actions: ActionTree<AccountState, RootState> = {
  initMyAccount({commit, getters}, data: IData) {
    commit('setAccountInfo', data.accountInfo)
    commit('setBillingInfo', data.billingInfo)
    commit('setInvoiceInfo', data.invoiceInfo)

  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
  }
}
