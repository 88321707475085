import {ActionTree, GetterTree, MutationTree} from "vuex"
import {RootState} from "@/store"
import datatable from "@/store/modules/datatable"
import notes from "@/store/modules/notes"
import tickets from "@/store/modules/tickets"
import ticketTasks from "@/store/modules/tickettasks"
import panels from "@/store/modules/panels"

// export interface DashboardState {
// }
//
// const state: DashboardState = {
// }
//
//const getters: GetterTree<DashboardState, RootState> = {
const getters = {
  isChanged: (state, getters) => getters.isNoteFormsChanged || getters.isTicketTasksChanged,
}
//
// const mutations: MutationTree<DashboardState> = {
// }
//
// const actions: ActionTree<DashboardState, RootState> = {
//
// }

export default {
  namespaced: true,
  // state,
  // actions,
  // mutations,
  getters,
  modules : {
    datatable,
    notes,
    tickets,
    ticketTasks,
    panels,
  }
}
