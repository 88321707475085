import {ActionTree, GetterTree, MutationTree} from "vuex"
import {RootState} from "@/store"
import {ITicketTag, ISearchField} from "@/types"

export interface TicketsFiltersState {
  search: string
  searchIn: string[]
  searchInDefault: string[]
  searchFields: ISearchField[]
  requestedById: string
  assignedToId: string
  tagsFilterValues: ITicketTag[]
  tagsLogic: string,
}

//const defaultSearchIn = ['title', 'description']
//const defaultSearchIn = ['title', 'description', 'id']

const state = (): TicketsFiltersState => ({
  search: '',
  searchIn: [],
  searchInDefault: [],
  searchFields: [],
  requestedById: '',
  assignedToId: '',
  tagsFilterValues: [],
  tagsLogic: '0',
})

const getters: GetterTree<TicketsFiltersState, RootState> = {
  search: state => state.search,
  searchIn: state => state.searchIn,
  searchInDefault: state => state.searchInDefault,
  searchFields: state => state.searchFields,
  requestedById: state => state.requestedById,
  assignedToId: state => state.assignedToId,
  tagsFilterValues: state => state.tagsFilterValues,
  tagsLogic: state => state.tagsLogic,
}

const mutations: MutationTree<TicketsFiltersState> = {
  set_search(state, search: string) {
    state.search = search
  },
  setSearchInDefault(state, value: string[]) {
    state.searchInDefault = Array.isArray(value) ? [...value] : []
  },
  setSearchIn(state, value: string[]) {
    state.searchIn = Array.isArray(value) ? [...value] : []
  },
  set_searchFields(state, fields: ISearchField[]) {
    state.searchFields = Array.isArray(fields) ? fields : []
  },
  set_requestedById(state, requestedById: string) {
    state.requestedById = requestedById
  },
  set_assignedToId(state, assignedToId: string) {
    state.assignedToId = assignedToId
  },
  set_tagsFilterValues(state, tags) {
    state.tagsFilterValues = tags;
  },
  setTagsLogic(state, tagsLogic: '0' | '1') {
    state.tagsLogic = tagsLogic
  },
}

const actions: ActionTree<TicketsFiltersState, RootState> = {
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
