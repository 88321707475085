import {ActionTree, GetterTree, MutationTree} from "vuex"
import {RootState} from "@/store"
import {INotification, ITicketTask} from "@/types"

export interface TicketTasksState {
  ticketTasks: ITicketTask[]
  canEditTasks: boolean
  hideClosedTasks: boolean
  isTicketTasksChanged: boolean
  foldedTasksArea: boolean
}

const state: TicketTasksState = {
  ticketTasks: [],
  canEditTasks: false,
  hideClosedTasks: false,
  isTicketTasksChanged: false,
  foldedTasksArea: false
}

const getters: GetterTree<TicketTasksState, RootState> = {
  ticketTasks: state => state.ticketTasks,
  canEditTasks: state => state.canEditTasks,
  hideClosedTasks: state => state.hideClosedTasks,
  isTicketTasksChanged: state => state.isTicketTasksChanged,
  foldedTasksArea: state => state.foldedTasksArea,
}

const mutations: MutationTree<TicketTasksState> = {
  setTicketTasks(state, value: ITicketTask[]) {
    state.ticketTasks = Array.isArray(value) ? value : []
  },
  setCanEditTasks(state, value: boolean) {
    state.canEditTasks = value
  },
  setHideClosedTasks(state, value: boolean) {
    state.hideClosedTasks = value
  },
  updateTask(state, item: Partial<ITicketTask>) {
    let task = state.ticketTasks.find((task: ITicketTask) => task.id === item.id)
    if (task) {
      task = Object.assign(task, item)
    }
  },
  setTicketTasksChanged(state, value: boolean) {
    state.isTicketTasksChanged = value
  },
  setFoldedTasksArea(state, value: boolean) {
    state.foldedTasksArea = value
  },
}

const actions: ActionTree<TicketTasksState, RootState> = {
  removeTask({commit, getters}, id: number) {
    const newTasks: ITicketTask[] = [].concat(getters.ticketTasks).filter((item: ITicketTask) => item.id !== id)
    commit('setTicketTasks', newTasks);
  },

  addTask({commit, getters}, item: ITicketTask) {
    const newTasks: ITicketTask[] = [].concat(getters.ticketTasks)
    newTasks.push(item)
    newTasks.sort((a, b) => {
      if (parseFloat(a.position) > parseFloat(b.position)) {
        return 1;
      }
      if (parseFloat(a.position) < parseFloat(b.position)) {
        return -1;
      }
      return 0;
    })
    commit('setTicketTasks', newTasks)
  },

  moveTaskAfterTask({commit, getters}, data: {movingTask: ITicketTask, prevTask: ITicketTask | null}) {
    const { prevTask } = data
    const movingTask = Object.assign({}, data.movingTask)
    const newTasks: ITicketTask[] = [].concat(getters.ticketTasks)
      .filter((item: ITicketTask) => item.id !== movingTask.id)
      .map(item => Object.assign({}, item))
    const prevTaskIndex = prevTask ? newTasks.findIndex(item => item.id === prevTask?.id) : -1
    const newIndex = prevTaskIndex + 1

    newTasks.splice(newIndex, 0, movingTask)

    newTasks.forEach((item, index) => {
      item.position = (index + 1).toString()
    })

    commit('setTicketTasks', newTasks)

    console.log('moveTaskAfterTask', newIndex, newTasks)
/*
    getters.ticketTasks.forEach((item, index) => {
      if (item.id === movingTask.id) {
        return
      }
      const itemCopy = Object.assign({}, item)
      if (!prevTask) {
        if (index === 0) {
          newTasks.push(movingTask)
          newTasks.push(itemCopy)

        }
      } else {
        if (item.id === prevTask.id) {

        }
      }
    })
*/
  }

}

export default {
  state,
  actions,
  mutations,
  getters,
}
