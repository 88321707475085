import {ref, nextTick, watch} from "vue"

export function useFloatingExists (refFloatingComponent) {

  const isExist = ref(false)

  watch(() => refFloatingComponent.value?.isHiding, async (newValue, oldValue) => {
    if (!newValue && oldValue !== undefined) {
      await nextTick()
      isExist.value = false
    }
  })


  const triggerHandler = async () => {
    console.log('useFloatingExists triggerHandler', refFloatingComponent.value, refFloatingComponent.value?.isOpened)
    if (refFloatingComponent.value) {
      refFloatingComponent.value.isOpened = !refFloatingComponent.value.isOpened
      return
    } else {
      isExist.value = true
    }

    await nextTick()
    if (refFloatingComponent.value) {
      refFloatingComponent.value.isOpened = true
    }
  }


  return {
    isExist,
    triggerHandler,
  }
}
