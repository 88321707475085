import {getCurrentInstance, isRef} from "vue"
import {useEventListener} from "@vueuse/core"

const listenersOrder = new Map()

const getLastUid = () => [...listenersOrder.keys()].pop()

const clickHandler = async (event) => {

  // const id = closeOnClickOutsideInstancesOrder[closeOnClickOutsideInstancesOrder.length - 1]
  // const popupObj = id && shownPopupsInstances.get(id)
  const params = listenersOrder.get(getLastUid())
  console.log('useClickOutsideOrder document clickHandler', event.target, listenersOrder, params)
  if (params && event.button === 0) {
    let isInsideTarget = false
    // AVB: тест глобального исключения клика по vConfirm
    params.targets = [...params.targets, ...document.querySelectorAll('.floating-modal-overlay.dialog.v-confirm')]

    params.targets.forEach((element) => {
      if (isRef(element)) {
        element = element.value
      }
      isInsideTarget = isInsideTarget || (element && element.contains && element.contains(event.target))
    })

    if (!isInsideTarget && typeof params.callback === 'function') {
      params.callback()
    }
  }

  // console.log('useClickOutsideOrder document clickHandler params', params)
  // return
  // const {props, emit, refFloatingContainer} = params
  // console.log('useFloatingContainer document clickHandler 2', event.target, props.isOpened, props.triggerTargets, refFloatingContainer.value.contains(event.target))
  // if (event.button === 0 && props.isOpened && !refFloatingContainer.value.contains(event.target)) {
  //   let isTriggerTarget = false
  //   props.triggerTargets.forEach((element) => {
  //     isTriggerTarget = isTriggerTarget || element.contains(event.target)
  //   })
  //   console.log('useFloatingContainer document clickHandler 3', isTriggerTarget, props.triggerTargets)
  //   !isTriggerTarget && emit('clickOutside', event)
  // }

  // if (event.button === 0) {
  //   //await closeLastPopupFromOrder('closeOnClickOutside')
  //   const params = shownPopupsInstances.get(getLastUid())
  //   console.log('useFloatingContainer keyHandler', event.key, shownPopupsInstances, params)
  //   //params?.emit('closeOnClickOutside')
  // }
}

useEventListener(document, 'mousedown', clickHandler)


export function useClickOutsideOrder () {

  const currentInstance = getCurrentInstance()

  const addClickOutsideListener = (callback, targets: any[]) => {
    if (currentInstance?.uid) {
      listenersOrder.set(currentInstance.uid, {
        instance: currentInstance,
        callback,
        targets,
      })
    }
  }

  const removeClickOutsideListener = () => {
    if (currentInstance?.uid) {
      listenersOrder.delete(currentInstance.uid)
    }
  }

  return {
    addClickOutsideListener,
    removeClickOutsideListener,
  }

}
