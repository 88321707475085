import {ActionTree, GetterTree, MutationTree} from "vuex"
import {RootState} from "@/store"
import {ITableField} from "@/types"

export interface DatatableState {
  //items: IData[]
  //requestedItemId: string | null
  selectedItemId: string
  //selectedItem: IData | null
  fields: ITableField[]
  itemsCount: number
  order: string | null
  reverse: boolean | null
  pageCurrent: number
  pageLast: number
}

const state = (): DatatableState => ({
  //items: [],
  //requestedItemId: '',
  selectedItemId: '',
  //selectedItem: null,
  fields: [],
  itemsCount: 0,
  order: null,
  reverse: null,
  pageCurrent: 1,
  pageLast: 1,
})

const getters: GetterTree<DatatableState, RootState> = {
  selectedItemId: state => state.selectedItemId,
  //selectedItem: state => state.selectedItem,
  fields: state => state.fields,
  itemsCount: state => state.itemsCount,
  visibleFields: state => state.fields.filter(item => item.visible == "1"),
  order: state => state.order,
  reverse: state => state.reverse,
  pageCurrent: state => state.pageCurrent,
  pageLast: state => state.pageLast,
}

const mutations: MutationTree<DatatableState> = {
  set_selectedItemId(state, id: string | undefined | null) {
    state.selectedItemId = typeof id === "string" ? id.toString().trim() : ''
  },
  // set_selectedItem(state, item: IData | null) {
  //   state.selectedItem = item
  // },
  set_fields(state, fields: ITableField[]) {
    state.fields = Array.isArray(fields) ? fields : []
  },
  set_itemsCount(state, count: number) {
    state.itemsCount = count
  },
  set_order(state, sortBy: string | null) {
    state.order = sortBy
  },
  set_reverse(state, sortDesc: boolean | null) {
    state.reverse = sortDesc
  },
  set_pageCurrent(state, page: number) {
    state.pageCurrent = page
  },
  set_pageLast(state, page: number) {
    state.pageLast = page
  },
  // setPageItemsCount(state, count) {
  //   state.pageItemsCount = count
  // },
}

const actions: ActionTree<DatatableState, RootState> = {
/*
  sortBy({ commit }, fieldName) {
    console.log('datatable actions sortBy', fieldName)
    commit('set_order', fieldName)
    commit('set_reverse', null)
    commit('set_pageCurrent', 1)
    //dispatch('fetchState')
  },

  sortDesc({ commit }, isDesc: boolean) {
    console.log('datatable actions isDesc', isDesc)
    commit('set_pageCurrent', 1)
    commit('set_reverse', isDesc)
    //dispatch('fetchState')
  },

  setSelected({ commit, getters, state }, id) {
    if (id === state.selectedItemId) {
      return
    }
    const item = getters['getItemById'](id)
    console.log('datatable actions setSelectedItem', item)
    commit('setSelectedItem', item)
    commit('setSelectedItemId', id)
    //dispatch('fetchState', { selectedId: id})
  },

  gotoPage({ commit, dispatch }, page) {
    dispatch('setSelected', '')
    commit('set_pageCurrent', page)
  }

*/
}

export default {
  //namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
