import {computePosition, offset, flip, shift, size, ComputePositionReturn} from "@floating-ui/dom"
import {isRef} from "vue"


const FLOATING_CONTAINER_PADDING = {
  top: 10,
  right: 15,
  bottom: 20,
  left: 10
}


/*
const FLOATING_CONTAINER_PADDING = {
  top: 30,
  right: 45,
  bottom: 60,
  left: 20
}
*/

export function usePositionPopup (props: any = {}) {

  const position = (target, popup, floatingProps, refFloatingSize, options) => {
    const placement = options.placement || floatingProps.placement || 'right-start'
    if (!Array.isArray(options.middleware)) {
      options.middleware = []
    }

    const middleware = [
      offset(floatingProps.offset),

      ... options.middleware,

      size({
        apply({availableWidth, availableHeight, elements}) {
          console.log('usePositionPopup position size', availableWidth, availableHeight, elements)
          const offset = floatingProps.offset || 0
          const minHeight = floatingProps.minHeight
          const maxHeight = floatingProps.maxHeight ? Math.min(availableHeight - offset, floatingProps.maxHeight) : availableHeight - offset

          availableWidth = availableWidth - offset
          //availableHeight = Math.min(availableHeight - offset, floatingProps.maxHeight)

          availableHeight = Math.max(maxHeight, minHeight)

          // Object.assign(elements.floating.style, {
          //   maxWidth: `${availableWidth}px`,
          //   maxHeight: `${availableHeight}px`,
          // });
          //refFloatingSize.value = {maxWidth: `${availableWidth}px`, maxHeight: `${availableHeight}px`}
          refFloatingSize.value = {availableWidth, availableHeight, minHeight, height: floatingProps.height}
        },
      }),
    ]

    console.log('usePositionPopup middleware', placement, middleware, target)

    if (floatingProps.height) {
      popup.style.height = floatingProps.height + 'px'
    }

    computePosition(target, popup, {
      placement,
      middleware,
    }).then((positionData: ComputePositionReturn) => {
      const {x, y} = adjustPopupPosition(positionData)
      console.log('usePositionPopup computePosition', positionData, x, y)
      Object.assign(
        popup.style,
        {
          left: `${x}px`,
          top: `${y}px`,
          maxWidth: `${refFloatingSize.value.availableWidth}px`,
          maxHeight: `${refFloatingSize.value.availableHeight}px`,
          minHeight: `${refFloatingSize.value.minHeight}px`,
        }
      )
    })

  }

  const positionRightStartFlipShift = (target, popup, floatingProps: any = {}, refFloatingSize) => {

    if (typeof props.position === "function") {
      props.position(target, popup, floatingProps, refFloatingSize)
      return
    }

    if (isRef(target)) {
      target = target.value
    }

    console.log('positionSeeker', target, popup, floatingProps, refFloatingSize.value)

    const placement = 'right-start'
    const middleware = [

      flip({
        crossAxis: false
      }),
      shift({
        mainAxis: true,
        crossAxis: true,
        padding: 5,
      }),

    ]

    const options = {
      placement,
      middleware,
    }

    position(target, popup, floatingProps, refFloatingSize, options)

  }

  const adjustPopupPosition = (positionData: ComputePositionReturn) => {
    console.log('usePositionPopup adjustPopupPosition', positionData)
    const [mainAxisPlacement, crossAxisPlacement] = positionData.placement.split('-')

    const adjustedPosition = {
      x: positionData.x,
      y: positionData.y
    }

    switch (mainAxisPlacement) {
      case 'top':
        adjustedPosition.y += FLOATING_CONTAINER_PADDING.bottom
        if (crossAxisPlacement === 'end') {
          adjustedPosition.x += FLOATING_CONTAINER_PADDING.right
        } else {
          adjustedPosition.x -= FLOATING_CONTAINER_PADDING.left
        }
        break
      case 'bottom':
        adjustedPosition.y -= FLOATING_CONTAINER_PADDING.top
        if (crossAxisPlacement === 'end') {
          adjustedPosition.x += FLOATING_CONTAINER_PADDING.right
        } else {
          adjustedPosition.x -= FLOATING_CONTAINER_PADDING.left
        }
        break
      case 'left':
        adjustedPosition.x += FLOATING_CONTAINER_PADDING.right
        if (crossAxisPlacement === 'start') {
          adjustedPosition.y -= FLOATING_CONTAINER_PADDING.top + FLOATING_CONTAINER_PADDING.bottom
        } else {
          adjustedPosition.y += FLOATING_CONTAINER_PADDING.bottom
        }
        break
      case 'right':
        adjustedPosition.x -= FLOATING_CONTAINER_PADDING.left
        if (crossAxisPlacement === 'start') {
          adjustedPosition.y -= FLOATING_CONTAINER_PADDING.top + FLOATING_CONTAINER_PADDING.bottom
        } else {
          adjustedPosition.y += FLOATING_CONTAINER_PADDING.bottom
        }
        break
    }

    if (adjustedPosition.y < 0) {
      adjustedPosition.y = 0
    }

    if (adjustedPosition.x < 0) {
      adjustedPosition.x = 0
    }

    return adjustedPosition
  }

  return {
    adjustPopupPosition,
    positionRightStartFlipShift,
  }

}
