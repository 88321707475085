import ApiService from "@/services/api_service"
import {IApiResponse, IData, ITableField} from "@/types"
import store from "@/store"
import qs from "qs"

const GlobalService = {
  get_main: function (extraParams?): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = {
        action: 'main',
      }
      if (extraParams) {
        params = Object.assign(params, extraParams)
      }
      ApiService._axios({
        url: '/action.php',
        method: "post",
        params: params
      }).then(response => {
        if (response.data.isError) {
          reject(response.data.message);
        } else if (!response?.data?.xdata) {
          reject('no response');
        } else {
          //const settings = response?.data?.xdata.model.settings;
          // settings.bw_user_field = parseInt(settings.bw_user_field);
          //settings.bv_show_star = parseInt(settings.bv_show_star);
          // settings.tags_align = 'left';
          resolve(response.data.xdata)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },

  fetchWhatIsNew: function (): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        action: "get_whatisnew"
      };
      const cancelTokenId = "get_whatisnew"
      ApiService.request({method: 'post', data: qs.stringify(params)}, cancelTokenId).then((response) => {
        resolve(response.xdata);
      }).catch((error) => {
        reject(error);
      })
    })
  },

  fetchNotificationCenter: function (options?): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        action: "notification_center"
      }
      if (options) {
        Object.assign(params, options);
      }
      const cancelTokenId = ''
      ApiService.request({method: 'post', data: qs.stringify(params)}, cancelTokenId).then((response) => {
        resolve(response.xdata);
      }).catch((error) => {
        reject(error);
      })
    })
  },

  fetchSettingsData: function (): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        action: "edit_settings"
      };
      const cancelTokenId = "edit_settings"
      ApiService.request({method: 'post', data: qs.stringify(params)}, cancelTokenId).then((response) => {
        resolve(response.xdata);
      }).catch((error) => {
        reject(error);
      })
    })
  },

  fetchAccessLogList: function (logType): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        action: "access_control_log",
      };
      if (logType === 'blocked') {
        params['only_blocked'] = '1'
      }

      const cancelTokenId = "access_control_log"
      ApiService.request({method: 'post', data: qs.stringify(params)}, cancelTokenId).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      })
    })
  },

  saveSettingsData: function (data, cancelTokenId): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        action: 'save_settings',
        ...data
      }
      ApiService.request({
        method: "post", data: qs.stringify(params)
      }, cancelTokenId).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error)
      })
    })
  },

  generateOAuthKey(cancelTokenId) {
    return new Promise((resolve, reject) => {
      const params = {
        'action': 'generate_oauth_key',
      };

      ApiService.request({
        method: 'post',
        data: qs.stringify(params)
      }).then((response) => resolve(response.xdata)).catch(error => resolve(error));
    })
  },

  getScreenData(screenName: string, requestedParams?: IData, cancelTokenId?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const actionMap = {
        ticket: 'edit_ticket',
        dashboard: 'get_dashboard_grid',
        listview: 'get_list_grid',
        people: 'manage_users',
        board: 'get_boardview_grid',
        report_general: 'get_report_general',
        project_info: 'manage_project',
        project_team: 'manage_project',
        workflow_designer: 'manage_project',
        ticket_designer: 'manage_project',
        automation: 'manage_project',
      }
      const params: IData = {
        action: actionMap[screenName],
        ...requestedParams
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        resolve(data)
      }).catch((data) => {
        reject({e: data, cancelTokenId})
      })

    })
  },

  /*
    getTagsForSelectorLastRequestTime: 0,

    getTagsForSelector(): Promise<any> {
      return new Promise((resolve, reject) => {
        const tagsFromStore = store.getters['tagsForSelector']
        if (Date.now() - this.getTagsForSelectorLastRequestTime < 2 * 60 * 1000) {
          resolve(tagsFromStore)
        } else {
          const actionName = 'get_all_tags'
          const params: IData = {
            action: actionName,
          }
          ApiService.request({
            method: "get",
            params
          }).then((data) => {
            console.log('GlobalService getTagsForSelector success', data)
            this.getTagsForSelectorLastRequestTime = Date.now()
            const tags = data?.xdata?.tags
            store.commit('set_tagsForSelector', tags || [])
            resolve(tags || tagsFromStore)
          }).catch((data) => {
            console.log('GlobalService getTagsForSelector error', data)
            resolve(tagsFromStore)
          })
        }

      })
    },
  */

  getFields(screenName: string, cancelTokenId?: string): Promise<ITableField[]> {
    const objTypeMap = {
      dashboard: 'dash_list_view',
      listview: 'list_view',
      report_general: 'general_report_view',
    }
    return new Promise((resolve, reject) => {
      const actionName = 'get_fields'
      const obj_type = objTypeMap[screenName] || screenName

      const params: IData = {
        action: actionName,
        obj_type,
        project: store.getters.project?.id,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('GlobalService setFields success', data)
        resolve(data.xdata.ret)
      }).catch((data) => {
        console.log('GlobalService setFields error', data)
        reject(data)
      })
    })
  },

  setFields(screenName: string, newFields: ITableField[], extraParams: IData = {}, cancelTokenId?: string): Promise<ITableField[]> {
    const objTypeMap = {
      dashboard: 'dash_list_view',
      listview: 'list_view',
      report_general: 'general_report_view',
    }
    return new Promise((resolve, reject) => {
      const actionName = 'set_fields'
      const obj_type = objTypeMap[screenName] || screenName
      const fields: IData = {}
      const orders: IData = {}

      const params: IData = {
        action: actionName,
        obj_type,
        project: store.getters.project?.id,
        fields,
        orders,
        ...extraParams
      }

      newFields.forEach((field: ITableField) => {
        fields[field.fieldname] = {visible: field.visible}
        orders[field.orders] = field.fieldname
      })

      console.log('GlobalService setFields', params, store.getters.project)

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('GlobalService setFields success', data)
        resolve(newFields)
      }).catch((data) => {
        console.log('GlobalService setFields error', data)
        reject(data)
      })
    })
  },

  setField(screenName: string, newField: ITableField, extraParams: IData = {}, cancelTokenId?: string): Promise<ITableField> {
    const objTypeMap = {
      dashboard: 'dash_list_view',
      listview: 'list_view',
      report_general: 'general_report_view',
    }
    return new Promise((resolve, reject) => {
      const actionName = 'set_field'
      const obj_type = objTypeMap[screenName]
      const fields: IData = {}
      const orders: IData = {}

      const params: IData = {
        action: actionName,
        obj_type,
        project: store.getters.project?.id,
        name: newField.fieldname,
        width: newField.width,
        ...extraParams
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('GlobalService setField success', data)
        resolve(newField)
      }).catch((data) => {
        console.log('GlobalService setField error', data)
        reject(data)
      })
    })
  },

  setSetting(settingName: string, settingValue: any, cancelTokenId?: string, options = {}): Promise<any> {

    return new Promise((resolve, reject) => {
      const actionName = 'set'

      const params: IData = {
        action: actionName,
        project: store.getters.project?.id,
        [settingName]: settingValue
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId, options).then((data) => {
        console.log('GlobalService setSetting success', data)
        resolve(data)
      }).catch((data) => {
        console.log('GlobalService setSetting error', data)
        reject(data)
      })
    })
  },

  restoreEvent(id: string, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'event_restore'

      const params: IData = {
        action: actionName,
        id,
      }

      if (!cancelTokenId) {
        cancelTokenId = actionName + '_' + id
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('GlobalService restoreEvent success', data)
        resolve(data)
      }).catch((data) => {
        console.log('GlobalService restoreEvent error', data)
        reject(data)
      })
    })

  },

  saveFeedback: function (email, content, cancelTokenId?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        action: 'save_feedback',
        email,
        content,
      }
      ApiService.request({
        method: "post", data: qs.stringify(params)
      }, cancelTokenId).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error)
      })
    })
  },

  saveSmileFeedback: function (smile, content, cancelTokenId?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        action: 'save_feedbackX',
        smile,
        content,
      }
      ApiService.request({
        method: "post", data: qs.stringify(params)
      }, cancelTokenId).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error)
      })
    })
  },

}

export default GlobalService
