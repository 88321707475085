import {ref, watch} from "vue";
import {ISettings} from "@/types";
import global_service from "@/services/global_service";
import {useI18n} from "vue-i18n";
import _cloneDeep from "lodash/cloneDeep"
import {useStore} from "vuex"
import {useAppUtils} from "@/composables/useAppUtils"
import {ITheme} from "@/composables/useColorScheme"


const globalData = ref<ISettings>({})
const previousData = ref<ISettings>({})
const accessData = ref<Partial<ISettings>>({})
const previousAccessData = ref<Partial<ISettings>>({})

//const isLoading = ref(false);
const languages = ref([]);   // ---
const countryList = ref([]);
const tzList = ref([]);
const tfList = ref([]);  // ---
const dfList = ref([]);  // ---
const weekStartList = ref({"6":'Sunday', "0":'Monday'}); // ---
const refreshPeriodList = ref([]);
const hideOldObjectPeriodList = ref([]);
const accessKey = ref([]);
const ticketsPostEmail = ref("");
const deadlineHoursList = ref(['12 am', '1 am', '2 am', '3 am', '4 am', '5 am', '6 am', '7 am', '8 am', '9 am', '10 am', '11 am',
  '12 pm', '1 pm', '2 pm', '3 pm', '4 pm', '5 pm', '6 pm', '7 pm', '8 pm', '9 pm', '10 pm', '11 pm']);
const accessLogsList = ref("");
const accessLogType = ref("all");
const visitorIsAccountAdministrator = ref();
const canPostEmailTickets = ref();
const allProviders = ref([]);
const teamUrl = ref("");
const slackInfo = ref();
const checkIpAccessAdmin = ref();
const yourIPAddress = ref("");
const projectsList = ref([])
const sampleTicket = ref({})
const lightThemesList = ref<ITheme[]>([])
const darkThemesList = ref<ITheme[]>([])

//const main2 = ref("");

export const useSettingsData = ()=>{
  const localData = ref<ISettings>({});
  const store = useStore()

  const {
    isSuccessServerResponse,
    showResponseSuccessMessage,
  } = useAppUtils()

  const setAccessData = data => {
    accessData.value = {
      access_type: data.access_type,
      allowed_cntrs: [...(data.allowed_cntrs || [])],
      allowed_ips: [...(data.allowed_ips || [])],
    }
    previousAccessData.value = {..._cloneDeep(accessData.value)}
  }

  const setData = function (data) {
    data.apply2all_main = "0";
    data.apply2all_view = "0";
    data.ver = data.site_ver
    data.setting_form = "1";

    setAccessData(data)
    delete data.access_type
    delete data.allowed_cntrs
    delete data.allowed_ips

    previousData.value = {..._cloneDeep(data)}
    globalData.value = {..._cloneDeep(data)}
  };

  const reset = function () {
    globalData.value = JSON.parse(JSON.stringify(previousData.value));
  };

  const fetchData = function () {
    //isLoading.value = true;
    return global_service.fetchSettingsData().then((response)=>{
      console.log('settings response - ', response);
      setData(response.settings);
      languages.value = response.site_lang_list;
      countryList.value = response.country_list;
      tzList.value = response.tz_list;
      tfList.value = response.time_format_list;
      dfList.value = response.date_format_list;
      // weekStartList.value = response.week_start_list;
      refreshPeriodList.value = response.refresh_period_list;
      hideOldObjectPeriodList.value = response.hide_old_object_period_list;
      accessKey.value = response.accessKey;
      ticketsPostEmail.value = response.ticketsPostEmail;
      visitorIsAccountAdministrator.value = response.visitorIsAccountAdministrator;
      canPostEmailTickets.value = response.canPostEmailTickets;
      allProviders.value = response.all_providers;
      teamUrl.value = response.teamUrl;
      slackInfo.value = response.slackInfo;
      checkIpAccessAdmin.value = response.checkIpAccessAdmin;
      yourIPAddress.value = response.my_ip;
      //main2.value = response.form_view;
      projectsList.value = response.visitorActiveProjects
      sampleTicket.value = response.sampleTicket
      lightThemesList.value = response.theme_list?.light || []
      darkThemesList.value = response.theme_list?.dark || []

    })
    // .finally(()=>{
    //   isLoading.value = false;
    // })
    .catch(reason => {
      console.log('UseSettingsData fetchData error', reason)
    });
  };

  const fetchAccessLogList = function () {
    return global_service.fetchAccessLogList(accessLogType.value).then((response)=>{
      accessLogsList.value = response.html;
    });
  };

  const saveData = async (data) => {
    // const raw = JSON.parse(JSON.stringify(globalData.value));
    // return global_service.saveSettingsData(raw, 'save_settings_cancel');

    //return global_service.saveSettingsData(data, 'save_settings_cancel').finally(() => { store.commit('settings/setSettings', data); })

    try {
      const response = await global_service.saveSettingsData(data, 'save_settings_cancel')
      store.commit('settings/setSettings', data)
      return response
    } catch (e) {
      console.log('useSettingsData saveData error', e)
      return e
    }

  }

  const resetSettingsAccessData = () => {
    setAccessData(previousAccessData.value)
    // Object.assign(accessData.value, {
    //   access_type: previousAccessData.value.access_type,
    //   allowed_cntrs: [...(previousAccessData.value.allowed_cntrs || [])],
    //   allowed_ips: [...(previousAccessData.value.allowed_ips || [])],
    // })
  }

  const saveSettingsAccessData = async () => {

    const dataForServer = {
      access_type: accessData.value.access_type,
      country: accessData.value.access_type === 'none' ? [] : [...(accessData.value.allowed_cntrs || [])],
      ips: accessData.value.access_type === 'none' ? [] : [...(accessData.value.allowed_ips || [])],
    }

    const response = await global_service.saveSettingsData(dataForServer, 'save_access_settings_cancel')

    if (isSuccessServerResponse(response)) {
      //setAccessData(response.xdata.settings)
      const dataForClient = {
        access_type: dataForServer.access_type,
        allowed_cntrs: dataForServer.country,
        allowed_ips: dataForServer.ips,
      }
      setAccessData(dataForClient)
      //store.commit('settings/setSettings', {..._cloneDeep(accessData.value)})
    }

    return response
  }

  return {
    globalData,
    accessData,
    accessKey,
    localData,
    previousData,
    languages,
    countryList,
    tzList,
    tfList,
    dfList,
    weekStartList,
    refreshPeriodList,
    hideOldObjectPeriodList,
    ticketsPostEmail,
    deadlineHoursList,
    // isLoading,
    accessLogsList,
    accessLogType,
    visitorIsAccountAdministrator,
    canPostEmailTickets,
    allProviders,
    teamUrl,
    slackInfo,
    checkIpAccessAdmin,
    yourIPAddress,
    //main2,
    projectsList,
    sampleTicket,
    lightThemesList,
    darkThemesList,
    //setData,
    fetchData,
    reset,
    fetchAccessLogList,
    saveData,
    resetSettingsAccessData,
    saveSettingsAccessData,
  }
}
