import {isRef} from "vue"

// el - DOM element | Element ref | Component ref
export function getDomElement(el) {
  let element = el

  if (isRef(el)) {
    element = el.value
  }

  if (!element) {
    return
  }

  if (element['__isVue']) {
    element = element['$el']
  }
  return element
}

export function getKeyboardFocusableElements (
  element: HTMLElement | HTMLDocument | null | undefined = document,
  options: {omitServiceElements: true | false} = {omitServiceElements: false}) {
  // https://github.com/makeup-jquery/jquery-focusable/blob/master/jquery.focusable.js
  // https://zellwk.com/blog/keyboard-focusable-elements/

  const focusableElementsList = [
    'a[href]',
    'button:not([disabled])',
    'area[href]',
    'input:not([disabled])',
    'select:not([disabled])',
    'textarea:not([disabled])',
    'iframe',
    'object',
    'embed',
    '*[tabindex]:not([tabindex="-1"]',
    '*[contenteditable]'
  ]

  const focusableElementsSelector = focusableElementsList.join()

  let result: any[] = []
  if (element) {
    result = [...element.querySelectorAll(focusableElementsSelector)]
      .filter(el => el.getAttribute('tabindex') !== '-1')

    if (options.omitServiceElements) {
      result = result.filter(el => !el.hasAttribute('data-circle-tabulation')) // elements from v-circle-tabulation directive
    }
  }

  return result
}
