import {ActionTree, GetterTree, MutationTree} from "vuex"
import {RootState} from "@/store"
import {IData, INote} from "@/types"

export interface NotesState {
  notes: IData[]
  addNoteDisabled: boolean
  notesOffset: number
  notesTimeOffset: number | string | null
  isMoreNotes: boolean
  isShowLogs: '0' | '1' | null
  noteSearch: string
  editNoteId: string
  replyNoteId: string
  replyPositionNoteId: string
  newNoteObj: IData | null
  editNoteObj: IData | null
  replyNoteObj: IData | null
  isTopFormChanged: boolean
  isBottomFormChanged: boolean
  isTopFormUploading: boolean
  isBottomFormUploading: boolean
}

const state = (): NotesState => ({
  notes: [],
  addNoteDisabled: false,
  notesOffset: 0,
  notesTimeOffset: null,
  isMoreNotes: false,
  isShowLogs: null,
  noteSearch: '',
  editNoteId: '',
  replyNoteId: '',
  replyPositionNoteId: '',
  newNoteObj: null,
  editNoteObj: null,
  replyNoteObj: null,
  isTopFormChanged: false,
  isBottomFormChanged: false,
  isTopFormUploading: false,
  isBottomFormUploading: false,
})

const getters: GetterTree<NotesState, RootState> = {
  notes: state => state.notes,
  notesOffset: state => state.notesOffset,
  notesTimeOffset: state => state.notesTimeOffset,
  // getNoteById: (state) => (id: string) => {
  //   return state.notes.find(item => item.key === id)
  // },
  addNoteDisabled: state => state.addNoteDisabled,
  isMoreNotes: state => state.isMoreNotes,
  isShowLogs: state => state.isShowLogs,
  noteSearch: state => state.noteSearch,
  editNoteId: state => state.editNoteId,
  replyNoteId: state => state.replyNoteId,
  replyPositionNoteId: state => state.replyPositionNoteId,
  newNoteObj: state => state.newNoteObj,
  editNoteObj: state => state.editNoteObj,
  replyNoteObj: state => state.replyNoteObj,
  isTopFormChanged: state => state.isTopFormChanged,
  isBottomFormChanged: state => state.isBottomFormChanged,
  isNoteFormsChanged: state => (state.isTopFormChanged || state.isBottomFormChanged),
  isNoteFormsUploading: state => (state.isTopFormUploading || state.isBottomFormUploading),
  getReplyPositionId: (state) => (replyNoteId: string, ticketId: string) => {
    let result = ''
    state.notes.find((obj: IData) => {
      const objKey: string = '' + obj.key
      console.log('Notes store getReplyPositionId objKey', objKey, ticketId, objKey === ticketId, objKey === ticketId && Array.isArray(obj.items))
      if (objKey === ticketId && Array.isArray(obj.items)) {
        const replyNoteIndex = obj.items.findIndex(item => item.id === replyNoteId)
        if (replyNoteIndex === -1) {
          return false
        }
        let replyNoteObj = obj.items[replyNoteIndex]
        const replyNoteDepth = obj.items[replyNoteIndex].depth

        const noteAfterReplyForm = obj.items.slice(replyNoteIndex + 1).find(item => {
          console.log('Notes store getReplyPositionId find noteAfterReplyForm', item, item.type, item.depth, item.depth <= replyNoteDepth)
          if (item.type !== 'note') {
            return false
          }
          if (item.depth <= replyNoteDepth) {
            return true
          } else {
            replyNoteObj = item
          }
        })
        console.log('Notes store getReplyPositionId ticketObj', obj, replyNoteIndex, replyNoteDepth, replyNoteObj.id, replyNoteObj.text, replyNoteObj)

        result = replyNoteObj.id
        return true

        // if (noteAfterReplyForm) {
        //   result = noteAfterReplyForm.id
        //   return true
        // }

        // let i = 0
        // do {
        //   i++
        //   //replyNoteObj = obj.items[replyNoteIndex + i]
        // } while (obj.items[replyNoteIndex + i] && obj.items[replyNoteIndex + i].depth > replyNoteDepth)
        // const noteObj = i === 1 ? obj.items[replyNoteIndex] : obj.items[replyNoteIndex + i - 1]
        // noteAfterWhichShouldBeReplyForm = noteObj
        // return true
      }
    })

    console.log('Notes store getReplyPositionId', replyNoteId, ticketId, result, state.notes)

    //return noteAfterWhichShouldBeReplyForm?.id || ''
    return result
  },
}

const mutations: MutationTree<NotesState> = {
  set_notes(state, notes: IData[] | null) {
    if (Array.isArray(notes)) {
      state.notes = notes
    }
  },
  set_notesOffset(state, value: number) {
    state.notesOffset = value
  },
  set_notesTimeOffset(state, value: number | string | null) {
    state.notesTimeOffset = value
  },
  appendNotes(state, notes: IData[]) {
    state.notes = [
      ...state.notes,
      ...notes,
    ]
  },
  set_noteObjExpanded(state, payload: {key: string, value: boolean}) {
    // const note = state.notes.find(item => item.key === payload.key)
    // if (note) {
    //   note.expanded = payload.value
    // }
    state.notes.forEach(item => {
      if (item.key === payload.key) {
        item.expanded = payload.value
      }
    })
  },
  set_addNoteDisabled(state, value: boolean) {
    state.addNoteDisabled = value
  },
  set_isMoreNotes(state, value: boolean) {
    state.isMoreNotes = value
  },
  set_isShowLogs(state, value) {
    state.isShowLogs = value
  },
  set_noteSearch(state, value: string) {
    state.noteSearch = value
  },
  setEditNoteId(state, value: string) {
    state.editNoteId = value
  },
  setReplyNoteId(state, value: string) {
    state.replyNoteId = value
  },
  setReplyPositionNoteId(state, value: string) {
    state.replyPositionNoteId = value
  },
  setNewNoteObj(state, value: IData | null) {
    console.log('notes setNewNoteObj', value)
    state.newNoteObj = value
  },
  setEditNoteObj(state, value: IData) {
    state.editNoteObj = value
  },
  setReplyNoteObj(state, value: IData) {
    state.replyNoteObj = value
  },
  removeNote(state, id) {
    state.notes.find(notesGroup => {
      console.log('store removeNote find', notesGroup, state.notes)
      if (Array.isArray(notesGroup.items)) {
        const note = notesGroup.items.findIndex(item => item.id === id)
        console.log('store removeNote findIndex', note)
        if (note === -1) {
          return false
        } else {
          notesGroup.items.splice(note, 1)
          return true
        }
      }
    })
  },
  setNoteFormChanged(state, payload: {value: boolean, type?: string}) { //value: boolean, type = 'top'
    const { value, type } = payload
    state[type === 'top' ? 'isTopFormChanged' : 'isBottomFormChanged'] = value
  },
  setNoteFormUploading(state, payload: {value: boolean, type?: string}) { //value: boolean, type = 'top'
    const { value, type } = payload
    state[type === 'top' ? 'isTopFormUploading' : 'isBottomFormUploading'] = value
  },
  setTicketCover(state, payload) {
    const { ticketId, attachmentId } = payload
    state.notes.forEach((notesGroup: any) => {
      if (notesGroup.key.toString() === ticketId.toString()){
        notesGroup.ticketCover = attachmentId.toString()
      }
    })
  },
  addNoteAtPosition(state, params: IData) {
    console.log('Notes addNoteAtPosition', state.notes, JSON.stringify(state.notes))
    const note: INote = params.note as INote
    const positionNote: string = params.positionNote as string
    const ticketId = note.ticket_id

    const targetNote = state.notes.find((obj: IData) => {
      const objKey: string = '' + obj.key
      console.log('Notes store getReplyPositionId objKey', objKey, ticketId, objKey === ticketId, objKey === ticketId && Array.isArray(obj.items))
      if (objKey === ticketId && Array.isArray(obj.items)) {
        if (positionNote === '0') { // new note added via top form
          obj.items = [note, ...obj.items]
        } else {
          const replyNoteIndex = obj.items.findIndex(item => item.id === positionNote)
          obj.items.splice(replyNoteIndex + 1, 0, note);
        }

        return true
      }
    })

    console.log('Notes addNoteAtPosition targetNote', targetNote, state.notes, JSON.stringify(state.notes))

    if (!targetNote) {
      state.notes.unshift({
        expanded: true,
        items: [{
          ...note
        }],
        key: ticketId
      })
    }
  },

  updateNoteByLocalId(state, params: IData) {
    const note: INote = params.note as INote
    const ticketId = note.ticket_id
    const noteLocalId = params.local_id
    state.notes.find((obj: IData) => {
      const objKey: string = '' + obj.key
      if (objKey === ticketId && Array.isArray(obj.items)) {
        const replyNote = obj.items.find(item => item.local_id === noteLocalId)
        if (replyNote) {
          Object.assign(replyNote, note)
        }
        return true
      }
    })
  },

  updateNote(state, note: INote) {
    const ticketId = note.ticket_id
    const noteId = note.id
    state.notes.find((obj: IData) => {
      const objKey: string = '' + obj.key
      if (objKey === ticketId && Array.isArray(obj.items)) {
        const replyNote = obj.items.find(item => item.id === noteId)
        if (replyNote) {
          Object.assign(replyNote, note)
        }
        return true
      }
    })
  },

  setNoteText(state, note: Partial<INote>) {
    const noteId = note.id
    state.notes.find((obj: IData) => {
      if (Array.isArray(obj.items)) {
        const storeNote = obj.items.find(item => item.id === noteId)
        console.log('setNoteText', storeNote, noteId, obj)
        if (storeNote) {
          storeNote.text = note.text
          delete storeNote.short_text
          return true
        }
      }
    })

  },

  // prependNoteRestoreLink(state, note: IData) {
  //   //const item = {...note}
  //   console.log('Notes prependNoteRestoreLink', note)
  //   state.notes.unshift({
  //     expanded: true,
  //     key: 'note_restore_link_' + Date.now(),
  //     items: [{
  //       ...note
  //     }]
  //   })
  // }

}

const actions: ActionTree<NotesState, RootState> = {
}

export default {
  state,
  actions,
  mutations,
  getters,
}
