import {ActionTree, GetterTree, MutationTree} from "vuex"
import {RootState} from "@/store"
import datatable from "@/store/modules/datatable"
import notes from "@/store/modules/notes"
import tickets from "@/store/modules/tickets"
import ticketTasks from "@/store/modules/tickettasks"
import panels from "@/store/modules/panels"
import {IData} from "@/types"

export interface ListviewState {
  projectId: string
  role: IData
}

const state: ListviewState = {
  projectId: '',
  role: {},
}

const getters: GetterTree<ListviewState, RootState> = {
  projectId: state => state.projectId,
  isChanged: (state, getters) => getters.isNoteFormsChanged || getters.isTicketTasksChanged,
  getRole: state=>state.role,
}

const mutations: MutationTree<ListviewState> = {
  set_projectId(state, id: string) {
    state.projectId = id
  },
  setRole(state, role = {}) {
    state.role = role
  },
}

const actions: ActionTree<ListviewState, RootState> = {
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules : {
    datatable,
    notes,
    tickets,
    ticketTasks,
    panels,
  }
}
