
import {computed, defineComponent, ref, watch} from "vue"
import { useStore } from 'vuex'
import {useColorScheme} from '@/composables/useColorScheme'

export default defineComponent({
  name: "ThemeSwitcher",
  setup() {

    const {
      getCurrentThemeAttrs,
      setThemeAttrs,
    } = useColorScheme()

    const { mode } = getCurrentThemeAttrs()
    const theme = ref<string>(mode)

    watch(theme, value => {
      setThemeAttrs({mode: value})
    })
    //watch(appTheme, value => theme.value = value)

/*
    const themeLocalStorageValue = localStorage.getItem('global.interface.theme')
    const theme = ref(themeLocalStorageValue || store.getters.theme)

    watch(theme, () => {
      store.commit('changeTheme', theme.value)
      localStorage.setItem('global.interface.theme', theme.value)
    }, {immediate: true})
*/

    return {
      theme,
    }

  }

})
