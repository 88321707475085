import {ActionTree, GetterTree, MutationTree} from "vuex"
import {RootState} from "@/store"
import {IBoardCustomField} from "@/types";
import BoardService from "@/services/board_service";
import router from "@/router";
import {SelectiveObjectReuse} from "selective-object-reuse";
import ticket_service from "@/services/ticket_service";

// export interface BoardState {
//   lastLoadTime?: number,
//   projectId?: number,
//   stepsIds: string[],
//   stepsByIds,
//   expandedStepIds: string[],
//
//   stepsTickets: {},
//   tickets,
//   expandedTickets: string[],
//   lastOpen?: string,
//   usersByIds
// }

const sor = new SelectiveObjectReuse();
// const sor = {wrap: (o, t) => o}

const state = () => ({
  isLoading: false,
  lastLoadTime: undefined,

  projectId: null,

  stepsIds: [],
  stepsByIds: {},
  expandedStepIds: [],

  stepsTickets: {},

  ticketsByIds: {},
  ticketsUsersIds: {},
  expandedTickets: [],

  ticketsSteps: {},

  usersByIds: {},

  fields: [],

  fieldsByIds: {},

  // searchStr: "",
  // searchIn: 'title,description',
  // searchFields: [],

  // requestedById: null,
  // assignedToId: null,

  archived_count: 0,
  archived_msg_key: 'stats_sticker',

  ticketsLastNote: {},

  role: {},
  templateType: null,
  templateContent: '',
  allTicketsCount: 0,
  ticketsPostingEmail: null,

  inProgress: [],

  animatedTickets: [],
  edited: [],
  lastDraggedTicketId: '',
})

const mutations: MutationTree<any> = {

  setProgress(state, {ticketId, value}) {
    const p = [...state.inProgress];
    const index = p.indexOf(ticketId);
    if (value) {
      if (index == -1) {
        p.splice(0,0, ticketId);
      }
    } else if (index != -1) {
      p.splice(0, 1, index);
    }
    state.inProgress = p;
  },

  set_projectId(state, projectId) {
    if (state.projectId != projectId) {
      state.lastLoadTime = undefined;
      state.lastOpen = undefined;
      state.projectId = projectId;
    }
  },

  setSteps(state, steps) {
    const ids: string[] = [];
    const stepsByIds = {};
    const expanded: string[] = [];
    steps.forEach((step) => {
      ids.push(step.id);
      if (step.expanded) {
        expanded.push(step.id);
        delete step.expanded;
      }
      stepsByIds[step.id] = Object.freeze(step);
    })
    state.stepsIds = ids;
    state.stepsByIds = stepsByIds;
    state.expandedStepIds = expanded;
  },

  setExpandStepById(state, {id, isExpanded}) {
    const index = state.expandedStepIds.indexOf(id)
    if (isExpanded && index === -1) {
      state.expandedStepIds.push(id)
    } else if (!isExpanded && index !== -1) {
      state.expandedStepIds.splice(index, 1)
    }
  },

  renameStep(state, {id, title}) {
    const item = state.stepsByIds[id]
    if (item) {
      state.stepsByIds[id] = Object.freeze({
        ...item,
        title
      })
    }
  },

  setTickets(state, {ticketsOrderIds, ticketsByIds}) {
    const _ticketsByIds = {};
    const _expandedTickets: string[] = [];
    const _animatedTickets: string[] = [];
    const _stepsTickets = {};
    const _usersByIds = {};
    const _ticketsUsersIds = {};
    const _ticketsSteps = {};

    state.stepsIds.forEach((s) => {
      if (ticketsOrderIds[s]) {
        _stepsTickets[s] = [...ticketsOrderIds[s]];

        _stepsTickets[s].forEach((item)=>{
          if (ticketsByIds[item]) {
            const tmp = {...ticketsByIds[item]};
            if (tmp.expanded) {
              _expandedTickets.push(item);
            }
            delete tmp.expanded;

            if (tmp.isAnimate) {
              _animatedTickets.push(item);
            }
            delete tmp.isAnimate

            tmp.tmpColor = null;
            tmp.isActiveMenu = false;
            if (!_ticketsUsersIds[tmp.id]) {
              _ticketsUsersIds[tmp.id] = [];
            }
            if (tmp.users && tmp.users.length) {
              tmp.users.forEach((user) => {
                _usersByIds[user.id] = Object.freeze({...user});
                _ticketsUsersIds[tmp.id].push(user.id);
              });
            }
            delete tmp.users;
            _ticketsSteps[tmp.id] = s;
            _ticketsByIds[tmp.id] = Object.freeze(tmp)
          }
        });
      } else {
        _stepsTickets[s] = [];
      }
    });

    state.stepsTickets = _stepsTickets;
    state.ticketsSteps = _ticketsSteps;
    state.usersByIds = _usersByIds;
    state.expandedTickets = _expandedTickets;
    state.animatedTickets = _animatedTickets;
    state.ticketsUsersIds = _ticketsUsersIds;
    state.ticketsByIds = _ticketsByIds;
    state.lastLoadTime = (new Date()).getTime();
  },

  // set_search(state, search) {
  //   state.searchStr = search ;
  // },

  // set_searchIn(state, value: string) {
  //   state.searchIn = value
  // },

  // set_searchFields(state, fields) {
  //   state.searchFields = Array.isArray(fields) ? fields : []
  // },
  //
  // set_requestedById(state, requestedById:string) {
  //   state.requestedById = requestedById;
  // },
  //
  // set_assignedToId(state, assignedToId:string) {
  //   state.assignedToId = assignedToId;
  // },


  addTicket(state, {item}) {
    const tmp = {...item};

    const _expanded = [...state.expandedTickets];
    const _expandedIndex = _expanded.indexOf(tmp.id);
    if (tmp.expanded ) {
      if (_expandedIndex == -1) {
        _expanded.push(item);
      }
    } else if (_expandedIndex != -1) {
        _expanded.splice(0,1);
    }
    delete tmp.expanded;

    const stepId = tmp.flow_step_id;
    const _ticketsSteps = {...state.ticketsSteps};
    _ticketsSteps[tmp.id] = stepId;
    delete tmp.flow_step_id;

    tmp.tmpColor = null;
    tmp.isActiveMenu = false;
    const _usersIds:any[] = [];
    if (tmp.users && tmp.users.length) {
      tmp.users.forEach((user)=> {
        if (!state.usersByIds[user.id]) {
          state.usersByIds[user.id] = Object.freeze({...user});
        } else {
          state.usersByIds[user.id] = Object.freeze(Object.assign({...state.usersByIds[user.id]}, {...user}));
        }
        _usersIds.push(user.id);
      });
      delete tmp.users;
    }
    state.ticketsUsersIds[tmp.id] = _usersIds;
    if (!state.ticketsByIds[tmp.id]) {
      state.ticketsByIds[tmp.id] = Object.freeze(tmp);
    }
    const t1 = [...state.stepsTickets[stepId]];
    t1.splice(0, 0, tmp.id);
    state.stepsTickets[stepId] = t1;
    state.ticketsSteps = _ticketsSteps;
  },

  setExpandTicketById(state, {id, isExpanded}) {
    // console.log('set ticket expand', id, isExpanded);
    const index = state.expandedTickets.indexOf(id)
    if (isExpanded && index === -1) {
      state.expandedTickets.push(id)
    } else if (!isExpanded && index !== -1) {
      state.expandedTickets.splice(index, 1)
    }
  },

  setStarTicketById(state, {id, star}) {
    const item = state.ticketsByIds[id]
    if (item) {
      state.ticketsByIds[id] = Object.freeze({
        ...item, star
      })
    }
  },

  setActiveTicketMenuById(state, {id, isActiveMenu}) {
    const item = state.ticketsByIds[id]
    if (item) {
      state.ticketsByIds[id] = Object.freeze({
        ...item, isActiveMenu
      })
    }
  },

  add_TicketIdToStep(state, {stepId, id, pos}) {
    // console.log('append ticket to step', stepId, id);
    const ticketsIds = [...state.stepsTickets[stepId]];
    const index = ticketsIds.indexOf(id);
    if (index==-1 || index !=-1 && index != pos) {
      if (index != -1) {
        ticketsIds.splice(index, 1);
      }
      ticketsIds.splice(pos | 0, 0, id);
      state.stepsTickets[stepId] = ticketsIds;
    }
  },

  remove_TicketIdFromStep(state, {stepId, id}) {
    // console.log('remove ticket from step', stepId, id);
    const ticketsIds = [...state.stepsTickets[stepId]];
    const index = ticketsIds.indexOf(id);
    if (index != -1) {
      ticketsIds.splice(index, 1);
      state.stepsTickets[stepId] = ticketsIds;
    }
  },

  moveTopTicketById(state, {id}) {
    const _stepId = state.ticketsSteps[id];
    if (_stepId) {
      const ticketsIds = [...state.stepsTickets[_stepId]];
      const index = ticketsIds.indexOf(id);
      ticketsIds.splice(index, 1);
      ticketsIds.splice(0, 0, id);
      state.stepsTickets[_stepId] = ticketsIds;
    }
  },

  moveBottomTicketById(state, {id}) {
    const _stepId = state.ticketsSteps[id];
    if (_stepId) {
      const ticketsIds = [...state.stepsTickets[_stepId]];
      const index = ticketsIds.indexOf(id);
      ticketsIds.splice(index, 1);
      ticketsIds.splice(ticketsIds.length, 0, id);
      state.stepsTickets[_stepId] = ticketsIds;
    }
  },

  moveNextStepTicketById(state, {id}) {
    const _stepId = state.ticketsSteps[id];
    if (_stepId) {
      const steps = [...state.stepsIds];
      const stepIndex = steps.indexOf(_stepId);
      const newStepIndex = Math.min(stepIndex + 1, steps.length - 1);
      const newStepId = steps[newStepIndex];
      const index = state.stepsTickets[_stepId].indexOf(id);
      const ts1 = [...state.stepsTickets[_stepId]];
      ts1.splice(index, 1);
      state.stepsTickets[_stepId] = ts1;
      const ts2 = [...state.stepsTickets[newStepId]];
      ts2.splice(0, 0, id)
      state.stepsTickets[newStepId] = ts2;
      state.ticketsSteps[id] = newStepId;
    }
  },

  moveToProjectTicketById(state, {id, projectId}) {
    const item = state.ticketsByIds[id]
    if (item) {
      state.ticketsByIds[id] = Object.freeze({
        ...item, flow_id:projectId
      })
    }
    if (projectId != state.projectId) {
      const stepId = item.flow_step_id;
      const index = state.stepsTickets[stepId].indexOf(id);
      state.stepsTickets[stepId].splice(index, 1);
    }
  },

  deleteTicketById(state, {id}) {
    const _stepId = state.ticketsSteps[id];
    if (_stepId) {
      const _tickets = [...state.stepsTickets[_stepId]];
      const index = _tickets.indexOf(id);
      _tickets.splice(index, 1);
      state.stepsTickets[_stepId] = _tickets;
      const _ticketsByIds = {...state.ticketsByIds};
      delete _ticketsByIds[id];
      state.ticketsByIds = _ticketsByIds;
      // delete state.ticketsSteps[id];
    }
  },

  setTicketTmpColorById(state, {id, tmpColor}) {
    const item = state.ticketsByIds[id]
    if (item) {
      state.ticketsByIds[id] = Object.freeze({
        ...item, tmpColor
      })
    }
  },

  setTicketColorById(state, {id, color}) {
    console.log('11111111111111111111111111   ', id, color)
    const item = state.ticketsByIds[id]
    if (item) {
      state.ticketsByIds[id] = Object.freeze({
        ...item, color
      })
    }
  },

  setTicketCoverById(state, {id, cover}) {
    const item = state.ticketsByIds[id]
    if (item) {
      state.ticketsByIds[id] = Object.freeze({
        ...item, cover
      })
    }
  },

  updateList(state, {id, tickets}) {
    // debugger
    // state.stepsTickets[id] = tickets;
    const _stepTickets:any[] = [];
    const _ticketsSteps = {...state.ticketsSteps};
    tickets.forEach((i) => {
      _ticketsSteps[i.id] = id;
      _stepTickets.push(i.id)
    });
    state.stepsTickets[id] = _stepTickets;
    state.ticketsSteps = _ticketsSteps;
  },

  addUsers(state, users) {
    const usersByIds ={...state.usersByIds};
    users.forEach((user)=>{
      usersByIds[user.id] = Object.freeze(user);
    })
    state.usersByIds = usersByIds;
  },

  update_ticketTeam(state, {id, team}) {
    state.ticketsUsersIds[id] = team;
  },

  updateUserById(state, {id, value}) {
    const _usersByIds = {...state.usersByIds};
    const _oldVal = _usersByIds[id];
    _usersByIds[id] = Object.freeze(Object.assign({..._oldVal}, {...value}));
    state.usersByIds = _usersByIds;
  },

  setCustomFields(state, {fields}) {
    const _fieldsIds:string[] = [];
    const _fields:IBoardCustomField[] = fields;
    _fields.forEach((item)=>{
      const fieldname = item.fieldname!;
      if (state.fieldsByIds[fieldname]) {
        Object.assign(state.fieldsByIds[fieldname], item);
      } else {
        state.fieldsByIds[fieldname] = item;
      }
      _fieldsIds.push(fieldname);
    });
    state.fields = _fieldsIds;
  },

  setLoading(state, value) {
    state.isLoading = value;
  },

  setArchivedCount(state, value) {
    state.archived_count = value;
  },

  setArchivedMsgKey(state, value) {
    state.archived_msg_key = value;
  },

  setLastTicketNote(state, {ticketId, note}) {
    if (state.ticketsLastNote[ticketId]) {
      if (note) {
        Object.assign(state.ticketsLastNote[ticketId], note);
      } else {
        delete state.ticketsLastNote[ticketId];
      }
    } else {
      if (note) {
        state.ticketsLastNote[ticketId] = note;
      }
    }
  },

  setRole(state, role = {}) {
    state.role = role
  },

  setEmptyTemplate(state, templateType) {
    state.templateType = templateType;
  },

  setEmptyContent(state, templateContent) {
    state.templateContent = templateContent
  },

  setAllTicketsCount(state, count) {
    state.allTicketsCount = (typeof count == 'string') ? parseInt(count) : count;
  },

  setTicketsPostingEmail(state, email) {
    state.ticketsPostingEmail = email;
  },

  setAddedNewTicketsIds(state, ids) {
    state.animatedTickets = ids;
  },

  setEditedTicketsIds(state, ids) {
    state.edited = ids;
  },

  updateTicket(state, {id, attrs}) {
    const ticket = state.ticketsByIds[id]
    if (ticket) {
      state.ticketsByIds[id] = Object.freeze({
        ...ticket,
        ...attrs
      })
    }
  },

  setLastDraggedTicketId(state, value) {
    state.lastDraggedTicketId = value || ''
  }

}

const actions: ActionTree<any, RootState> = {
/*
  fetchBoard({commit, state, dispatch}, projectId) {
    commit('setLoading', true);
    projectId = projectId || state.projectId;
    // dispatch('fetchFields', projectId).then(()=>{
      dispatch('fetchTickets', projectId).then(()=>{
        commit('setLoading', false);
      })
    // })
  },
*/

/*
  fetchTickets({dispatch, commit, state}, projectId) {
    projectId = projectId || state.projectId;
    return BoardService.get_tickets(projectId).then((response) => {
      if (response.cached != 1) {
      if (projectId != response.project.id) {
        return router.replace({name: 'Board', params: {projectId: response.project.id}}).then(()=>{
          dispatch('updateBoard', response);
        });
      }
      else {
        dispatch('updateBoard', response);
      }
    }
    });
  },
*/

  toggleStepExpanded({commit, state}, {stepId, isExpanded}) {
    commit('setExpandStepById', {id:stepId, isExpanded });
    BoardService.toggle_step_expanded(stepId, state.projectId, isExpanded).catch((error)=>{
      commit('setExpandStepById', {id:stepId, isExpanded:!isExpanded });
    });
  },

  removeTicket({commit}, id) {
      commit('deleteTicketById', {id:id});
  },

  updateBoard({commit}, response) {
    commit('setSteps', response.steps || []);

    if (response.cached != 1) {
      commit('setTickets', {
        'ticketsOrderIds': (response.ticket_list_by_step || []),
        'ticketsByIds': (response.ticket_list_by_id || [])
      });
    }
    // let fields: IBoardCustomField[] | null = null;
    // if (response.customFieldName) {
    //   fields = Object.values(response.customFieldName);
    //   fields = fields.map((f: IBoardCustomField) => {
    //     f.fieldname = f.name;
    //     delete f.name;
    //     f.visible = 1;
    //     return f;
    //   });
    // } else {
    //   fields = [];
    // }
    commit('setCustomFields', {fields: response.customFields || []});
    //commit('set_searchFields', response.search_fields);
    commit("setArchivedCount", parseInt(response.ticket_archived_cnt || 0, 10));
    commit("setArchivedMsgKey", response.ticket_archived_msg_key);
    commit("setRole", response.role);
    commit("setEmptyTemplate", response.template_type || null);
    commit("setEmptyContent", response.template_content || '');
    commit("setAllTicketsCount", response.cnt_ticket || response.ticket_cnt);
    commit("setTicketsPostingEmail", response.ticketsPostingEmail || null);
  },

  // fetchFields({commit, state}, projectId) {
  //   projectId = projectId || state.projectId;
  //   return BoardService.get_custom_fields(projectId).then((response) => {
  //       commit('setCustomFields', {
  //         'fields': response});
  //   })
  // },

  clearTickets({commit, state}) {
    // const newSteps:IBoardStep[] = [];
    commit("setTickets", {});
    //commit('setSteps', {steps: []});
    // for (const s in state.tickets) {
    //   state.tickets[s] = [];
    // }
    // state.steps.forEach((s)=>{
    //   const newS:IBoardStep = Object.assign({}, s);
    //   newS.children = [];
    //   newSteps.push(newS);
    // });
  },

  update_ticketTeam({commit, state}, {id, team}) {
    const _usersIds:any[] = [];
    team.forEach((user)=>{
      _usersIds.push(user.id);
      commit('updateUserById', {id:user.id, value:user});
    });
    commit('update_ticketTeam', {id, team:_usersIds});
  },

  set_search({commit, state}, str) {
    commit("set_search", str);
  },

  updateLastTicketNote({commit, state}, ticketId) {
    BoardService.get_last_note(ticketId)
    .then((response)=>{
      commit("setLastTicketNote", ticketId, response);
    })
    .catch(()=>{
      commit("setLastTicketNote", [ticketId, null]);
    })
  },

/*
  removeCover({commit, state}, {ticketId}) {
    console.log('action removeCover', ticketId);
    const cancelId = 'removeCoverCID'
    ticket_service.setCover(ticketId, '-1', cancelId).then(()=>{
      commit("setTicketCoverById", {id: ticketId, cover: null});
    })
  },
*/

  addedNewTicketEffect({commit, state}, {id}) {
    const ids = [...state.animatedTickets];
    ids.push(id);
    commit('setAddedNewTicketsIds', ids)
  },

  removeAnimatedTicket({commit, state}, {id}) {
    const ids = [...state.animatedTickets];
    const index = ids.indexOf(id);
    if (index != -1) {
      ids.splice(index, 1);
    }
    commit('setAddedNewTicketsIds', ids);
  },

  clearAnimatedTickets({commit}) {
    commit('setAddedNewTicketsIds', []);
  },

  editedTicketEffect({commit, state}, {id}) {
    const ids = [...state.animatedTickets];
    ids.push(id);
    commit('setEditedTicketsIds', ids)
  }
}

const getters: GetterTree<any, RootState> = {

  project: state => {
    return state.projectId
  },

  expandedStepIds(state) {
    return state.expandedStepIds.reduce((out, id) => {
      out[id] = true;
      return out
    }, {})
  },

  steps: (state) => state.stepsIds.map(id => ({
    id,
    item: state.stepsByIds[id],
    expanded: state.expandedStepIds.includes(id)
  })),

  stepByIds: (state, getters) => getters.steps.reduce((out, step) => {
    out[step.id] = step;
    return out;
  }, {}),

  getStepById: (state) => (id) => {
    return state.stepsByIds[id]
  },


  expandedIdsRev: state => state.expandedTickets.reduce((out, id) => {
    out[id] = true;
    return out;
  }, {}),

  getTicketById: (state, getters) => (id) => {
    return {...state.ticketsByIds[id]};
  },

  getTicketsByStep:(state, getters) => (stepId) => {
    const e = getters.expandedIdsRev;
    let out:any[] = [];
    const ip = state.inProgress;
    const aa = [...state.animatedTickets];
    if (state.stepsTickets[stepId]) {
      out = state.stepsTickets[stepId].map((id) => {
        if (state.ticketsByIds[id]) {
          const _users = state.ticketsUsersIds[id].map(userId=>({...state.usersByIds[userId]}));
          return {
            id,
            isAdded: !!aa.find((n)=>n==id),
            item: {...state.ticketsByIds[id]},
            isExpanded: !!(e[id]),
            isInProgress: !!(ip.indexOf(id) != -1),
            users: _users
          }
          return {id:id, item: id}
        }
      })
    }
    out = sor.wrap(out, 'ticketsByStep_'+stepId);
    return out;
  },

  getTicketsByStep2:(state, getters) => (stepId, limit = 500) => {
    console.log('getTicketsByStep2')
    const e = getters.expandedIdsRev;
    let out:any[] = [];
    const ip = state.inProgress;
    const aa = [...state.animatedTickets];
    if (state.stepsTickets[stepId]) {
      out = state.stepsTickets[stepId].slice(0, limit).map((id) => {
        if (state.ticketsByIds[id]) {
          const _users = state.ticketsUsersIds[id].map(userId=>({...state.usersByIds[userId]}));
          return {
            id,
            isAdded: !!aa.find((n)=>n==id),
            item: {...state.ticketsByIds[id]},
            isExpanded: !!(e[id]),
            isInProgress: !!(ip.indexOf(id) != -1),
            users: _users
          }
          return {id:id, item: id}
        }
      })
    }
    out = sor.wrap(out, 'ticketsByStep_'+stepId);
    return out;
  },

  getTicketsBySteps(state, getters) {
    const e = getters.expandedIdsRev;
    if (!globalThis.tmp_store) {
      globalThis.tmp_store = []
    }
    const out = state.stepsIds.reduce((out, id) => {
      if (state.stepsTickets[id]) {
        out[id] = state.stepsTickets[id].map(id => {
          return {
            id,
            item: state.ticketsByIds[id],
            isExpanded: !!(e[id]),
            isInProgress: (state.inProgress.indexOf(id) != -1),
            users: JSON.stringify(state.ticketsByIds[id].usersIds.map(userId=>({"id": userId, "item":state.usersByIds[userId]})))
          }
        });
      } else {
        out[id] = [];
      }
      return sor.wrap(out, "ticketsBySteps");
    }, {})
    return out;
  },

  usersByIds (state) {
    return Object.keys(state.usersByIds).reduce((out, item)=>{
      out[item] = state.usersByIds[item];
      return out;
    }, {});
  },

  getFields (state) {
    return state.fields.map((key)=>{
      return state.fieldsByIds[key];
    })
  },

  getVisibleFields (state) {
    let out = [];
     out = state.fields.reduce((out1, key)=>{
       if (state.fieldsByIds[key].visible == 1) {
        out1.push(state.fieldsByIds[key]);
      }
       return out1;
    }, []);
    return out;
  },

  getLastLoadTime: state => {
    return state.lastLoadTime;
  },

  isLoaded: state => {
    return state.lastLoadTime;
  },

  isLoading: state => {
    return state.isLoading;
  },

  isBigBoard: (state, getters) => getters.getTicketsCount > 1000,

  getTicketsCount: state => {
    return Object.keys(state.ticketsByIds).length;
  },

  getRealTicketsCountInStep: state=>{
    let out = {};
    out = state.stepsIds.reduce((out, key)=>{
      out[key] = state.stepsTickets[key] ? state.stepsTickets[key].length : 0;
      return out;
    }, {});
    return out;
  },

  // search: state => {return state.searchStr},
  //
  // searchIn: state => state.searchIn,
  //
  // searchFields: state => {
  //   return state.searchFields;
  // },
  //
  // requestedById: state => state.requestedById,
  //
  // assignedToId: state => state.assignedToId,

  getArchivedCount: state=>{return state.archived_count},

  getArchivedMsgKey: state => state.archived_msg_key,

  getLastTicketNote: state => (ticketId) => {
    return state.ticketsLastNote[ticketId];
  },


  getRole: state=>state.role,
  getTemplateType: state=>state.templateType,
  getTemplateContent: state=>state.templateContent,
  getAllTicketsCount: state => state.allTicketsCount,
  getTicketsPostingEmail: state => state.ticketsPostingEmail,
  lastDraggedTicketId: state => state.lastDraggedTicketId,

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
  }
}
