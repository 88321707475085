import {createI18n, I18nOptions} from "vue-i18n"
import ApiService from "@/services/api_service"

let i18n
export const useLocale = () =>{

  const createLocale = (locale, messages) => {
    const i18options: I18nOptions = {
      legacy: false,
      locale, // set locale
      messages, // set locale messages
      warnHtmlMessage: false, // https://vue-i18n.intlify.dev/api/composition.html#warnhtmlmessage
      missingWarn: false, // https://vue-i18n.intlify.dev/api/composition.html#missingwarn
    }

    if (i18options.locale) {
      const customRule = getCustomPluralizationRule(i18options.locale)
      if (customRule) {
        i18options.pluralRules = {
          [i18options.locale]: customRule
        }
      }
    }


    i18n = createI18n(i18options)

    if (i18options.messages && i18options.locale && i18options.messages[i18options.locale] && i18options.messages[i18options.locale]['An error ocurred, please try again']) {
      ApiService.setDefaultErrorMessage(i18options.messages[i18options.locale]['An error ocurred, please try again'] as string)
    }

    return i18n
  }

  const f = function (...args) {
    const { t } = i18n.global
    let i = 0;
    let str = t(args[0]).replace(/%s/ig, function () {
      i++;
      return '{' + i + '}';
    });

    args.forEach((k, index) => {
      if (index > 0) {
        str = str.replace('{' + index + '}', k);
      }
    })

    return str;
  }


  return {
    createLocale,
    f,
  }

}

const getCustomPluralizationRule = (locale) => {
  let ruleFn: ((choice: any, choicesLength: any, orgRule: any) => any) | null = null
  switch (locale) {
    case 'ru_RU':
    case 'uk_UK':
      ruleFn = (choice, choicesLength, orgRule) => {
        if (choice === 0) {
          return 0
        }

        const teen = choice > 10 && choice < 20
        const endsWithOne = choice % 10 === 1
        if (!teen && endsWithOne) {
          return 1
        }
        if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
          return 2
        }

        return choicesLength < 4 ? 2 : 3
      }
      break
  }
  return ruleFn
}
