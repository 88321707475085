import {ActionTree, GetterTree, MutationTree} from "vuex"
import {IData, IFieldErrors, IUser} from "@/types"
import {RootState} from "@/store"
import datatable from "@/store/modules/datatable"
import panels from "@/store/modules/panels"

export interface PeopleState {
  selectedUser: Partial<IUser> | null
  viewMode: 'view' | 'edit'
  users: Array<IUser>
  userCanEdit: boolean
  canRemoveUser: boolean
  canChangeAdm: boolean
  canChangeAccessRights: boolean
  search: string
  isUserFormChanged: boolean
}

const state: PeopleState = {
  selectedUser: null,
  viewMode: 'view',
  users: [],
  userCanEdit: false,
  canRemoveUser: false,
  canChangeAdm: false,
  canChangeAccessRights: false,
  search: '',
  isUserFormChanged: false,
}

const getters: GetterTree<PeopleState, RootState> = {
  selectedUser: state => state.selectedUser,
  viewMode: state => state.viewMode,
  users: state => state.users,
  // todo: getUserById with caching
  getUserById: (state) => (id: string) => state.users.find(user => user.id === id),
  //fieldErrors: state => state.fieldErrors,
  userCanEdit: state => state.userCanEdit,
  canRemoveUser: state => state.canRemoveUser,
  canChangeAdm: state => state.canChangeAdm,
  canChangeAccessRights: state => state.canChangeAccessRights,
  search: state => state.search,
  isChanged: (state, getters) => state.isUserFormChanged,
}

const mutations: MutationTree<PeopleState> = {
  set_selectedUser(state, user: IUser | null) {
    state.selectedUser = user
  },
  set_viewMode(state, mode) {
    state.viewMode = mode
  },
  set_users(state, users: IUser[]) {
    state.users = users
  },
  // setFieldErrors(state, errors: IFieldErrors) {
  //   state.fieldErrors = { ... errors }
  // }
  updateUser(state, {id, attrs}) {
    const obj = state.users.find(item => item.id === id)
    if (obj) {
      Object.assign(obj, attrs)
    }
  },

  setUserCanEdit(state, value) {
    state.userCanEdit = !!value
  },
  setCanRemoveUser(state, value) {
    state.canRemoveUser = !!value
  },
  setCanChangeAdm(state, value) {
    state.canChangeAdm = !!value
  },
  setCanChangeAccessRights(state, value) {
    state.canChangeAccessRights = !!value
  },
  set_search(state, search: string) {
    state.search = search
  },
  setUserFormChanged(state, value: boolean) {
    state.isUserFormChanged = value
  },
}

const actions: ActionTree<PeopleState, RootState> = {
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules : {
    datatable,
    panels,
  }

}
