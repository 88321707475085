import { vShow as _vShow, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createBlock(_component_draggable, {
    class: "dev-tools",
    list: [{name: 'the-dev-panel'}],
    "item-key": "name",
    forceFallback: true,
    handle: ".handle",
    onStart: _ctx.dragPanelStartHandler,
    onEnd: _ctx.dragPanelEndHandler
  }, {
    item: _withCtx(() => [
      _withDirectives(_createElementVNode("div", {
        style: _normalizeStyle(_ctx.styleObject),
        class: "dev hidden"
      }, null, 4), [
        [_vShow, _ctx.isVisible]
      ])
    ]),
    _: 1
  }, 8, ["onStart", "onEnd"]))
}