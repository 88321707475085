import {defineComponent, onBeforeUnmount, onMounted, ref, watch, nextTick} from "vue"
import Draggable from "vuedraggable"
import {IData} from "@/types"

export default defineComponent({
  name: 'TheDevPanel',

  components: {
    Draggable,
  },

  setup(props) { //props, context

    const localStoragePrefix = 'devPanel'
    const windowHeight = ref(window.innerHeight)
    const windowWidth = ref(window.innerWidth)
    const rootElement: HTMLElement | null = document.querySelector(':root')
    const rootStyle = rootElement?.style

    const cssVarsParams = [
      {
        varName: '--h',
        defValue: '0',
        units: '',
        modelName: 'cssVar__h',
        model: ref('')
      },
      {
        varName: '--h-accent',
        defValue: '0',
        units: '',
        modelName: 'cssVar__h_accent',
        model: ref('')
      },
      {
        varName: '--s',
        defValue: '0',
        units: '%',
        modelName: 'cssVar__s',
        model: ref('')
      },
      {
        varName: '--s-accent',
        defValue: '0',
        units: '%',
        modelName: 'cssVar__s_accent',
        model: ref('')
      },
      {
        varName: '--l',
        defValue: '0',
        units: '%',
        modelName: 'cssVar__l',
        model: ref('')
      },
      {
        varName: '--l-accent',
        defValue: '0',
        units: '%',
        modelName: 'cssVar__l_accent',
        model: ref('')
      },
    ]

    const adjustFontSize = (change) => {
      const html = document.documentElement;
      const fontSize = parseFloat(getComputedStyle(html).fontSize);
      const newFontSize = fontSize + change;

      // Ограничения на размер шрифта
      const minFontSize = 10;
      const maxFontSize = 22;

      if (newFontSize >= minFontSize && newFontSize <= maxFontSize) {
        html.style.fontSize = `${newFontSize}px`;
      }
    };


    const decreaseFontSize = () => {
      adjustFontSize(-1);
    };

    const increaseFontSize = () => {
      adjustFontSize(1);
    };

    const resetFontSize = () => {
      document.documentElement.style.fontSize = ''; // Устанавливаем размер шрифта по умолчанию
    };

    const updateWindowSize = () => {
      windowHeight.value = window.innerHeight
      windowWidth.value = window.innerWidth
    }

    onMounted(() => {
      window.addEventListener('resize', updateWindowSize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateWindowSize)
    })


    const setCssVar = (cssVarName, cssVarValue, units) => {
      rootStyle?.setProperty(cssVarName, cssVarValue + units)
      localStorage.setItem(`${localStoragePrefix}.${cssVarName}`, cssVarValue)
    }

    const initCssVars = () => {
      cssVarsParams.forEach(varItem => {
        const storageValue = localStorage.getItem(`${localStoragePrefix}.${varItem.varName}`)
        varItem.model.value = storageValue || varItem.defValue
        if (storageValue) {
          setCssVar(varItem.varName, varItem.model.value, varItem.units)
        }
        watch(varItem.model, () => {
          setCssVar(varItem.varName, varItem.model.value, varItem.units)
        })
      })
    }

    const resetCssVarsModelsToDefaultValues = () => {
      cssVarsParams.forEach(varItem => {
        varItem.model.value = varItem.defValue
      })
    }

    const resetCssVars = () => {
      resetCssVarsModelsToDefaultValues()

      nextTick(() => {
        cssVarsParams.forEach(varItem => {
          localStorage.removeItem(`${localStoragePrefix}.${varItem.varName}`)
          rootStyle?.removeProperty(varItem.varName)
        })
      })
    }

    const cssVarsModelsForTemplate = () => {
      const result = {}
      cssVarsParams.forEach(varItem => {
        result[varItem.modelName] = varItem.model
      })
      return result
    }

    initCssVars()

    const isVisible = ref(true)
    const styleObject = ref<IData>({})
    let panelPosition
    const mousePosition = {
      x: 0,
      y: 0
    }
    const dragPanelStartHandler = (dragEvent) => {
      isVisible.value = false
      panelPosition = dragEvent.item.getBoundingClientRect()
      mousePosition.x = dragEvent.originalEvent.x
      mousePosition.y = dragEvent.originalEvent.y
    }

    const dragPanelEndHandler = (dragEvent) => {
      const left = panelPosition.x + dragEvent.originalEvent.x - mousePosition.x
      const top = panelPosition.y + dragEvent.originalEvent.y - mousePosition.y
      isVisible.value = true

      styleObject.value = {
        bottom: 'auto',
        right: 'auto',
        top: top + 'px',
        left: left + 'px',
      }

      sessionStorage.setItem('dev_panel_styleObject', JSON.stringify(styleObject.value))
    }

    onMounted(() => {
      const styleObjectStorageValue = sessionStorage.getItem('dev_panel_styleObject')
      if (styleObjectStorageValue) {
        try {
          const styleObjectStorageData = JSON.parse(styleObjectStorageValue)
          if (styleObjectStorageData && typeof styleObjectStorageData === 'object') {
            styleObject.value = {...styleObjectStorageData}
          }
        } catch (e) {
          console.log('TheDevPanel onMounted', e)
        }

      }
    })

    return {
      ...cssVarsModelsForTemplate(),
      windowHeight,
      windowWidth,
      isVisible,
      styleObject,
      resetCssVars,
      dragPanelStartHandler,
      dragPanelEndHandler,
      decreaseFontSize,
      increaseFontSize,
      resetFontSize,
    }
  }

})
