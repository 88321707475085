import {ActionTree, GetterTree, MutationTree} from "vuex"
import {RootState} from "@/store"
import { IFlowStep } from "@/types"

export interface WorkflowDesignerState {
  projectId: string
  steps: IFlowStep[]
  isChanged: boolean
  readOnly: boolean
}

const state: WorkflowDesignerState = {
  projectId: '',
  steps: [],
  isChanged: false,
  readOnly: false,
}

const getters: GetterTree<WorkflowDesignerState, RootState> = {
  projectId: state => state.projectId,
  steps: state => state.steps,
  isChanged: state => state.isChanged,
  readOnly: state => state.readOnly,
}

const mutations: MutationTree<WorkflowDesignerState> = {
  setProjectId(state, id: string) {
    state.projectId = id
  },
  setSteps(state, value: IFlowStep[]) {
    state.steps = [...value]
  },
  setIsChanged(state, value: boolean) {
    state.isChanged = value
  },
  setReadOnly(state, value: boolean) {
    state.readOnly = value
  },
}

const actions: ActionTree<WorkflowDesignerState, RootState> = {

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules : {
  }
}
