import {getKeyboardFocusableElements} from "@/utils/appUtils"

const SELECTED_CLASS = 'selected'

const params = {
  el: document,
  multiple: false,
  items: '[data-id]',
}

const getItems = () => {
  console.log('DropdownKeysNavigation getItems', params)
  const {el, items} = params
  return Array.from(el.querySelectorAll(items))
}

const getSelectedItem = () => getItems().find(item => item.classList.contains('selected'))

const keyEventHandler = (el, binding, event) => {
  console.log('DropdownKeysNavigation', el, binding, event.key, event.target, event)

  Object.assign(params, {
    el,
    ...binding.value
  })

  const items = getItems()
  const selectedItem = getSelectedItem()
  const selectedIndex = selectedItem ? items.indexOf(selectedItem) : -1

  if (event.key === 'ArrowDown') {
    console.log('DropdownKeysNavigation ArrowDown', selectedItem, selectedItem && items.indexOf(selectedItem))
    if (items[selectedIndex + 1]) {
      if (selectedItem) {
        selectedItem.classList.remove(SELECTED_CLASS)
      }
      items[selectedIndex + 1].classList.add(SELECTED_CLASS)
    }

  }
  //
  // const forwardArrowsKeys = ['ArrowUp', 'ArrowRight']
  // const backwardArrowsKeys = ['ArrowDown', 'ArrowLeft']
  // const isForwardArrow = forwardArrowsKeys.includes(event.key)
  // const isBackwardArrow = backwardArrowsKeys.includes(event.key)
  // if (isForwardArrow || isBackwardArrow) {
  //   const tabbableElements = getKeyboardFocusableElements(el)
  //   const targetIndex = tabbableElements.indexOf(event.target)
  //   let nextIndex
  //   if (isBackwardArrow) {
  //     nextIndex = targetIndex === 0 ? tabbableElements.length - 1 : targetIndex - 1
  //   } else {
  //     nextIndex = targetIndex < tabbableElements.length - 1 ? targetIndex + 1 : 0
  //   }
  //   console.log('DropdownKeysNavigation keyEventHandler', targetIndex, nextIndex, isBackwardArrow, event)
  //   tabbableElements[nextIndex].focus()
  // }
}

const DropdownKeysNavigation = {
  mounted(el, binding) {
    console.log('DropdownKeysNavigation mounted', el, binding, DropdownKeysNavigation)
    el.addEventListener('keydown', keyEventHandler.bind(null, el, binding))
  },
  unmounted(el, binding) {
    console.log('DropdownKeysNavigation unmounted', el, binding, DropdownKeysNavigation)
    el.removeEventListener('keydown', keyEventHandler.bind(null, el, binding))
  },
}

export default DropdownKeysNavigation
