import {defineComponent, PropType, toRefs} from "vue";
import {IUser} from "@/types";

export default defineComponent({
  name: 'user-avatar',
  props: {
    avatarName: {
      type: String,
      default: ""
    },
    username: {
      type: String,
      default: ""
    },
    image: {
      type: String,
      default: ""
    },
    image2x: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: "32"
    },
    radius: {
      type: Number,
      default: 50,
    },
    user: {
      type: Object as PropType<IUser>
    },
    color: {type: String, default: ''},
    bg: {type: String, default:'1'},
    showTitleHint: {type: Boolean, default: true}
  },

  setup(props) {
    const {avatarName, username, image, image2x, size, radius, color, user} = toRefs(props);
    const text = ((): string => {
      const aN = user.value ? user.value.avatar_name : avatarName.value;
      const uN = user.value ? user.value.name : username.value;
      if (aN) {
        const words = (aN as string).split(/[\s-]+/);
        return words[0];
      } else if (uN) {
        const words = (uN as string).split(/[\s-]+/)
        return words.map(word => word.substr(0, 1)).join('').substr(0, 3).toUpperCase()
      }
      return "...";
    })();

    const normalizeSize = size.value;
    const icon = user.value ? user.value["ico_" + size.value + "x" + size.value] : image.value ? image.value : null;
    const icon2x = user.value ? user.value["icoX2_" + size.value + "x" + size.value] : image2x.value ? image2x.value : null;
    const hasImage = !!icon;

    const colorClass = (user.value ? user.value.avatar_name : avatarName.value) ? ((user.value ? user.value.avatar_name : avatarName.value) as string).split(/[\s-]+/)[1] : '';
    const title = props.showTitleHint ? user.value?.name : ''

    const toColor = (str: string) => {
      let hash = 0
      if (!str) return 'black'
      for (const char of str.split('')) {
        hash = (hash << (8 - hash)) + char.charCodeAt(0)
      }
      return '#' + Math.abs(hash).toString(16).substr(0, 6)
    };

    const style = (() => {
      return {
        width: size.value + 'px',
        height: size.value + 'px',
        'border-radius': radius.value + '%',
        'background-color': !hasImage ? (!avatarName.value && color.value === '') ? toColor(username.value) : color.value : 'none',
        'line-height': size.value + 'px'
      }
    })()



    return {
      text,
      hasImage,
      icon,
      icon2x,
      style,
      normalizeSize,
      colorClass,
      title,
    }
  }
})
