import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-size", "data-user-type", "title"]
const _hoisted_2 = {
  key: 0,
  class: "type-text"
}
const _hoisted_3 = ["src", "srcset", "alt", "width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["avatar", [_ctx.colorClass, (!_ctx.hasImage)?'text-avatar':'image-avatar']]),
    "data-size": '_'+ _ctx.normalizeSize,
    "data-user-type": _ctx.user?.type,
    title: _ctx.title
  }, [
    (!_ctx.hasImage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.text), 1))
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: _ctx.icon,
          srcset: _ctx.icon + (_ctx.icon2x ? ', ' + _ctx.icon2x +' 2x' : ''),
          alt: _ctx.text,
          class: "avatar-ico",
          width: _ctx.normalizeSize,
          height: _ctx.normalizeSize
        }, null, 8, _hoisted_3))
  ], 10, _hoisted_1))
}